import { Injectable } from '@angular/core';
import { TicketDashboardInfoResponse } from '../../../../Models/Tickets/TicketDashboardInfoResponse.model';

@Injectable({
    providedIn: 'root'
})
export class TicketDashboardService {
    public ClearStoredValues() {
        this.LastLocalUserTabNumber = 0;
        this.LastLocalUserWebUserSearch = null;
        this.LastWebUserEntityFilter = null;    //  Don't default - user may not have access to it!
        this.LastWebUserTabNumber = 0;
        this.ShowCompletedTickets = false;
        this.ResponseDueWithinDaysFilterValue = "2";
    }

    public LastLocalUserTabNumber: number = 0;
    public LastLocalUserWebUserSearch: TicketDashboardInfoResponse = null;

    public LastWebUserTabNumber: number = 0;
    public LastWebUserEntityFilter: string = null;  //  Don't default - user may not have access to it!

    public LastRegulatorTabNumber: number = 0;

    public ShowCompletedTickets: boolean = false;

    public ResponseDueWithinDaysFilterValue: string = "2";
}
