import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { DrawingToolBase } from "Shared/Components/Maps/Tools/DrawingToolBase";
import { MapBrowserEvent, Map } from "ol";
import { Pointer, Interaction } from "ol/interaction";
import ol_Overlay_Popup from "ol-ext/overlay/Popup";

export class InformationTool extends DrawingToolBase {

    private _PopupOverlay: any;     //  type = ol.Overlay.Popup, but there are no typescript mappings currently available for this

    constructor(map: Map, mapToolService: MapToolService) {
        super(map, mapToolService); 

        this.Init();
    }

    public OnDestroy(): any {
        if (this._PopupOverlay)
            this.Map.removeOverlay(this._PopupOverlay);
        this._PopupOverlay = null;

        return super.OnDestroy();
    }

    public Clear(): void {
        this._PopupOverlay.hide();
    }

    protected CreateInteraction(): Interaction {
        //  ol.interaction.Draw: https://openlayers.org/en/latest/apidoc/module-ol_interaction_Draw.html
        const interaction = new Pointer({
            handleEvent: (evt: MapBrowserEvent<any>) => {
                //  To check right click, need to watch for contextmenu.  It checks to see if this tool is active
                //  and prevents it from showing. But if we turn it off too soon, it will think we're not active
                //  and open it.
                if (evt.type === "contextmenu") {
                    //  Right click - stop the interaction.  Must do this in setTimeout or context menu may not have
                    //  checked to see that we're active yet!
                    setTimeout(() => interaction.setActive(false));
                    return false;
                }

                if (evt.type !== "click")
                    return true;

                this.OnClick(evt);
                return false;
            }
        });

        return interaction;
    }

    protected Init(): void {
        super.Init();

        //  ol_Overlay_Popup
        this._PopupOverlay = new ol_Overlay_Popup(
            {
                popupClass: "default anim", //"tooltips", "warning" "black" "default", "tips", "shadow",
                closeBox: true,
                //onshow: function () { console.log("You opened the box"); },
                //onclose: function () { console.log("You close the box"); },
                positioning: 'auto',
                autoPan: true,
                autoPanAnimation: { duration: 250 }
            });
        this.Map.addOverlay(this._PopupOverlay);
    }

    protected OnPointerMove(evt: any): void {
        if (evt.dragging)
            return;

        const helpMsg = 'Click to see information for location';
        this.SetHelpMessage(helpMsg, evt.coordinate);
    }

    private OnClick(evt: MapBrowserEvent<any>): void {
        this.MapToolService.MapSearchService.CreatePopupContentForLocation(evt.pixel, evt.coordinate, evt.frameState.viewState.resolution, this.Map.getView().getZoom())
            .subscribe(content => {
                if (content)
                    this._PopupOverlay.show(evt.coordinate, content);
            });
    }
}
