import { Injectable } from '@angular/core';
import { SearchFilter } from 'Models/Searching/SearchFilter.model';
import { SearchOrderBy } from 'Models/Searching/SearchOrderBy.model';


@Injectable({ providedIn: 'root' }) //Needs to be root so that we can clear out the values when the user logs out or switches the center
export class PhoneTicketDashboardStateManagerService {
    
    CurrentView: { GroupID: number, FilterID: number };
    CurrentFilter: SearchFilter[];
    CurrentSort: SearchOrderBy[];
    CurrentTicketNumber: string;

    ClearState() {
        this.CurrentFilter = null;
        this.CurrentSort = null;
        this.CurrentView = null;
        this.CurrentTicketNumber = null;
    }
}
