import { Component } from '@angular/core';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'iq-phone-back-button-icons',
    templateUrl: './PhoneBackButtonIcons.component.html',
    styleUrls: ['./PhoneBackButtonIcons.component.scss']
})
export class PhoneBackButtonIconsComponent {

    icon = faArrowLeft;

    constructor() {
    }
}
