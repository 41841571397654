import { Feature, MapBrowserEvent } from "ol";
import { Coordinate } from "ol/coordinate";
import { Circle as ol_geom_Circle, Geometry, LineString, Polygon } from "ol/geom";
import GeometryType from "ol/geom/GeometryType";
import { Draw, Interaction } from "ol/interaction";
import { Vector as ol_layer_Vector } from 'ol/layer';
import Map from 'ol/Map';
import RenderFeature from "ol/render/Feature";
import { Vector as ol_source_Vector } from 'ol/source';
import { Circle, Fill, Stroke, Style, Text } from "ol/style";
import { GeometryTransformer } from "Shared/Components/Maps/GeometryTransformer";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { DrawingToolBase } from "Shared/Components/Maps/Tools/DrawingToolBase";

export class MeasureDistanceTool extends DrawingToolBase {

    private _Layer: ol_layer_Vector<ol_source_Vector<Geometry>>;
    private _NumPoints: number = 0;
    private _LastCoordinate: Coordinate = null;

    constructor(map: Map, mapToolService: MapToolService) {
        super(map, mapToolService);

        this.CreateLayer();
        this.Init();
    }

    public OnDestroy(): any {
        this._Layer.setMap(null);
        this._Layer.setStyle(null);       //  Needed or the style function will maintain a reference to "this" (and cause a javascript closure memory leak)
        this._Layer = null;

        return super.OnDestroy();
    }

    public Clear(): void {
        this._Layer.getSource().clear();
    }

    public IsEmpty(): boolean {
        return this._Layer.getSource().getFeatures().length === 0;
    }

    private CreateLayer(): void {
        this._Layer = new ol_layer_Vector({
            source: new ol_source_Vector(),
            style: (feature) => this.BuildStyleForFeature(feature, false),
        });

        this.Map.addLayer(this._Layer);
    }

    protected CreateInteraction(): Interaction {
        //  ol.interaction.Draw: https://openlayers.org/en/latest/apidoc/module-ol_interaction_Draw.html
        const interaction = new Draw({
            source: this._Layer.getSource(),
            type: (GeometryType.LINE_STRING as any),            //  Bug in TypeScript or OpenLayers jsdocs does not recognize the type correctly
            style: (feature) => this.BuildStyleForFeature(feature, true),
            finishCondition: () => this._NumPoints > 1,
            geometryFunction: (coordinates, geometry) => {
                if (geometry)
                    geometry.setCoordinates(coordinates);
                else
                    geometry = new LineString(coordinates as number[] | number [][]);

                const coords: Coordinate[] = geometry.getCoordinates();
                this._NumPoints = coords.length;
                if (this._NumPoints > 1)
                    this._LastCoordinate = coords[coords.length - 2];
                else
                    this._LastCoordinate = null;
                return geometry;
            },
            condition: (evt: MapBrowserEvent<PointerEvent>) => {
                if (evt.type !== "pointerdown")
                    return false;       //  ???

                const pointerEvent = evt.originalEvent;
                if (pointerEvent.button === 2) {
                    //  Right click: If empty, turn off the tool.  If not empty, finish the drawing and leave active.
                    if (this._NumPoints === 0)
                        interaction.setActive(false);
                    else
                        interaction.finishDrawing();
                }

                return pointerEvent.button === 0;       //  Only process Left mouse click
            }
        });

        this.AddListener(interaction.on("drawend", () => this._NumPoints = 0));

        return interaction;
    }

    protected OnPointerMove(evt: any): void {
        if (evt.dragging)
            return;

        let helpMsg = 'Click to start measuring';

        if (this._NumPoints > 0)
            helpMsg = 'Click to continue measuring,</br>double-click to end';

        let positionToLeft: boolean = true;
        if (this._LastCoordinate && this._LastCoordinate[0] > evt.coordinate[0])
            positionToLeft = false;

        this.SetHelpMessage(helpMsg, evt.coordinate, positionToLeft);
    }

    private BuildStyleForFeature(feature: Feature<any> | RenderFeature, forFeatureBeingDrawn: boolean): Style[] {
        const styles: Style[] = [];

        //  First style contains the stroke and will apply to the entire geometry
        if (forFeatureBeingDrawn) {
            styles.push(new Style({
                stroke: new Stroke({
                    color: 'green',
                    lineDash: [10, 10],
                    width: 2
                }),
                image: new Circle({
                    radius: 5,
                    stroke: new Stroke({
                        color: 'green'
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    })
                })
            }));
        }
        else {
            styles.push(new Style({
                stroke: new Stroke({
                    color: 'green',
                    width: 2
                })
            }));
        }

        MeasureDistanceTool.AddStylesForMeasurements(feature, styles, true, this.MapToolService.LabelDistancesOnlyInFeet);

        return styles;
    }

    public static AddStylesForMeasurements(feature: Feature<any> | RenderFeature, styles: Style[], showTotal: boolean, labelDistancesOnlyInFeet: boolean): void {
        const geometry = feature.getGeometry();// as ol.geom.LineString;

        let segment: LineString = null;
        let count: number = 0;

        //  Generate a text label for each line segment.
        //  Adapted from: https://stackoverflow.com/questions/38391780/multiple-text-labels-along-a-linestring-feature
        //  TODO: Don't build for every individual segment.  Compare the slopes and if very close (essentially straight), keep
        //  going so that we build a label for straight lines (with multiple points).  Will reduce the number of labels
        //  for buffered shapes and give better measurements.
        switch (geometry.getType()) {
            case "LineString": {
                const lineString = geometry as LineString;
                lineString.forEachSegment(function (start, end) {
                    segment = new LineString([start, end]);
                    count++;

                    styles.push(new Style({
                        geometry: segment,
                        text: MeasureDistanceTool.CreateTextStyle(segment, false, labelDistancesOnlyInFeet)
                    }));
                });

                //  LineStrings are used when drawing polygons & rectangles too - so don't show total label for those
                if (showTotal && (count > 1)) {
                    styles.push(new Style({
                        geometry: segment,
                        text: MeasureDistanceTool.CreateTextStyle(lineString, true, labelDistancesOnlyInFeet)
                    }));
                }
                break;
            }
            case "Polygon": {
                const polygon = geometry as Polygon;

                let last: number[] = null;
                polygon.getLinearRing(0).getCoordinates().forEach(c => {
                    if (last !== null)
                        MeasureDistanceTool.AddStyleForSegment(last, c, styles, labelDistancesOnlyInFeet);

                    last = c;
                });
                break;
            }
            case "Circle": {
                const circle = geometry as ol_geom_Circle;
                const center = circle.getCenter();
                const ls = new LineString([center, [center[0] + circle.getRadius(), center[1]]]);

                styles.push(new Style({
                    geometry: ls,
                    stroke: new Stroke({
                        color: "#0951A7",
                        width: 2
                    }),
                    text: MeasureDistanceTool.CreateTextStyle(ls, false, labelDistancesOnlyInFeet)
                }));

                break;
            }
        }
    }

    //  TODO: Don't build for every individual segment.  Compare the slopes and if very close (essentially straight), keep
    //  going so that we build a label for straight lines (with multiple points).  Will reduce the number of labels
    //  for buffered shapes and give better measurements.
    private static AddStyleForSegment(fromCoord: number[], toCoord: number[], styles: Style[], labelDistancesOnlyInFeet: boolean): void {
        const lineString = new LineString([fromCoord, toCoord]);
        styles.push(new Style({
            geometry: lineString,
            text: MeasureDistanceTool.CreateTextStyle(lineString, false, labelDistancesOnlyInFeet)
        }));
    }

    private static CreateTextStyle(geometry: LineString, isTotal: boolean, labelDistancesOnlyInFeet: boolean): any {
        //  Must convert the geometry to a geographic coordinate system so that measurements will be accurate
        const transformedLine = GeometryTransformer.ToGeography(geometry);
        let distanceFeet = transformedLine ? transformedLine.getLength() * MapConstants.FEET_PER_METER : 0;
        distanceFeet = Math.round(distanceFeet);        //  round to nearest foot

        let text: string;
        if (labelDistancesOnlyInFeet || (distanceFeet < MapConstants.FEET_PER_MILE))
            text = distanceFeet.toLocaleString("en-US") + ' ft';
        else
            text = String(Math.round((distanceFeet / MapConstants.FEET_PER_MILE) * 100) / 100) + ' mi';

        if (isTotal)
            text = "(total " + text + ")";

        return new Text({
            font: "bold 16px 'Courier New'",
            fill: new Fill({ color: 'blue' }),
            stroke: new Stroke({ color: 'white', width: 3 }),
            overflow: isTotal,        //  Allow overlap on the total label so that it ALWAYS shows.
            placement: "line",
            text: text,
            textAlign: "center",
            textBaseline: isTotal ? "top" : "bottom"
        });
    }
}
