import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PasswordRequirements } from 'iqCognito/models';
import { validateUsername } from 'iqCognito/validation';
import { PersonLogin } from 'Models/People/PersonLogin.model';
import { CognitoCreateLoginRequest } from 'Models/Security/Cognito/CognitoCreateLoginRequest.model';
import { CreateLoginRequest } from "Models/Security/CreateLoginRequest.model";
import { CreateLoginResponse } from "Models/Security/CreateLoginResponse.model";
import { LinkToExistingAccountRequest } from "Models/Security/LinkToExistingAccountRequest.model";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'Services/AuthenticationService';
import { SettingsService } from 'Services/SettingsService';
import { ConfirmationDialogComponent } from 'Shared/Components/Controls/Dialog/Confirmation/ConfirmationDialog.component';
import { InformationDialogComponent } from 'Shared/Components/Controls/Dialog/Information/InformationDialog.component';
import { DialogModel } from 'Shared/Components/Controls/Dialog/Models/Dialog.model';
import { IQ_VALIDATOR_PATTERNS } from 'Shared/Components/Forms/Validation/ValidationPatterns.model';
import { IsEqualToFormControl } from 'Shared/Components/Forms/Validation/Validators/IsEqualToFormControl.validator';

export class ExactixAdminCreateLoginDialogComponentData {
    PasswordRequirements: PasswordRequirements;
    Email: string;
    Username: string;
    FirstName: string;
    LastName: string;
    ShowVerifyEmail: boolean;
    PersonID: string;
}

@Component({
    templateUrl: './AdminCreateLoginDialog.component.html',
    styleUrls: ['./AdminCreateLoginDialog.component.scss']
})
export class ExactixAdminCreateLoginDialogComponent implements OnDestroy {

    public FormGroup: FormGroup;
    public PasswordFormControl: FormControl;
    public EmailFormControl: FormControl;
    public ConfirmEmailFormControl: FormControl;
    public UsernameFormControl: FormControl;
    public FirstNameFormControl: FormControl;
    public LastNameFormControl: FormControl;

    public PasswordRequirements: PasswordRequirements;
    public UsernameSameAsEmail: boolean = true;
    public ApiAccessOnly: boolean = false;

    public ShowVerifyEmail: boolean;
    public Saving: boolean = false;
    public ErrorMessage: string;

    private _PersonID: string;

    private _Destroyed = new Subject<void>();

    constructor(private _DialogRef: MatDialogRef<ExactixAdminCreateLoginDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ExactixAdminCreateLoginDialogComponentData,
        public SettingsService: SettingsService, private http: HttpClient, private _AuthenticationService: AuthenticationService,
        private _Dialog: MatDialog) {

        this.PasswordRequirements = { ...new PasswordRequirements(), ...data.PasswordRequirements };
        this.ShowVerifyEmail = !data.Email;        //  Verify email if one was not provided

        this._PersonID = data.PersonID;

        this.BuildFormGroup(data);
    }

    public ngOnDestroy(): void {
        this._Destroyed.next();
        this._Destroyed.complete();
    }

    private BuildFormGroup(data: ExactixAdminCreateLoginDialogComponentData): void {
        this.PasswordFormControl = new FormControl();

        //  Validators set in SetValidatorsDependantOnApiAccessOnly()
        this.EmailFormControl = new FormControl(data.Email);
        this.ConfirmEmailFormControl = new FormControl(null);

        //  Necessary for the IsEqualToFormControl() validator
        this.ConfirmEmailFormControl.valueChanges.pipe(takeUntil(this._Destroyed)).subscribe(() => this.EmailFormControl.updateValueAndValidity());

        this.UsernameFormControl = new FormControl(data.Username);      //  Validator set in SetUsernameValidator()

        this.FirstNameFormControl = new FormControl(data.FirstName, [Validators.required]);
        this.LastNameFormControl = new FormControl(data.LastName, [Validators.required]);

        //  Properties are named to match CognitoCreateLoginRequest which for some reason needs to match the cognito model so that
        //  we can use reflection to set the properties in the server (saving -10 lines of code, yes takes 10 extra lines of code).
        this.FormGroup = new FormGroup({
            username: this.UsernameFormControl,
            password: this.PasswordFormControl,
            email: this.EmailFormControl,
            given_name: this.FirstNameFormControl,
            family_name: this.LastNameFormControl
        });

        this.SetValidatorsDependantOnApiAccessOnly();
    }

    public ApiAccessOnlyChanged(): void {
        this.ApiAccessOnly = !this.ApiAccessOnly;
        this.SetValidatorsDependantOnApiAccessOnly();
    }

    private SetValidatorsDependantOnApiAccessOnly(): void {
        if (this.ApiAccessOnly) {
            this.EmailFormControl.clearValidators();
            this.ConfirmEmailFormControl.clearValidators();
        }
        else {
            const emailValidators = [Validators.required, Validators.maxLength(100), Validators.pattern(IQ_VALIDATOR_PATTERNS.emailPattern)];
            this.ConfirmEmailFormControl.setValidators(emailValidators);

            if (this.ShowVerifyEmail)
                emailValidators.push(IsEqualToFormControl(this.ConfirmEmailFormControl, "emails do not match"));

            this.EmailFormControl.setValidators(emailValidators);
        }

        this.SetUsernameValidator();
    }

    public UsernameSameAsEmailChanged(): void {
        this.UsernameSameAsEmail = !this.UsernameSameAsEmail;
        this.SetUsernameValidator();
    }

    private SetUsernameValidator(): void {
        if (!this.ApiAccessOnly && this.UsernameSameAsEmail)
            this.UsernameFormControl.clearValidators();
        else
            this.UsernameFormControl.setValidators([Validators.required, validateUsername]);
        this.UsernameFormControl.updateValueAndValidity();
    }

    public CreateLogin(): void {
        this.Saving = true;

        const request = new CreateLoginRequest(this._PersonID, this.ApiAccessOnly, this.FormGroup.getRawValue());

        if (this.ApiAccessOnly)
            request.LoginRequest.email = null;

        this.http.post<CreateLoginResponse>(this.SettingsService.ApiBaseUrl + "/Security/Login/Create", request).subscribe(
            response => this.HandleCreateLoginResponse(response),
            err => {
                console.log(err);
                this.Saving = false;
                this.ErrorMessage = err && err.error ? err.error.Message || err : "Something went wrong";
            });
    }

    private HandleCreateLoginResponse(response: CreateLoginResponse): void {
        this.Saving = false;

        if (response.PersonLogin)
            this._DialogRef.close(response.PersonLogin);
        else if (response.InUseByPerson)
            this.ShowAccountAlreadyLinked(response);
        else
            this.PromptLinkToExistingAccount(response);
    }

    private ShowAccountAlreadyLinked(response: CreateLoginResponse): void {
        const which = (response.UsernameInUse ? "Username" : "Email");
        this.ErrorMessage = "That " + which;
        this.ErrorMessage += " is already being used by the login for " + response.InUseByPerson.FirstName + " " + response.InUseByPerson.LastName + ".";

        //  This link will cause a page load.  Only way to avoid that would be to create custom dialog.  Didn't think it was worth it.
        const message = "<p>" + this.ErrorMessage + "</p><p>Click <a href='people/details/" + response.InUseByPerson.ID + "#tab3'>here</a> to view that Person.</p>";
        const data = new DialogModel(which + " in use", message);

        this._Dialog
            .open(InformationDialogComponent, {
                data: data,
                minWidth: '50%'
            });
    }

    private PromptLinkToExistingAccount(response: CreateLoginResponse): void {
        this.ErrorMessage = "That " + (response.UsernameInUse ? "Username" : "Email");
        this.ErrorMessage += " is in use by another Exactix or Coursettra account but is not currently registered with " + this._AuthenticationService.CurrentUser.CurrentOneCallCenterName + ".";

        const message = "<p>" + this.ErrorMessage + "</p><p>Do you want to link that account to this person?</p>";
        const data = new DialogModel("Link to existing account", message, "Link");

        this._Dialog
            .open(ConfirmationDialogComponent, {
                data: data,
                minWidth: '50%'
            })
            .afterClosed().subscribe(link => {
                if (link)
                    this.LinkToExistingAccount(response);
            });
    }

    private LinkToExistingAccount(response: CreateLoginResponse): void {
        const cognitoInfo = this.FormGroup.getRawValue() as CognitoCreateLoginRequest;

        const username = response.UsernameInUse ? cognitoInfo.username : null;

        //  Always send the entered email (which is null if ApiAccessOnly).  Will attempt to link by Username if that
        //  is given, else will use email.  But need email in case the account we are linking to does not have one
        //  and this request is for a regular account (which requires an email).
        //  Password is needed if this is an ApiAccessOnly user and the account we are linking to is currently in a
        //  "Force password change" status.
        const request = new LinkToExistingAccountRequest(this._PersonID, this.ApiAccessOnly, username, cognitoInfo.email, cognitoInfo.password);

        this.http.post<PersonLogin>(this.SettingsService.ApiBaseUrl + "/Security/Login/LinkToExistingAccount", request)
            .subscribe(personLogin => this._DialogRef.close(personLogin));
    }
}
