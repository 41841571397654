<ng-container *ngIf="ServiceArea?.Discussions" >
    <ng-container *ngFor="let discussion of ServiceArea.Discussions; last as isLast">
        <div style="padding: 5px 0; display:flex; flex-direction:column">
            <ng-container *ngIf="discussion.Response; else addDiscussion">
                <label style="word-break: break-word;">
                    <ng-container *ngIf="discussion.DiscussionType === DiscussionTypeEnum.RequestActualExcavationDate; else nonDate">
                        Date of Excavation: {{discussion.Response | date: SettingsService.DateFormat}}
                    </ng-container>
                    <ng-template #nonDate>
                        {{discussion.Response}} with marking delay
                    </ng-template>
                </label>
            </ng-container>
            <ng-template #addDiscussion>
                <ng-container *ngIf="!ReadOnly">
                    <span class="link" *ngIf="discussion.DiscussionType === DiscussionTypeEnum.RequestActualExcavationDate" (click)="AddExcavationDate($event)">+ add excavation date</span>
                    <span class="link" *ngIf="discussion.DiscussionType === DiscussionTypeEnum.MarkingDelayRequested" (click)="AddMarkingDelayResponse($event)">+ add marking delay response</span>
                    <label class="red">*required</label>
                </ng-container>
            </ng-template>
        </div>

        <mat-divider style="margin-left: 25px;" *ngIf="!isLast"></mat-divider>
    </ng-container>
</ng-container>
