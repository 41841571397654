import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdditionalContactTypeEnum } from 'Enums/AdditionalContactType.enum';
import { ServiceAreaTypeEnum } from 'Enums/ServiceAreaType.enum';
import _ from 'lodash';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'Services/CommonService';
import { IQ_VALIDATOR_PATTERNS } from 'Shared/Components/Forms/Validation/ValidationPatterns.model';

@Component({
    selector: 'ticket-send-copy-to',
    templateUrl: './SendCopyTo.component.html',
    styleUrls: ['./SendCopyTo.component.scss'],
    providers: [
        CommonService
    ]
})
export class SendCopyToComponent implements OnInit, OnDestroy {
    //  Pass in a pre-created FormGroup to store the form values here.  Otherwise, will create one internally.
    //  That is needed on the ticket saved steps because the step components are created dynamically (so the FormGroup
    //  is "owned" by the TicketSavedDialogBaseComponent).
    @Input()
    public FormGroup: FormGroup;

    @Input("ticket")
    private _Ticket: Ticket;

    //  If we saved multiple segments, this should contain all of the Ticket.IDs
    @Input("ticketIDList")
    public TicketIDList: string[];

    // Custom email addresses to use.  If not provided, will get from the ticket.
    @Input("emails")
    private _Emails: string[];

    // Custom fax numbers to use.  If not provided, will get from the ticket.
    @Input("faxNumbers")
    private _FaxNumbers: string[];

    public get IsValid(): boolean { return this.FormGroup?.valid ?? false; }

    //  Options to show or not show the options.  This is independent of the default send option!  So it's possible
    //  to not show the option but to automatically send.
    public ShowSendEmail: boolean = false;
    public ShowSendFax: boolean = false;

    protected _Destroyed: Subject<void> = new Subject();

    constructor(private _CommonService: CommonService, private _TicketService: TicketService) {
    }

    public ngOnDestroy(): void {
        this._Destroyed.next();
        this._Destroyed.complete();
    }

    public ngOnInit(): void {
        if (!this._CommonService.AuthenticationService.CurrentUser.OneCallCenterSettings)
            return;     //  User got logged out?

        this.ShowSendEmail = coerceBooleanProperty(this._CommonService.CurrentUser.OneCallCenterSettings.ShowSendEmailToExcavator);
        this.ShowSendFax = coerceBooleanProperty(this._CommonService.CurrentUser.OneCallCenterSettings.ShowSendFaxToExcavator);

        this.BuildForm();
    }

    protected BuildForm(): void {
        if (!this.FormGroup)
            this.FormGroup = new FormGroup({});

        //  If true, we require the email to be sent to the excavator (cannot uncheck it) - which will include the Landlord Notification Report.
        //  And we also allow viewing the Landlord Notification report here.
        const showViewLandlordNotification = coerceBooleanProperty(this._CommonService.AuthenticationService.CurrentUser.OneCallCenterSettings.SendLandlordNotification
            && this._Ticket.ServiceAreas && this._Ticket.ServiceAreas.some(sa => sa.ServiceAreaType.Value === ServiceAreaTypeEnum.Landlord));

        //  Force sending New tickets to homeowner when AlwaysSendEmailToHomeowner is enabled
        const alwaysSendEmailToHomeowner = coerceBooleanProperty(this._CommonService.AuthenticationService.CurrentUser.OneCallCenterSettings.AlwaysSendEmailToHomeowner)
            && !this._Ticket.Excavator.ExcavatorOfficeID && (this._Ticket.Version === 0);

        const sendToHomeownerOrLandlord = showViewLandlordNotification || alwaysSendEmailToHomeowner;

        //  Can't require email because not all users will have one!  If that somehow changes, can do this like this:
        //if (sendToHomeownerOrLandlord)
        //    sendEmailCopyToCallerControl.setValidators([Validators.requiredTrue]);

        //  Add the email form controls if not already found
        if (!this.FormGroup.get("SendEmailCopyToCaller")) {
            //  TODO: AZ would like a list of emails (and faxes?)...
            const emailList = (this._Emails && this._Emails.length > 0) ? this._Emails : this.GetEmailsFromTicket();
            const defaultEmailAddress = emailList.length > 0 ? emailList[0] : "";
            const emailAddressControl = new FormControl(defaultEmailAddress, [Validators.required, Validators.maxLength(100), Validators.pattern(IQ_VALIDATOR_PATTERNS.emailPattern)]);

            let sendEmailDefault = coerceBooleanProperty(this._CommonService.CurrentUser.OneCallCenterSettings.SendEmailToExcavatorDefault || sendToHomeownerOrLandlord);
            if (_.isEmpty(defaultEmailAddress))
                sendEmailDefault = false;       //  Don't default to checked if no email - otherwise form won't allow closing unless it's unchecked
            const sendEmailCopyToCallerControl = new FormControl(sendEmailDefault);
            this.SetupCheckboxEvents(sendEmailCopyToCallerControl, emailAddressControl);

            this.FormGroup.addControl("SendEmailCopyToCaller", sendEmailCopyToCallerControl);
            this.FormGroup.addControl("EmailAddress", emailAddressControl);
        }

        //  Add the fax form controls if not already found
        if (!this.FormGroup.get("SendFaxCopyToCaller")) {
            const faxNumberList = (this._FaxNumbers && this._FaxNumbers.length > 0) ? this._FaxNumbers : this.GetFaxNumbersFromTicket();
            const defaultFaxNumber = faxNumberList.length > 0 ? faxNumberList[0] : "";
            const faxNumberControl = new FormControl(defaultFaxNumber, [Validators.required, Validators.maxLength(20), Validators.pattern(IQ_VALIDATOR_PATTERNS.faxPattern)]);

            let sendFaxDefault = coerceBooleanProperty(this._CommonService.CurrentUser.OneCallCenterSettings.SendFaxToExcavatorDefault);
            if (_.isEmpty(defaultFaxNumber))
                sendFaxDefault = false;       //  Don't default to checked if no fax - otherwise form won't allow closing unless it's unchecked
            const sendFaxCopyToCallerControl = new FormControl(sendFaxDefault);
            this.SetupCheckboxEvents(sendFaxCopyToCallerControl, faxNumberControl);

            this.FormGroup.addControl("SendFaxCopyToCaller", sendFaxCopyToCallerControl);
            this.FormGroup.addControl("FaxNumber", faxNumberControl);
        }
    }

    /**
     * Returns a list of email addresses from the TicketExcavator.  TicketEntryFormBase has overridable methods
     * where this can be customized per One Call but this is the generic method (currently, all One Calls do this - no overrides).
     * Completing a Suspend also needs to do this but it does not have the TicketEntryFormBase so it will always use this method.
     * @param ticketExcavator
     */
    private GetEmailsFromTicket(): string[] {
        const ticketExcavator = this._Ticket?.Excavator;
        const emails: string[] = [];
        if (!ticketExcavator)
            return emails;

        if (!_.isEmpty(ticketExcavator.ContactEmail))
            emails.push(ticketExcavator.ContactEmail);

        if ((ticketExcavator.ContactAdditionalPhone1Type === AdditionalContactTypeEnum.Email) && !_.isEmpty(ticketExcavator.ContactAdditionalPhone1Value))
            emails.push(ticketExcavator.ContactAdditionalPhone1Value);
        if ((ticketExcavator.ContactAdditionalPhone2Type === AdditionalContactTypeEnum.Email) && !_.isEmpty(ticketExcavator.ContactAdditionalPhone2Value))
            emails.push(ticketExcavator.ContactAdditionalPhone2Value);

        return emails;
    }

    /**
     * Returns a list of fax numbers from the TicketExcavator.  TicketEntryFormBase has overridable methods
     * where this can be customized per One Call but this is the generic method (currently, only FL overrides).
     * Completing a Suspend also needs to do this but it does not have the TicketEntryFormBase so it will always use this method.
     * @param ticketExcavator
     */
    private GetFaxNumbersFromTicket(): string[] {
        const ticketExcavator = this._Ticket?.Excavator;
        const faxNumbers: string[] = [];
        if (!ticketExcavator)
            return faxNumbers;

        if ((ticketExcavator.ContactAdditionalPhone1Type === AdditionalContactTypeEnum.Fax) && !_.isEmpty(ticketExcavator.ContactAdditionalPhone1Value))
            faxNumbers.push(ticketExcavator.ContactAdditionalPhone1Value);
        if ((ticketExcavator.ContactAdditionalPhone2Type === AdditionalContactTypeEnum.Fax) && !_.isEmpty(ticketExcavator.ContactAdditionalPhone2Value))
            faxNumbers.push(ticketExcavator.ContactAdditionalPhone2Value);

        return faxNumbers;
    }

    private SetupCheckboxEvents(checkboxFormControl: FormControl, addressFormControl: FormControl): void {
        checkboxFormControl.valueChanges
            .pipe(takeUntil(this._Destroyed))
            .subscribe(checked => {
                if (checked)
                    addressFormControl.enable();
                else
                    addressFormControl.disable();
            });
        if (!checkboxFormControl.value)
            addressFormControl.disable();
    }

    //  Note that when this component is used in the TicketSavedDialog, TicketEntryFormBase handles this and does not call this method.
    //  This is becasue of the way the dynamic steps are handled (which requires storing the SendCopy info in a FormGroup owned by
    //  TicketSavedDialogBase and the step with the SendCopyTo component may not even be created at the time the user closes the dialog).
    public SendToExcavator(): void {
        if (!this.FormGroup.valid)
            return;     //  ???  should not have been called!

        const ticketIDList = (this.TicketIDList && this.TicketIDList.length > 0) ? this.TicketIDList : [this._Ticket.ID];

        const form = this.FormGroup.value;
        const sendCopyToEmailAddress = form.SendEmailCopyToCaller ? form.EmailAddress : null;
        const sendCopyToFaxNumber = form.SendFaxCopyToCaller ? form.FaxNumber : null;
        if (!sendCopyToEmailAddress && !sendCopyToFaxNumber)
            return;

        this._TicketService.SendCopyToExcavator(ticketIDList, this._Ticket.Excavator.ExcavatorContactID, sendCopyToEmailAddress, sendCopyToFaxNumber);
    }
}
