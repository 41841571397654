import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BrowserSizeService, BrowserSizeEvent } from './BrowserSizeService.service';

export class HideMenuEvent {
    constructor(public hide: boolean, public isMobile: boolean, public fromPage: boolean, public HideTopMenuBar: boolean) {}
}

@Injectable({
    providedIn: 'root'
})
export class MainMenuService {

    public hideMenu: BehaviorSubject<HideMenuEvent> = new BehaviorSubject(new HideMenuEvent(true, false, false, false));

    //Need to make sure to clear this out on the destroy action of any controller that sets it!!!
    private _forceClose: boolean = false;
    get forceClose() {
        return this._forceClose;
    }
    set forceClose(val: boolean) {
        if (this._forceClose !== val) {
            //  Only do this if the value is different or if it's called multiple times, can cause stack overflow issues!
            this._forceClose = val;
            this.UpdateMenuStatus();
        }
    }

    private _HideTopMenuBar: boolean = false;
    get HideTopMenuBar() {
        return this._HideTopMenuBar;
    }
    set HideTopMenuBar(val: boolean) {
        this._HideTopMenuBar = val;
        this.UpdateMenuStatus();
    }

    private browserSize: BrowserSizeEvent = new BrowserSizeEvent(true, false, false);

    constructor(private sizeService: BrowserSizeService) {
        sizeService.browserSize.subscribe(size => {
            this.browserSize = size;
            this.UpdateMenuStatus();

        })
    }

    private UpdateMenuStatus() {
        this.hideMenu.next(new HideMenuEvent(this.browserSize.isMobile || this.forceClose, this.browserSize.isMobile, this.forceClose, this.HideTopMenuBar));
    }
}
