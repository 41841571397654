import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { PhoneSaveButtonComponent } from './Save/PhoneSaveButton.component';
import { PhoneSaveButtonIconsComponent } from './Save/Icons/PhoneSaveButtonIcons.component';
import { PhoneCancelButtonComponent } from './Cancel/PhoneCancelButton.component';
import { PhoneCancelButtonIconsComponent } from './Cancel/Icons/PhoneCancelButtonIcons.component';
import { PhoneCloseButtonComponent } from './Close/PhoneCloseButton.component';
import { PhoneCloseButtonIconsComponent } from './Close/Icons/PhoneCloseButtonIcons.component';
import { PhoneBackButtonComponent } from './Back/PhoneBackButton.component';
import { PhoneBackButtonIconsComponent } from './Back/Icons/PhoneBackButtonIcons.component';
import { PhoneActionsButtonIconsComponent } from './Actions/Icons/PhoneActionsButtonIcons.component';
import { PhoneActionsButtonComponent } from './Actions/PhoneActionsButton.component';
import { PhoneFiltersButtonIconsComponent } from './Filters/Icons/PhoneFiltersButtonIcons.component';
import { PhoneFiltersButtonComponent } from './Filters/PhoneFiltersButton.component';
import { PhoneSettingsButtonIconsComponent } from './Settings/Icons/PhoneSettingsButtonIcons.component';
import { PhoneSettingsButtonComponent } from './Settings/PhoneSettingsButton.component';
import { PhoneViewButtonIconsComponent } from './View/Icons/PhoneViewButtonIcons.component';
import { PhoneViewButtonComponent } from './View/PhoneViewButton.component';

import { iqFabButtonScrollResizeDirective } from './iqFabButtonScrollResize.directive';


@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        MatButtonModule
    ],
    declarations: [
        PhoneActionsButtonIconsComponent,
        PhoneActionsButtonComponent,
        PhoneSaveButtonComponent,
        PhoneSaveButtonIconsComponent,
        PhoneCancelButtonComponent,
        PhoneCancelButtonIconsComponent,
        PhoneCloseButtonComponent,
        PhoneCloseButtonIconsComponent,
        PhoneBackButtonComponent,
        PhoneBackButtonIconsComponent,
        PhoneFiltersButtonIconsComponent,
        PhoneFiltersButtonComponent,
        PhoneSettingsButtonIconsComponent,
        PhoneSettingsButtonComponent,
        PhoneViewButtonIconsComponent,
        PhoneViewButtonComponent,

        iqFabButtonScrollResizeDirective
    ],
    exports: [
        PhoneActionsButtonIconsComponent,
        PhoneActionsButtonComponent,
        PhoneSaveButtonComponent,
        PhoneSaveButtonIconsComponent,
        PhoneCancelButtonComponent,
        PhoneCancelButtonIconsComponent,
        PhoneCloseButtonComponent,
        PhoneCloseButtonIconsComponent,
        PhoneBackButtonComponent,
        PhoneBackButtonIconsComponent,
        PhoneFiltersButtonIconsComponent,
        PhoneFiltersButtonComponent,
        PhoneSettingsButtonIconsComponent,
        PhoneSettingsButtonComponent,
        PhoneViewButtonIconsComponent,
        PhoneViewButtonComponent,

        iqFabButtonScrollResizeDirective
    ]

})
export class PhoneButtonsModule { }
