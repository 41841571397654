import { Component, Input } from '@angular/core';
import { AuthComponentBase } from '../../auth-component-base';
import { IqAwsCognitoService } from '../../Services/iq-aws-cognito.service';
import { ViewStateEnum, PasswordRequirements } from '../../models';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { controlsEqualValidator } from '../../validation';

@Component({
  selector: 'iq-aws-cognito-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class iQNewPasswordComponent extends AuthComponentBase {

  private _passwordRequirements: PasswordRequirements;
  @Input() set PasswordRequirements(val: PasswordRequirements) {
    let defaultRequirements = new PasswordRequirements();
    this._passwordRequirements = { ...defaultRequirements, ...val };
  }
  get PasswordRequirements(){
    return this._passwordRequirements;
  }
  
  get username() { return this.group.get("Username"); }
  get password() { return this.group.get("Password"); }
  get confirmPassword() { return this.group.get("ConfirmPassword"); }

  sessionExpired: boolean = false;

  private user;
  private oldPassword;

  constructor(protected awsCognitoService: IqAwsCognitoService) {
    super(awsCognitoService);
  }

  ngOnInit() {
    super.ngOnInit();
    
    this.SetPasswordRequirements(this.password, this.PasswordRequirements);

    //Should be set by the time this is shown, so we can do a take(1)
    this.awsCognitoService.viewStateInfoChange$.pipe(take(1)).subscribe(val => {
      if (val != null && val.user != null) {
        this.username.setValue(val.user.username);
        this.oldPassword = val.MessageData;
        this.user = val.user;
      }
    });
  }
  
  ShouldDisplay() {
    return ViewStateEnum.newPasswordRequired == this.authState.state;
  }

  BuildForm() {
    return new FormGroup({
      Username: new FormControl({value: null, disabled: true}, [Validators.required]),//Should always have the username because they just tried to sign in with it.
      Password: new FormControl(null, [Validators.required]),
      ConfirmPassword: new FormControl(null, [Validators.required])
    }, [controlsEqualValidator('Password', 'ConfirmPassword')]);
  }
  onSubmitPasswordChange() {
    if (this.oldPassword === this.password.value) {
      this.SetError("The new password can not be the same as the temporary one");
      return;
    }

    this.awsCognitoService.CompleteNewPassword(this.user, this.password.value, this.ClientMetadata).pipe(take(1)).subscribe(null, (err: any) => {
      if (err != null && err.code != null && err.code === "NotAuthorizedException") {
        this.sessionExpired = true;
      }
      else
        this.SetError(err);
    });
  }

  onRestart() {
    this.awsCognitoService.setViewState({ user: this.user, state: ViewStateEnum.signedOut, MessageData: null });
  }
}
