import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { PasswordRequirements } from 'iqCognito/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IsEqualToFormControl } from 'Shared/Components/Forms/Validation/Validators/IsEqualToFormControl.validator';

@Component({
    selector: 'iq-password-prompt',
    templateUrl: './PasswordPrompt.component.html',
    styleUrls: ['./PasswordPrompt.component.scss']
})
export class PasswordPromptComponent implements OnInit, OnDestroy {

    @Input()
    public PasswordFormControl: FormControl;

    @Input()
    public PasswordRequirements: PasswordRequirements = new PasswordRequirements();

    public ConfirmPasswordFormControl: FormControl;

    public ShowPassword: boolean;
    public Icon = faEyeSlash;

    private _Destroyed = new Subject<void>();

    constructor() {
    }

    public ngOnDestroy(): void {
        this._Destroyed.next();
        this._Destroyed.complete();
    }

    public ngOnInit(): void {
        this.ConfirmPasswordFormControl = new FormControl(null, [Validators.required, Validators.minLength(this.PasswordRequirements.MinCharacters)]);

        //  Necessary for the IsEqualToFormControl() validator
        this.ConfirmPasswordFormControl.valueChanges.pipe(takeUntil(this._Destroyed)).subscribe(() => this.PasswordFormControl.updateValueAndValidity());

        //  Needed or parent component's FormGroup.valid will trigger an "Expression has changed after it was checked" error.
        setTimeout(() => {
            this.PasswordFormControl.setValidators([
                Validators.required,
                Validators.minLength(this.PasswordRequirements.MinCharacters),
                IsEqualToFormControl(this.ConfirmPasswordFormControl, "passwords do not match")
            ]);
            this.PasswordFormControl.updateValueAndValidity();
        });
    }

    public ToggleShowPassword() {
        this.ShowPassword = !this.ShowPassword;
        this.Icon = this.ShowPassword ? faEye : faEyeSlash;
    }
}
