import { Component, Input } from '@angular/core';
import { AuthComponentBase } from '../../auth-component-base';
import { ViewStateEnum, PasswordRequirements } from '../../models';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { take, finalize } from 'rxjs/operators';
import { IqAwsCognitoService } from '../../Services/iq-aws-cognito.service';
import { controlsEqualValidator, validateUsername } from '../../validation';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'iq-aws-cognito-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.css'],
	host: { '[class.iq-aws-cognito-reset-password]': 'true' }
})
export class iQResetPasswordComponent extends AuthComponentBase {

	private _passwordRequirements: PasswordRequirements;
	@Input() set PasswordRequirements(val: PasswordRequirements) {
	  let defaultRequirements = new PasswordRequirements();
	  this._passwordRequirements = { ...defaultRequirements, ...val };
	}
	get PasswordRequirements(){
	  return this._passwordRequirements;
	}
	
	waitingForCode: boolean;
	fromLogin: boolean = false;

	get username() { return this.group.get("Username"); }
	get password() { return this.group.get("Password"); }
	get confirmPassword() { return this.group.get("ConfirmPassword"); }
	get code() { return this.group.get("Code"); }

	ShouldDisplay(): boolean {
		return this.authState.state == ViewStateEnum.resetPassword;
	}
	BuildForm() {
		return new FormGroup({
			Username: new FormControl(null, [Validators.required, validateUsername]),
			Password: new FormControl(null, [Validators.required]),
			ConfirmPassword: new FormControl(null, [Validators.required]),
			Code: new FormControl(null, [Validators.required])
		}, [controlsEqualValidator('Password', 'ConfirmPassword')]);
	}

	constructor(protected awsCognitoService: IqAwsCognitoService, private snackBar: MatSnackBar) {
		super(awsCognitoService);
	}

	ngOnInit() {
		super.ngOnInit();

		this.SetPasswordRequirements(this.password, this.PasswordRequirements);

		//Should be set by the time this is shown, so we can do a take(1)
		this.awsCognitoService.viewStateInfoChange$.pipe(take(1)).subscribe(val => {
			if (val.user != null)
				this.username.setValue(val.user.username);
			
			if (val.MessageData && val.MessageData.FromLogin) {
				this.username.disable();
				this.fromLogin = true;
				this.ActionMessage = "A password reset has been requested and not completed.  Please check your email for a code, or have it resent by clicking 'Resend code' below."
				this.waitingForCode = true;
			}
		});
	}

	onReturnToLogin() {
		this.awsCognitoService.setViewState({ 
			state: ViewStateEnum.signedOut, 
			user: this.username.value ? { username: this.username.value } : null,
			MessageData: null
		});
	}

	onSendEmail() {

		let usernameValue = this.username.value;
		if (!usernameValue) {
			this._errorMessage = 'Username cannot be empty';
			return;
		}

		//reset the error in case they changed the username and it's valid now
		this._errorMessage = "";

		this.username.disable();

		this.isBusy = true;
			
		this.awsCognitoService.InitForgotPassword(usernameValue, this.ClientMetadata)
			.pipe(take(1), finalize(() => this.isBusy = false))
			.subscribe(
				(data) => {
					this.waitingForCode = true;
					this.SetActionMessage(data);
					this.snackBar.open("Code Sent", null);
				},
				(err) => {
					this.username.enable();
					this.SetError(err);
					this.waitingForCode = false;
				});
	}

	onSubmitPasswordChange() {
		if (this.group.valid) {
			this.isBusy = true;

			this.awsCognitoService.CompleteForgotPassword(this.username.value, this.password.value, this.code.value, this.ClientMetadata)
				.pipe(take(1), finalize(() => this.isBusy = false))
				.subscribe(null, (err) => this.SetError(err));
		}
		else
			this.SetError("Please provide required data.");
	}
}
