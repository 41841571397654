<div class="app-root">
    <desktop-root *ngIf="!isPhoneSize; else phoneView"></desktop-root>
    <ng-template #phoneView>
        <div class="app-content iq-phone-page" style="display:flex; flex-direction:column;">
            <general-busy-indicator></general-busy-indicator>
            <busy-indicator [busyKey]="busyKey"></busy-indicator>
            <iq-phone-header *ngIf="showDefaultHeader">
                <ng-template *ngIf="!showingLoginPage && !(isSignedIn | async)" iqPhoneHeaderLeft>
                    <button mat-icon-button routerLink="/login">
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                </ng-template>
            </iq-phone-header>
            <div [ngClass]="{ 'iq-phone-page-content' : showDefaultHeader, 'iq-phone-page' : !showDefaultHeader }" [@phoneRouteAnimations]="prepareRouteAnimation(outlet)">
                <router-outlet #outlet="outlet"></router-outlet>
            </div>
        </div>
    </ng-template>
</div>

<!--Used as the outlet for things we build print pages for-->
<router-outlet name='print'></router-outlet>
