import { TicketEntryExcavatorInfoCompany } from "./TicketEntryExcavatorInfoCompany.model";

export class TicketEntryExcavatorInfoResponse {

    public LastVerifiedDate: Date;
    public MustVerifyExcavator: boolean;
    public VerifyFrequency: string;

    //  Extra Contact Info
    public HasLogin: boolean;
    public LastLoginDate?: Date;

    public ContactInfoChanged: boolean;

    //  These are populated for any Excavator Company fields that are different between the referenced
    //  Ticket and the current information
    public CompanyID?: number;
    public CompanyName?: string;
    public CompanyTypeID?: string;
    public CompanyIndustryTypeID?: string;

    //  These are populated for any Excavator Office fields that are different between the referenced
    //  Ticket and the current information
    public OfficeName?: string;
    public OfficePhone?: string;

    //  These are populated for any Excavator Contact fields that are different between the referenced
    //  Ticket and the current information
    public ExcavatorID?: number;
    public ContactName?: string;
    public ContactMainPhone?: string;

    //  Changed Address fields - which belong to either the Office or the Contact (if homeowner)
    public Address1?: string;
    public Address2?: string;
    public City?: string;
    public State?: string;
    public Zip?: string;
    public County?: string;

    // Linked-to Companies & Offices
    public Companies: TicketEntryExcavatorInfoCompany[];
    public IsHomeowner: boolean;

    //  These are messages that will be displayed in the excavator panel for the currently picked excavator.
    //  Only applies when editing/copying/resuming a ticket.
    //  User must acknowledge these messages before the ticket can be saved.
    //  Picking a different excavator entity will reset (remove) the warning.
    //  i.e. Contact is deleted and a ticket is being edited: Will result in adding a new Contact - warn the user
    //       and make them accept what is about to happen (if they don't pick a valid contact).
    public PickedCompanyWarning?: string;
    public PickedOfficeWarning?: string;
    public PickedContactWarning?: string;

    //  Flags that indicate if the user needs to confirm that they want to update an existing contact rather than
    //  add a new one.  Used to force the user to confirm changes after they pick a company/office/contact and then
    //  modify it to make sure that they did not intend to create a new record - happens a lot for DigSafe because they
    //  shared "Excavator IDs" in their old system.  Can also happen when phone numbers are the same for multiple people.
    //  Doing this is enabled by SystemSettings.ConfirmExcavatorUpdate.
    public MustConfirmCompanyUpdate: boolean;
    public MustConfirmOfficeUpdate: boolean;
    public MustConfirmContactUpdate: boolean;
    public MustConfirmContactAddToOffice: boolean;

    // An error message to display to the user if doing a dialog-based ticket function and we can't save the
    // ticket due to an issue with the excavator (i.e. The ticket being edited references a deleted
    // company/office/contact).
    public PreventDialogEditReason?: string;
}
