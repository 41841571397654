import { IConfigValue } from "Models/Interfaces/IConfigValue.interface";
import { TicketFunctionActionEnum } from "Enums/TicketFunctionAction.enum";
import { TicketEditVersionRuleEnum } from "Enums/TicketEditVersionRule.enum";

export class TicketFunction implements IConfigValue {
    xmin: number;
    public ID: string;
    public Name: string

    public Action: TicketFunctionActionEnum;
    public IsDefaultForAction: boolean;

    /**
     *  When false, the user is not able to pick this - it will be selected/used by the system or in response
     *  to other values entered into the ticket (like the work start to trigger Suspend Until).
     */
    public IsUserSelectable: boolean;

    /**
     *  If set, this function is not allowed from the ... menu of the ticket list.
     *  Default is false - all ticket functions default to being shown in the ... menu.
     */
    public DoNotAllowFromTicketList: boolean;

    /**
     *  If set and picked from the ... menu of the ticket list, the page will switch to show the ticket details
     *  and then show the edit dialog.  Only applies to functions that use a dialog to do the edit.
     *  Default is false - a function that uses a dialog (including Cancel) will not navigate to the ticket details.
     */
    public RequireViewTicket: boolean;

    public TicketEditVersionRule: TicketEditVersionRuleEnum;

    /**
    * Determines if a ticket being edited with this function should re-geocode it's system digsite.
    * This is checked in TicketEntryFormBase.ShouldGeocodeTicketDigSiteForTicketBeingEdited() after some other pre-checks.
    * A ticket function that uses a dialog should always set this to false since they do not show a map or allow changing the dig site fields.
    * A ticket function that uses the full form and generates a new Ticket Number should (probably?) always set this to true.
    */
    public RegeocodeWhenEditing: boolean;

    /**
     *  If not null, determines if the Verify Location dialog should be shown for this Ticket Function.
     *  If null, will depend on the setting on the Ticket Type.
     */
    public MustVerifyLocation?: boolean;

    /**
     * If a new ticket number is being created, service areas are ALWAYS recalculated.
     * Otherwise, if TicketFunction.RecalculateServiceAreas has a value, this determine the behavior (either true or false).
     * And if TicketFunction.RecalculateServiceAreas is null, we use the value from TicketType.RecalculateServiceAreas
     * (which is not nullable).
     * Normally, everyone should configure this on the TicketFunction.  Except for AZ...  But initially, this flag did not exist
     * and all One Calls had TicketType.RecalculateServiceAreas set to true.
     */
    public RecalculateServiceAreas?: boolean;

    /**
     *  If not null, determines if Service Areas can be manually added when using this Ticket Function.
     *  Also requires that the user has permission to do this.
     *  If null, will depend on the setting on the Ticket Type.
     */
    public CanManuallyAddServiceAreas?: boolean;

    /**
     *  If not null, determines if manually added Service Areas can be removed when using this Ticket Function.
     *  Also requires that the user has permission to do this.
     *  If null, will depend on the CanManuallyAddServiceAreas setting on the Ticket Type.
     */
    public CanRemoveManuallyAddedServiceAreas?: boolean;

    /**
     *  If true, can upload ticket attachments when doing this ticket function.
     */
    public CanUploadAttachments?: boolean;
}
