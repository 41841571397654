import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthComponentBase } from 'iqCognito/auth-component-base';
import { PasswordRequirements, ViewStateEnum } from 'iqCognito/models';
import { IqAwsCognitoService } from 'iqCognito/Services/iq-aws-cognito.service';
import { controlsEqualValidator, validateUsername } from 'iqCognito/validation';
import { PersonLinkLoginResponse } from 'Models/People/PersonLinkLoginRequest.model';
import { finalize, take } from 'rxjs/operators';

@Component({
    selector: 'auth-link-person-sign-up',
    templateUrl: './LinkPersonSignUp.component.html',
    styleUrls: ['./LinkPersonSignUp.component.scss']
})
export class ExactixLinkPersonSignUpComponent extends AuthComponentBase {

    private _passwordRequirements: PasswordRequirements;
    @Input() set PasswordRequirements(val: PasswordRequirements) {
        let defaultRequirements = new PasswordRequirements();
        this._passwordRequirements = { ...defaultRequirements, ...val };
    }
    get PasswordRequirements() {
        return this._passwordRequirements;
    }

    @Input() Model: PersonLinkLoginResponse;

    groupValid: boolean = false;

    ShouldDisplay(): boolean {
        return this.authState.state == ViewStateEnum.createNewUser;
    }

    BuildForm(): FormGroup {
        const email = this.Model ? this.Model.Email : null;
        return new FormGroup({
            username: new FormControl(email, [Validators.required, validateUsername]),
            password: new FormControl(null, [Validators.required]),
            confirmPassword: new FormControl(null, [Validators.required])
        }, [controlsEqualValidator('password', 'confirmPassword')]);
    }

    get usernameControl() { return this.group.get("username"); }
    get passwordControl() { return this.group.get("password"); }
    get confirmPasswordControl() { return this.group.get("confirmPassword"); }

    constructor(protected awsCognitoService: IqAwsCognitoService) {
        super(awsCognitoService);

    }

    onValueIsValid(isValid: boolean) {
        this.groupValid = isValid;
    }

    onReturnToLogin() {
        this.awsCognitoService.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
    }

    onSignUp() {
        if (!this.group.valid) {
            return null;
        }

        const user = this.group.value;

        user.email = this.Model.Email;
        user.given_name = this.Model.FirstName;
        user.family_name = this.Model.LastName;

        //Clear this out so we don't try to send it on the signup call to cognito
        user.confirmPassword = null;

        if (user == null) {
            return;
        }

        this.isBusy = true;

        this.awsCognitoService.CreateNewUser(user, { AutoConfirmUser: "true" }).pipe(take(1), finalize(() => this.isBusy = false))
            .subscribe(null, err => this.SetError(err));
    }

    public ErrorInstructions: string = null;

    protected SetError(err) {
        super.SetError(err);

        if (this._errorMessage.indexOf("Email is in use") !== -1) {
            this._errorMessage = "An account already exists with that email.";
            this.ErrorInstructions = "Exactix systems share login credentials.  If you already have an account on this or another Exactix system, please use the 'Already have login' link below and login with the same username and password you use on the other system.  That will link the existing account to this system so you have a single username/password for all Exactix systems.";
        } else
            this.ErrorInstructions = null;
    }
}

