import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IqAwsCognitoService } from '../../../Services/iq-aws-cognito.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormComponentBase } from '../../../form-component-base';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CognitoUser } from '@aws-amplify/auth';
import { take, finalize } from 'rxjs/operators';
import { validateUsername } from '../../../validation';

@Component({
  templateUrl: './change-alternate-username-dialog.component.html',
  styleUrls: ['./change-alternate-username-dialog.component.css']
})
export class iQChangeAlternateUsernameDialogComponent extends FormComponentBase {
  BuildForm(): FormGroup {
    return new FormGroup({
      PreferredUsername: new FormControl(null, [Validators.required, validateUsername])
    });
  }

  loggedInUser: CognitoUser;
  isSignedIn: boolean;

  username: string;
  currentPreferredUsername: string;

  get preferredUsername() { return this.group.get("PreferredUsername"); }
  
  constructor(protected awsCognitoService: IqAwsCognitoService, 
    public dialogRef: MatDialogRef<iQChangeAlternateUsernameDialogComponent>, private snackBar: MatSnackBar) {
    super();
    this.isBusy = true;

    awsCognitoService.signInEventChange$.pipe(take(1))
      .subscribe(val => {
        this.isSignedIn = val.SignedIn;

        if (val.SignedIn) {
          this.loggedInUser = val.User;
          this.username = this.loggedInUser.getUsername();
          this.loggedInUser.getUserAttributes((err, attributes) =>{
            this.isBusy = false;

            if (err){
              this.SetError(err);
              this.snackBar.open(err.message, null);
            }
            else{
              let preferredUsernameAtt = attributes.find(f=>f.getName() === "preferred_username");
              if (preferredUsernameAtt != null){
                this.preferredUsername.setValue(preferredUsernameAtt.getValue());
                this.currentPreferredUsername = preferredUsernameAtt.getValue();
              }
            }
              
          });
          
        }
        else
          this.isBusy = false;
        
      }, (err) => {
        this.isBusy = false;
        this.SetError(err);
      });
      
   }

  ngOnInit() {
    super.ngOnInit();

    this.dialogRef.updateSize("500px");
  }

  onChangePreferredUsername(){
    if (!this.group.valid)
      return;

    this.isBusy = true;

    this.awsCognitoService.UpdatePreferredUsername(this.loggedInUser, this.preferredUsername.value)
    .pipe(finalize(() => this.isBusy = false))
    .subscribe(success=>{
      
      this.snackBar.open("Alternate Username Changed", null);
      this.dialogRef.close(this.preferredUsername.value);

    }, err => {
      let msg = err.message;

      this.snackBar.open(msg, null);
    });
  }
}
