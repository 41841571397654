<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Complete Suspended Ticket?</h1>
<mat-dialog-content style="display:flex; flex-direction:column; padding-left:2em; padding-right:2em">
    <div style="text-align:center; padding-bottom:20px">This will complete the suspended Ticket with no further changes and send it to all affected Service Areas.</div>
    <div style="text-align:right">Are you sure you want to Complete this Ticket?</div>
    <ticket-send-copy-to #sendCopyTo [ticket]="Ticket"></ticket-send-copy-to>
</mat-dialog-content>
<mat-dialog-actions class="centered-dialog-buttons">
    <iq-icon-button button-class="Dialog" icon="Cancel" mat-dialog-close>Cancel</iq-icon-button>
    <iq-icon-button button-class="Dialog" icon="OK" iqAutoFocus [disabled]="!CanComplete()" (click)="OnCompleteTicket()">Complete</iq-icon-button>
</mat-dialog-actions>
