export enum TicketStatusEnum {
    Incomplete = 0,
    Suspended = 1,
    Complete = 2,
    Void = 4
}

export enum TicketStatusEnumDescription {
    Incomplete = "Incomplete",
    Suspended = "Suspended",
    Complete = "Complete",
    Void = "Void"
}
