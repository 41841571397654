import { Component, Input } from '@angular/core';


@Component({
    selector: 'iq-phone-settings-button',
    templateUrl: './PhoneSettingsButton.component.html',
    styleUrls: ['./PhoneSettingsButton.component.scss']
})
export class PhoneSettingsButtonComponent {

    @Input() disabled: boolean;

    constructor() {
    }
}
