import { Component, Directive, TemplateRef, ContentChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'Services/AuthenticationService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';




@Directive({
    selector: '[iqPhoneHeaderLeft]'
})
export class PhoneHeaderLeftDirective {

}

@Directive({
    selector: '[iqPhoneHeaderCenter]'
})
export class PhoneHeaderCenterDirective {

}

@Directive({
    selector: '[iqPhoneHeaderRight]'
})
export class PhoneHeaderRightDirective {

}




@Component({
    selector: 'iq-phone-header',
    templateUrl: './PhoneHeader.component.html',
    styleUrls: ['./PhoneHeader.component.scss']
})
export class PhoneHeaderComponent implements OnDestroy {

    private destroyed$: Subject<void> = new Subject();

    @ContentChild(PhoneHeaderLeftDirective, { read: TemplateRef, static: false }) leftTemplate: TemplateRef<any>;
    @ContentChild(PhoneHeaderCenterDirective, { read: TemplateRef, static: false }) centerTemplate: TemplateRef<any>;
    @ContentChild(PhoneHeaderRightDirective, { read: TemplateRef, static: false }) rightTemplate: TemplateRef<any>;

    OccLogoImage: string = null;
    OccLogoHeight: string;
    OccMobileLogoHeight: string;

    constructor(private authenticationService: AuthenticationService) {
        //Need to always get when it changes.  It will be an empty user if logged out
        this.authenticationService.CurrentUserObserver(true).pipe(takeUntil(this.destroyed$))
            .subscribe((appUser) => {
                if (appUser.OneCallCenterSettings) {
                    this.OccLogoImage = appUser.OneCallCenterSettings.LogoImage;
                    this.OccLogoHeight = appUser.OneCallCenterSettings.LogoHeight ?? "60px";
                    this.OccMobileLogoHeight = appUser.OneCallCenterSettings.MobileLogoHeight ?? "45px";
                }
            });
    }

    ngOnDestroy() {

        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
