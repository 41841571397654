import { Component, Input, ElementRef, Output, EventEmitter, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faThList, faChalkboard, faCircle, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faAnalytics, faPlus, faQuestion, faTrash, faPen, faSearch } from '@fortawesome/pro-solid-svg-icons';
import {
    faCopy, faTimes, faCheck, faFileTimes, faMapMarkerExclamation, faExternalLink, faBomb,
    faEdit, faUnlockAlt, faStepForward, faBan, faWindowClose, faRedo, faSave, faShare, faChartLine,
    faSignIn, faArrowToBottom, faBookmark, faCommentDots, faPrint, faEnvelope, faCommentLines
} from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faExpandAlt, faShovel } from '@fortawesome/pro-light-svg-icons';

//  1 button to rule them all.  Centralizes the styling and functionality of a button or link in 1 place
//  so that they all look and act consistently.
//  Does more than just css styling!  It also enforces that clicks are debounced to prevent double-clicks
//  from triggering multiple times.  Also handles space & enter to activate the click event.
//  Icons can be included to the right or left and are set by pre-defined names so that they are easy to
//  change globally if we need to.

//  Angular FontAwesome links:
//      Features: https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/features.md
//      Usage: https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage.md
//      Custom icons: https://stackoverflow.com/questions/11426172/add-custom-icons-to-font-awesome
//                    https://github.com/FortAwesome/Font-Awesome/wiki/Customize-Font-Awesome

/**
 * Header: Style as a Header Button - for the main buttons in the top-right area of a page.
 * Dialog: Style the button for use at the bottom of a dialog.
 * Button: Style as a regular Button.
 * Link: Does not currently style as expected - think something got changed at some point but it's also not used anywhere
 * If you only want a link style, do not use this component.  Just put "class='link'" on the element and use the 
 *      iqPreventDoubleClick directive to debounce the click event.
 **/
type ButtonClassType = "Header" | "Dialog" | "Button" | "Button-Small" | "Link";

type AlignType = "left" | "right";

type IconNameType = "New" | "List" | "Dashboard" | "Statistics" | "Activity" | "Blacklist" | "Login"
    | "Complete" | "Suspended" | "Incomplete" | "Void" | "Resume" | "Discard" | "Unlock" | "ViewMostRecent"
    | "WorkComplete" | "WorkNotComplete"
    | "TicketFunction-Cancel" | "Copy" | "Edit"
    | "TicketFunction-Correction"                                                                                                   //  NY and FL
    | "TicketFunction-Damaged or Discovered Line" | "TicketFunction-Locate Again" | "TicketFunction-Demolition"                     //  NY
    | "TicketFunction-Edit"                                                                                                         //  AZ
    | "TicketFunction-Renewal" | "TicketFunction-Update"                                                                            //  FL
    | "TicketFunction-In Reference" | "TicketFunction-Add Service Area"                                                             //  DigSafe
    | "TicketFunction-Extension" | "TicketFunction-Remark" | "TicketFunction-Retransmit Ticket"                                     //  IN/KY
    | "TicketFunction-Additional Notice" | "TicketFunction-Add Comments" | "TicketFunction-On-Site Exposed Facility"                //  IN/KY
    | "TicketFunction-No Show" | "TicketFunction-Resend" | "TicketFunction-Additional Info"                                         //  SC
    | "Cancel" | "OK" | "Save" | "View" | "Close" | "Next" | "Previous" | "Download" | "Help" | "Search" | "Delete"
    | "Print" | "Email"
    | "Comments";

@Component({
    selector: 'iq-icon-button',
    templateUrl: './IconButton.component.html',
    styleUrls: ['./IconButton.component.scss']
})
export class IconButtonComponent implements AfterViewInit, OnDestroy {

    private _ButtonClass: ButtonClassType = "Button";
    @Input("button-class")
    public get ButtonClass(): ButtonClassType { return this._ButtonClass }
    public set ButtonClass(buttonClass: ButtonClassType) {
        this._ButtonClass = buttonClass;
        this.Configure();
    }

    //  If null, an icon is not used
    private _IconName: IconNameType = null;
    @Input("icon")
    public get IconName(): IconNameType { return this._IconName }
    public set IconName(iconName: IconNameType) {
        this._IconName = iconName;
        this.Configure();
    }

    private _Align: AlignType = "left";
    @Input("align")
    public get Align(): AlignType { return this._Align }
    public set Align(align: AlignType) {
        this._Align = align;
        this.Configure();
    }

    private _Disabled: boolean = false;
    @Input("disabled")
    public get Disabled(): boolean { return this._Disabled; }
    public set Disabled(disabled: boolean) {
        this._Disabled = disabled;

        if (this._Button)
            this._Button.nativeElement.tabIndex = this._Disabled ? -1 : 0;

        this.Configure();
    }

    @Input("title")
    public Title: string = "";

    //  Change this to control the debounce time.  Set to 0 to disable debounce
    @Input()
    public DebounceTime: number = 700;

    @Output("click")
    public ClickEvent = new EventEmitter();

    //  Can use these 2 events to be notified when the user tabs in order to focus a different control.
    //  If a subscriber is set, will cause the tab event to do nothing so the subscriber MUST focus to another control!
    @Output()
    public OnForwardTab = new EventEmitter<boolean>();
    @Output()
    public OnBackTab = new EventEmitter<boolean>();

    @ViewChild('button')
    private _Button: ElementRef;

    public Icon: IconDefinition;// | any;
    public CircleIcon = faCircle;

    public ComponentClasses: { [key: string]: boolean } = {};

    public StackStyles: { [key: string]: any } = {};

    public IconClasses: string[] = [];
    public IconStyles: { [key: string]: any } = {};

    constructor(private _Element: ElementRef) {
        this.Configure();


        //Why was this set here if the icon is allowed to be null?  And/or why is it after the Configure call if the Configure method sets it null?
        //Also if you set the disabled property (even to false, so it's enabled) and don't set a icon it will have a null value for icon, so I'm commenting this out.
        //If it's put back in then need to update the right side actions on the Excavator Contact, Destination, Communication and Service Area Pages
        //this.Icon = faAnalytics;
    }

    public ngOnDestroy(): void {
        this._Element.nativeElement.focus = null;
        this._Element = null;
    }

    public ngAfterViewInit(): void {
        this._Button.nativeElement.tabIndex = this._Disabled ? -1 : 0;

        //  Change the focus function of this components nativeElement to focus the Button.
        //  Needed for the iqAutoFocus directive to be able to set focus to the button.
        this._Element.nativeElement.focus = () => this._Button.nativeElement.focus();
    }

    focus() {
        setTimeout(() => this._Button.nativeElement.focus());
    }

    private Configure(): void {
        this.StackStyles = {};
        this.IconClasses = [];
        this.IconStyles = {};

        //  This is another way to create a "circle" border around the icon.  But, it creates a border around the
        //  icon which is not necessarily a square.  So for icons (like the Next/Previous icons), it creates an ellipse
        //  that looks very odd when compared to others that create a better circle.  So using the icon stack method.
        //  Left this here in case we want to switch back - it uses less dom elements so would be nice if we could do
        //  it this way but it's only a couple extra so not a big deal.
        //  Also see this that describes the 2 methods: https://markheath.net/post/font-awesome-circle-background
        //this.IconStyles["padding"] = "8px";
        //this.IconStyles["border-radius"] = "50%";
        //this.IconStyles["background"] = "white";

        if (this.Align === 'left')
            this.StackStyles["margin-right"] = "5px";
        else {
            this.StackStyles["margin-left"] = "5px";
            this.StackStyles["order"] = 1;
        }

        const iconSettings = this.GetIconSettings();
        if (iconSettings) {
            this.Icon = iconSettings.icon;

            if (this._Disabled)
                this.IconStyles["color"] = 'darkgray';
            else if (iconSettings.color)
                this.IconStyles["color"] = iconSettings.color;
            else
                this.IconClasses.push('primary-color');     //  Default to the primary color

            if (iconSettings.fontSize)
                this.IconStyles["font-size"] = iconSettings.fontSize;

            if (iconSettings.stroke)
                this.IconStyles["stroke"] = iconSettings.stroke;
            if (iconSettings.strokeWidth)
                this.IconStyles["stroke-width"] = iconSettings.strokeWidth;
        }
        else
            this.Icon = null;

        //switch (this._ButtonClass) {
        //    case "Header":
        //    case "Dialog":
        //    case "Link":
        //        break;
        //}

        this.ComponentClasses = {
            'disabled': this._Disabled,
            'header': this._ButtonClass === 'Header',
            'dialog': this._ButtonClass === 'Dialog',
            'button': this._ButtonClass === 'Button' || this._ButtonClass === 'Button-Small',
            'link': this._ButtonClass === 'Link',
            'small-text': this._ButtonClass === 'Button-Small',
        };
    }

    private GetIconSettings(): { icon: IconDefinition, color?: string, fontSize?: string, stroke?: string, strokeWidth?: string }  {

        if (!this._IconName)
            return null;

        switch (this._IconName) {
            case "New":
                return { icon: faPlus };
            case "List":
                return { icon: faThList };
            case "Dashboard":
                return { icon: faChalkboard };
            case "Statistics":
                return { icon: faAnalytics };
            case "Activity":
                return { icon: faChartLine };
            case "Blacklist":
                return { icon: faBan };
            case "Login":
                return { icon: faSignIn };
            case "Complete":
                return { icon: faCheck, color: 'green', fontSize: "1.5em" };
            case "Suspended":
                return { icon: faBookmark, color: 'orange', fontSize: "1.2em" };
            case "Incomplete":
            case "WorkComplete":
                return { icon: faCheck, color: 'green', fontSize: "1.2em" };
            case "WorkNotComplete":
                return { icon: faShovel, color: 'red', fontSize: "1.2em" };
            case "Help":
                return { icon: faQuestion, color: 'yellow', fontSize: "1.3em", stroke: 'darkslategray', strokeWidth: '10px' };
            case "Void":
                return { icon: faTimes, color: 'red', fontSize: "1.5em" };
            case "Resume":
                return { icon: faExternalLink, color: 'slategray' };
            case "Edit":
                return { icon: faEdit, color: 'slategray', fontSize: "1.1em" };
            case "Unlock":
                return { icon: faUnlockAlt, color: 'slategray' };
            case "ViewMostRecent":
                return { icon: faStepForward, color: 'slategray', fontSize: "1.2em" };
            case "Discard":
            case "Delete":
                return { icon: faTrash, color: 'blue' };
            case "Copy":
                return { icon: faCopy, color: 'slategray', fontSize: "1.2em" };
            case "TicketFunction-Cancel":                           //  NY
                return { icon: faFileTimes, color: 'slategray', fontSize: "1.2em" };
            case "TicketFunction-Correction":                       //  NY, FL
            case "TicketFunction-Edit":                             //  AZ
            case "TicketFunction-Retransmit Ticket":                //  IN/KY
            case "TicketFunction-Resend":                           //  SC
                return { icon: faPen, color: 'slategray', fontSize: "1.2em" };
            case "TicketFunction-Damaged or Discovered Line":       //  NY
            case "TicketFunction-Additional Notice":                //  IN/KY
                return { icon: faMapMarkerExclamation, color: 'slategray', fontSize: "1.3em" };
            case "TicketFunction-Demolition":                       //  NY
                return { icon: faBomb, color: 'slategray', fontSize: "1.2em" };
            case "TicketFunction-Locate Again":                     //  NY
            case "TicketFunction-Extension":                        //  IN/KY
                return { icon: faExpandAlt, color: 'slategray', fontSize: "1.2em" };
            case "TicketFunction-No Show":                          //  SC
                return { icon: faQuestion, color: 'slategray', fontSize: "1.2em" };
            case "TicketFunction-Renewal":                          //  FL
            case "TicketFunction-Remark":                           //  IN/KY
                return { icon: faRedo, color: 'slategray', fontSize: "1.1em" };
            case "TicketFunction-Update":                           //  FL, SC
                return { icon: faEdit, color: 'slategray', fontSize: "1.1em" };
            case "TicketFunction-In Reference":                     //  DigSafe
            case "TicketFunction-Add Comments":                     //  IN/KY
            case "TicketFunction-Additional Info":                  //  SC
                return { icon: faCommentDots, color: 'slategray', fontSize: "1.1em" };
            case "TicketFunction-On-Site Exposed Facility":         //  KY
                return { icon: faShovel, color: 'slategray', fontSize: "1.1em" };
            case "TicketFunction-Add Service Area":                 //  DigSafe
                return { icon: faPlus, color: 'slategray', fontSize: "1.1em" };
            case "Cancel":
                return { icon: faBan, color: 'red', fontSize: "1.2em" };
            case "OK":
                return { icon: faCheck, color: 'green', fontSize: "1.5em" };
            case "Save":
                return { icon: faSave, color: 'green', fontSize: "1.2em" };
            case "View":
                return { icon: faShare, color: 'green', fontSize: "1.2em" };
            case "Close":
                return { icon: faWindowClose, color: 'slategray' };
            case "Next":
                return { icon: faChevronRight, fontSize: "1.2em" };
            case "Previous":
                return { icon: faChevronLeft, fontSize: "1.2em" };
            case "Download":
                return { icon: faArrowToBottom, color: 'slategray', fontSize: "1.2em" };
            case "Search":
                return { icon: faSearch, color: 'green', fontSize: "1.2em" };
            case "Print":
                return { icon: faPrint, color: 'green', fontSize: "1.2em" };
            case "Email":
                return { icon: faEnvelope, color: 'green', fontSize: "1.2em" };
            case "Comments":
                return { icon: faCommentLines };
            default:
                console.error("Unhandled Icon Name:", this._IconName);
                return { icon: faQuestion, color: 'red', fontSize: "1.2em" };
        }
    }

    //  All clicks (and space/enter) are debounced using the iqPreventDoubleClick directive to prevent
    //  double-submitting for click-happy users.  Any tasks that execute in response to a click that are
    //  potentially long running (i.e. any api call) should also disable this component when they are executing.
    public OnDebouncedClick(event: MouseEvent): void {
        if (!this._Disabled)
            this.ClickEvent.next(event);
    }

    public OnKeydown(event: KeyboardEvent): void {
        if (event.code === "Tab") {
            if (event.shiftKey && (this.OnBackTab.observers.length > 0)) {
                this.OnBackTab.next(true);
                event.stopPropagation();
                event.preventDefault();
            }
            else if (!event.shiftKey && (this.OnForwardTab.observers.length > 0)) {
                this.OnForwardTab.next(true);
                event.stopPropagation();
                event.preventDefault();
            }
        }
    }
}
