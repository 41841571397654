export enum PermissionsEnum {
    NA = 0,

    AdministerLogins = 90,

    Communications_View = 100,
    Communications_Edit,

    Destination_View = 200,
    Destination_Edit,
    Destination_Delete,
    Destination_Create,
    Destination_Copy,

    ExcavatorCompany_View = 250,
    ExcavatorCompany_Edit,
    ExcavatorCompany_Delete,
    ExcavatorCompany_Create,
    //ExcavatorCompany_Copy,     //  We don't do this atm!

    ExcavatorOffice_View = 260,
    ExcavatorOffice_Edit,
    ExcavatorOffice_Delete,
    ExcavatorOffice_Create,

    //We don't do this ATM
    //ExcavatorOffice_Copy,

    ExcavatorContact_View = 270,
    ExcavatorContact_Edit,
    ExcavatorContact_Delete,
    ExcavatorContact_Create,
    //We don't do this ATM
    //ExcavatorContact_Copy,

    Member_View = 650,
    Member_Edit,
    Member_Delete,
    Member_Create,
    Memeber_Copy,

    OneCallCenter_View = 750,
    OneCallCenter_Edit,

    Person_View = 800,
    Person_Create,
    Person_Edit,
    Person_Delete,
    Person_Copy,
    Person_PersonalProfile,

    Projects_View = 820,
    Projects_Create,
    Projects_Edit,
    Projects_Delete,
    Projects_Copy,

    Registration_View = 875,
    Registration_Create,
    Registration_Edit,
    Registration_Delete,
    Registration_CompleteRegistrationChanges,
    Registration_ApproveRegistrationChanges,
    Registration_ActivateRegistration,

    Report_View = 890,

    Role_View = 900,
    Role_Create,
    Role_Edit,
    Role_Delete,
    Role_Copy,

    ServiceArea_View = 950,
    ServiceArea_Create,
    ServiceArea_Edit,
    ServiceArea_Delete,
    ServiceArea_Copy,

    ServiceProvider_View = 980,
    ServiceProvider_Create,
    ServiceProvider_Edit,
    ServiceProvider_Delete,
    ServiceProvider_Copy,
    
    Ticket_Create = 1000,
    Ticket_View,
    Ticket_Copy,
    Ticket_UnlockTickets,
    TicketCreation_ViewServiceAreas,        //  Allows viewing service areas during ticket creation
    TicketCreation_EditServiceAreas,        //  Allows manually adding/removing service areas to tickets
    Ticket_View_ForCompany,
    Ticket_View_ForOffice,
    TicketCreation_CreateMultipleTickets,
    TicketCreation_UnsuppressCustomBuffer,  //  Allows unsuppressing service areas that have been suppressed by a custom buffer on the service area

    TicketResponse_Create = 1100,
    TicketResponse_View,

    SystemStatus_View = 1200,

    SystemConfiguration_View = 1210,
    SystemConfiguration_Create = 1211,
    SystemConfiguration_Edit = 1212,
    SystemConfiguration_Delete = 1213,
}
