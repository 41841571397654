import { Component } from '@angular/core';
import { faCog } from '@fortawesome/pro-light-svg-icons';

@Component({
    selector: 'iq-phone-settings-button-icons',
    templateUrl: './PhoneSettingsButtonIcons.component.html',
    styleUrls: ['./PhoneSettingsButtonIcons.component.scss']
})
export class PhoneSettingsButtonIconsComponent {

    icon = faCog;

    constructor() {
    }
}
