import { Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ReplaySubject } from 'rxjs';

export class BrowserSizeEvent {
    constructor(public isDesktop: boolean, public isMobile: boolean, public isPhone: boolean) {}
}

@Injectable({
    providedIn: 'root'
})
export class BrowserSizeService {

    public browserSize: ReplaySubject<BrowserSizeEvent> = new ReplaySubject();


    private mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;

    private phoneQuery: MediaQueryList;
    private _phoneQueryListener: () => void;

    constructor(media: MediaMatcher) {
        //May want to move this to a service that we can inject into other places so we can have defined shareable set sizes to fire events?
        this.mobileQuery = media.matchMedia('(max-width: 1279px)');       

        this._mobileQueryListener = () => {
            this.SizeChanged();
        }
        this.mobileQuery.addListener(this._mobileQueryListener);


        this.phoneQuery = media.matchMedia('(max-width: 599px)');

        this._phoneQueryListener = () => {
            this.SizeChanged();
        }
        this.phoneQuery.addListener(this._phoneQueryListener);


        this.SizeChanged();
    }

    private SizeChanged() {
        this.browserSize.next(new BrowserSizeEvent(!this.mobileQuery.matches && !this.phoneQuery.matches, this.mobileQuery.matches, this.phoneQuery.matches));
    }
}
