import { Injectable } from '@angular/core';
import { ExcavatorAutocompleteRequest } from '@iqModels/Excavators/ExcavatorAutocompleteRequest.model';
import { ExcavatorAutocompleteResponse } from '@iqModels/Excavators/ExcavatorAutocompleteResponse.model';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { ExcavatorCompany } from 'Models/Excavators/ExcavatorCompany.model';
import { ExcavatorSelfRegistrationSearchRequest } from "Models/Excavators/ExcavatorSelfRegistrationSearchRequest.model";
import { ExcavatorSelfRegistrationSearchResponse } from "Models/Excavators/ExcavatorSelfRegistrationSearchResponse.model";
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnumService } from 'Services/Enum.service';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { UIDateTimeFormat } from 'Shared/Utils/MaskFormats.model';

@Injectable({
    providedIn: 'root'
})
export class ExcavatorCompanyService extends CRUDBaseService<ExcavatorCompany> {

    protected apiPath: string = "Administration/ExcavatorCompany";

    ViewPermission: PermissionsEnum = PermissionsEnum.ExcavatorCompany_View;//Needs to be overriden and set to the proper permission
    EditPermission: PermissionsEnum = PermissionsEnum.ExcavatorCompany_Edit;//Needs to be overriden and set to the proper permission
    CreatePermission: PermissionsEnum = PermissionsEnum.ExcavatorCompany_Create;//Needs to be overriden and set to the proper permission
    DeletePermission: PermissionsEnum = PermissionsEnum.ExcavatorCompany_Delete;//Needs to be overriden and set to the proper permission
    //CopyPermission: PermissionsEnum = PermissionsEnum.ExcavatorCompany_Copy;//Needs to be overriden and set to the proper permission

    constructor(protected services: CRUDServices, private enumService: EnumService) {
        super(services);
    }

    public TicketAutoComplete(value: string, column: string, oneCallCenterCode: string = undefined): Observable<ExcavatorAutocompleteResponse[]> {
        const searchRequest = new ExcavatorAutocompleteRequest(column, value);
        searchRequest.OneCallCenterCode = oneCallCenterCode;

        return this.services.http.post<ExcavatorAutocompleteResponse[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/TicketAutoComplete", searchRequest);
    }

    public SearchForSelfRegistration(searchRequest: ExcavatorSelfRegistrationSearchRequest): Observable<ExcavatorSelfRegistrationSearchResponse> {
        return this.services.http.post<ExcavatorSelfRegistrationSearchResponse>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/SearchForSelfRegistration", searchRequest);
    }

    public CanDelete(excavatorCompanyIDs: string[]): Observable<ExcavatorCompany[]> {//Use an array incase we ever allow deleteing multiple.  We do for Companies and Contacts.
        return this.services.http.post<ExcavatorCompany[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/CanDelete", excavatorCompanyIDs);
    }

    public GetAvailableSearchColumnsAndFilters(): Observable<{ columns: SearchColumn[], filters: SearchColumn[] }> {

        let columns = [new SearchColumn("Name", "Name", "Name", "Name"),
            new SearchColumn("CompanyID", "Company ID", "CompanyID", "CompanyID")];

        const industryType = new SearchColumn("IndustryType_Name", "Industry Type", "IndustryType.Name", "IndustryType.ID");
        industryType.filterOptions = this.enumService.IndustryTypes.pipe(map(vals => {
            const results = [];
            vals.forEach(f => results.push(new SelectOption(f.ID, f.Name)));
            return results;
        }));
        columns.push(industryType);

        const companyType = new SearchColumn("CompanyType_Name", "Company Type", "CompanyType.Name", "CompanyType.ID");
        companyType.filterOptions = this.enumService.CompanyTypes.pipe(map(vals => {
            const results = [];
            vals.forEach(f => results.push(new SelectOption(f.ID, f.Name)));
            return results;
        }));
        
        columns.push(companyType);

        const isActive = new SearchColumn("IsActive", "Active", "IsActive", "IsActive");
        isActive.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")]);
        isActive.class = "red-green";
        isActive.width = "10%";
        columns.push(isActive);


        const modifyDate = new SearchColumn("LastModifyDateTime", "Last Modified", "LastModifyDateTime", "LastModifyDateTime");
        modifyDate.useDateSearch = true;
        modifyDate.ShowFutureDateOptions = false;
        modifyDate.formatType = 'date';
        modifyDate.width = "15%";
        modifyDate.format = UIDateTimeFormat;
        columns.push(modifyDate);

        columns = columns.sort((a, b) => a.name.localeCompare(b.name));
        return of({ columns: columns, filters: columns });
    }
}
