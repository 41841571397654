import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '@iqModels/People/Person.model';

@Pipe({
    name: 'personName'
})
export class PersonNameFormatPipe implements PipeTransform {

    //FL = First Last
    //LF = Last, First
    transform(value: Person, format: 'FL' | 'LF' | null): string {
        if (!value) {
            return "";
        }

        let first = value.FirstName == null ? '' : value.FirstName;
        let last = value.LastName == null ? '' : value.LastName;
        switch (format) {
            case 'FL':
                return first + ' ' + last;
            case 'LF':
                return last + ', ' + first;
            default:
                return first + ' ' + last;
        }
    }

}
