import { Component, ViewChild, ViewContainerRef, Renderer2 } from '@angular/core';
import { AuthenticationService } from 'Services/AuthenticationService';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { slideInFromLeft, slideInFromRight } from 'Shared/Utils/Animations';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';

import { faHomeAlt, faReceipt, faCog, faCheck, faTextSize, faSignOut } from '@fortawesome/pro-light-svg-icons';
import { SideSlideoutComponent } from 'Shared/Components/Controls/SideSlideout/SideSlideout.component';
import { faBars, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { AppUpdateService } from 'Services/AppUpdateService';
import { BasePrimaryView } from 'Shared/BaseClasses/BasePrimaryView';
import { AppUser } from 'Models/Security/AppUser.model';


@Component({
    selector: 'iq-phone-main-menu',
    templateUrl: './PhoneMainMenu.component.html',
    styleUrls: ['./PhoneMainMenu.component.scss'],
    animations: [slideInFromLeft, slideInFromRight]
})
export class PhoneMainMenuComponent extends BasePrimaryView {

    private destroyed$: Subject<void> = new Subject();

    signedIn: Observable<boolean> = this.AuthenticationService.UserIsSignedIn;

    @ViewChild(SideSlideoutComponent) public flyout: SideSlideoutComponent;

    showNav: boolean = true;
    isOpen: boolean = false;
    personName: string;
    email: string;

    homeIcon = faHomeAlt;
    ticketIcon = faReceipt;
    signoutIcon = faSignOut;

    barsIcon = faBars;
    configIcon = faCog;
    backIcon = faArrowLeft;
    downIcon = faCaretDown;
    checkIcon = faCheck;
    textSizeIcon = faTextSize;

    TicketViewPermission: PermissionsEnum = PermissionsEnum.Ticket_View;

    constructor(authenticationService: AuthenticationService, renderer: Renderer2, public AppUpdateService: AppUpdateService) {
        super(authenticationService, renderer);

        const palette = localStorage.getItem('colorPalette');
        if (palette)
            this.SetColorPalette(palette);

        const fontSize = localStorage.getItem('fontSize');
        if (fontSize)
            this.SetFontSize(fontSize);
    }

    protected OnAppUserChanged(appUser: AppUser): void {
        //this.personID = appUser.ID && appUser.ID !== Guid.empty ? appUser.ID : null;
        this.personName = appUser.FullName;
        this.email = appUser.EmailAddress;
    }

    SignOut() {
        this.close();
        this.AuthenticationService.logout(true);
    }

    close() {
        this.flyout.detach();
    }

    OpenToggle($event) {
        this.isOpen = $event;
        if (this.isOpen === false)
            this.showNav = true;
    }
}
