export enum EntityEnum {
    None = 0,

    //  *** These *MUST* be numbered and cannot be changed once they are set!
    //  These values are referenced by the Screen/Filter configuration tables!

    Alert = 6,

    Announcement = 10,

    Audit = 30,

    Blacklist = 50,

    BroadcastMessage = 75,

    Communication = 100,

    Destination = 200,

    DeliveryRule = 220,

    Excavator = 400,

    //Only used for screen remembering on search page.  All permissions go through the main excavator entity
    ExcavatorContact = 401,
    //Only used for screen remembering on search page.  All permissions go through the main excavator entity
    ExcavatorOffice = 402,

    Log = 900,                                          //  Just needed for the Web UI list to work correctly...

    MapFeature = 980,

    Member = 1000,

    OneCallCenter = 1200,

    Person = 1400,
    PersonRole = 1401,

    Project = 1500,
    Registration = 1600,
    Report = 1650,                                      //  Just needed for the Web UI list to work correctly...
    Role = 1800,
    RoleQARules = 1805,                                 // Needed to use the base list page class
    ServiceArea = 2000,
    ServiceProvider = 2100,
    Ticket = 2400,
    TicketAffectedServiceArea = 2500,
    TicketResponse = 2600
}

//  When using the EntityTypeEnum pipe, just need to define any that have names different than
//  the name of the Enum.  It will default to the enum name if not found here.
export enum EntityEnumDescription {
    ExcavatorContact = "Excavator Contact",
    ServiceArea = "Service Area",
    ServiceProvider = "Service Provider",
}
