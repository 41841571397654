import { OLGeometryTypeEnum } from "Enums/GeometryType.enum";
import _, { DebouncedFunc } from "lodash";
import { Feature, PluggableMap } from "ol";
import { Control } from 'ol/control';
import { Options } from "ol/control/Control";
import { EventsKey } from "ol/events";
import { Geometry } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import Map from 'ol/Map';
import { unByKey } from "ol/Observable";
import RenderFeature from "ol/render/Feature";
import VectorSource from "ol/source/Vector";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { GeometryUtils } from "../GeometryUtils";

//  Example from OpenLayers: https://github.com/openlayers/openlayers/blob/master/src/ol/control/FullScreen.js
//  https://openlayers.org/en/latest/examples/custom-controls.html
//  https://stackoverflow.com/questions/25927895/openlayers-3-custom-controls-in-typescript

export class DigSiteSizeControl extends Control {

    private _DivNode: HTMLDivElement;

    private _LayersPropertyChangeEventsKey: EventsKey;
    private _LayerFeaturesChangeEvents: { [layerName: string]: EventsKey; };

    private _DebouncedSetText: DebouncedFunc<(text: string) => void>;

    constructor(opt_options?: Options) {
        const options = opt_options ? opt_options : { element: document.createElement('div') };
        super(options);

        //  Css class of this control - styled in Map.scss
        const cssClassName = 'iq-digsite-size-map-control';

        this._DivNode = document.createElement("div");
        this._DivNode.innerHTML = "";

        options.element.className = cssClassName + ' ' + MapConstants.CLASS_UNSELECTABLE + ' ' + MapConstants.CLASS_CONTROL;
        options.element.appendChild(this._DivNode);

        //  Have to set here (instead of in initializer) or get an error caused by super() not being called first
        this._DebouncedSetText = _.throttle(this.SetText, 100);     //  throttle instead of debounce or it won't update until you stop moving
    }

    setMap(map?: PluggableMap) {
        super.setMap(map);

        if (!map) {
            this._DivNode = null;
            this.RemoveEventHandlers();
            this._DebouncedSetText.cancel();
            return;     //  being destroyed
        }

        this.InitializeEventHandlers();
    }

    public static FindSelfInMap(map: Map): DigSiteSizeControl {
        return map.getControls().getArray().find(c => c instanceof DigSiteSizeControl) as DigSiteSizeControl;
    }

    private InitializeEventHandlers(): void {
        this._LayerFeaturesChangeEvents = {};

        const map = this.getMap() as Map;
        this._LayersPropertyChangeEventsKey = map.getLayers().on("propertychange", () => {
            this.MonitorLayerForChanges();
        });

        this.MonitorLayerForChanges();
    }

    private RemoveEventHandlers(): void {
        if (this._LayersPropertyChangeEventsKey) {
            unByKey(this._LayersPropertyChangeEventsKey);
            this._LayersPropertyChangeEventsKey = null;
        }

        Object.keys(this._LayerFeaturesChangeEvents)
            .forEach(key => unByKey(this._LayerFeaturesChangeEvents[key]));
        this._LayerFeaturesChangeEvents = {};
    }

    private MonitorLayerForChanges(): void {
        const map = this.getMap() as Map;

        map.getLayers().forEach(l => {
            const name = l.get("name");
            if (!this._LayerFeaturesChangeEvents[name]) {
                switch (name) {
                    //  Need change events on both DigSite and Unbuffered here.  The change event will fire on both but the DigSite is always set before
                    //  the Unbuffered so the Unbuffered will take precedence (which is what we want).
                    case MapConstants.LAYERNAME_DIGSITE:
                    case MapConstants.LAYERNAME_UNBUFFERED_DIGSITE:
                    case MapConstants.LAYERNAME_DIGSITE_EDITOR: {
                        const vectorLayer = (l as any) as VectorLayer<VectorSource<Geometry>>;
                        //console.warn("registering change event for " + name, vectorLayer.getSource().getFeatures());
                        this._LayerFeaturesChangeEvents[name] = vectorLayer.getSource().on("change", () => this.ShowSizeOfFeaturesOnLayer(name, vectorLayer));
                        this.ShowSizeOfFeaturesOnLayer(name, vectorLayer);      //  Call now because layer gets initialized and loaded before we can set up a change event on it
                        break;
                    }
                }
            }
        });
    }

    private ShowSizeOfFeaturesOnLayer(layerName: string, vectorLayer: VectorLayer<VectorSource<Geometry>>): void {
        const features = vectorLayer.getSource().getFeatures();
        //console.warn("Layer changed", name, features.length);
        if (features.length > 0)
            this.ShowSizeOfFeatureBeingDrawn(features[0], layerName);
    }

    /**
     *  Resets the displayed size based on the current values in the DigSite and/or Unbuffered DigSite layers
     */
    public ResetSizeOfFeatures(): void {
        const map = this.getMap() as Map;

        map.getLayers().forEach(l => {
            const name = l.get("name");
            switch (name) {
                //  These layers are added with DigSite first followed by Unbuffered so that is the order we should find them in
                case MapConstants.LAYERNAME_DIGSITE:
                case MapConstants.LAYERNAME_UNBUFFERED_DIGSITE: {
                    const vectorLayer = (l as any) as VectorLayer<VectorSource<Geometry>>;
                    this.ShowSizeOfFeaturesOnLayer(name, vectorLayer);
                    break;
                }
            }
        });
    }

    public ShowSizeOfFeatureBeingDrawn(feature: Feature<any> | RenderFeature, layerName: string = null): void {
        const geometry = feature.getGeometry();

        let text: string;
        switch (layerName) {
            case MapConstants.LAYERNAME_DIGSITE:
            case MapConstants.LAYERNAME_UNBUFFERED_DIGSITE:
                text = "Dig Site ";
                break;
            case MapConstants.LAYERNAME_DIGSITE_EDITOR:
                text = "Unsaved Dig Site ";
                break;
            default:
                text = "Drawing ";
        }

        //  .toLocaleString() w/maximumFractionDigits option rounds to the nearest number of specified digits
        switch (geometry.getType()) {
            case OLGeometryTypeEnum.LineString:
            case OLGeometryTypeEnum.MultiLineString: {
                const distanceFeet = GeometryUtils.LengthFt(geometry);
                //console.warn("ShowLengthFeatureBeingDrawn", geometry.getType(), distanceFeet, distanceFeet.toLocaleString(undefined, { maximumFractionDigits: 0 }));
                text += " Length: " + distanceFeet.toLocaleString(undefined, { maximumFractionDigits: 0 }) + " ft (";
                text += (distanceFeet / MapConstants.FEET_PER_MILE).toLocaleString(undefined, { maximumFractionDigits: 2 }) + " mi)";
                if (geometry.getType() === OLGeometryTypeEnum.MultiLineString)
                    text += "<br/><span style='font-size:small'>* contains multiple lines</span>";
                break;
            }
            case OLGeometryTypeEnum.Polygon:
            case OLGeometryTypeEnum.MultiPolygon:
            case OLGeometryTypeEnum.Circle: {
                //  Can get sq ft like this.  But don't think it's very useful so went with dimensions.
                //let areaSqFt = GeometryUtils.AreaSqFt(geometry);
                //areaSqFt = Math.round(areaSqFt);        //  round to nearest foot
                //text += " Area = " + areaSqFt.toLocaleString() + " sq ft / " + String(Math.round((areaSqFt / MapConstants.SQFEET_PER_SQMILE) * 100) / 100) + ' sq mi';
                const dimensionsFt = GeometryUtils.DimensionsFt(geometry);
                //console.warn("ShowLengthFeatureBeingDrawn", geometry.getType(), dimensionsFt);
                if ((dimensionsFt.width < 1) || (dimensionsFt.height < 1))
                    return;     //  empty/not valid (probably drawing it and have not drawn a proper polygon yet)
                text += " Dimensions: " + dimensionsFt.width.toLocaleString(undefined, { maximumFractionDigits: 0 }) + " ft ";
                text += "(" + (dimensionsFt.width / MapConstants.FEET_PER_MILE).toLocaleString(undefined, { maximumFractionDigits: 2 }) + " mi) ";
                text += "x " + dimensionsFt.height.toLocaleString(undefined, { maximumFractionDigits: 0 }) + " ft ";
                text += "(" + (dimensionsFt.height / MapConstants.FEET_PER_MILE).toLocaleString(undefined, { maximumFractionDigits: 2 }) + " mi)";
                if (geometry.getType() === OLGeometryTypeEnum.MultiPolygon)
                    text += "<br/><span style='font-size:small'>* contains multiple areas</span>";
                break;
            }
            default:
                //console.warn("ShowLengthFeatureBeingDrawn: unhandled geometry type", geometry.getType(), geometry);
                return;
        }

        this._DebouncedSetText(text);
    }

    private SetText(text: string): void {
        this._DivNode.innerHTML = text;
    }
}
