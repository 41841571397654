import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SideSlideoutComponent, SideMenuFlyoutContentDirective } from './SideSlideout.component';



@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SideSlideoutComponent,
        SideMenuFlyoutContentDirective
    ],
    exports: [
        SideSlideoutComponent,
        SideMenuFlyoutContentDirective
    ]

})
export class SideSlideoutModule { }
