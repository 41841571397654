<!-- button that floats at bottom-right of page.  Only visible when this component is used as a view (the anonymous ticket search) -->
<div class="iq-display-2 hide-when-printing" style="position: absolute; right: 40px; bottom: 40px; z-index: 1000">
    <button mat-fab color="primary" (click)="OnPrint()">
        <mat-icon>print</mat-icon>
    </button>
</div>

<pre>{{TicketText}}</pre>

<!-- this is included here because it's not stored in the generated text (because text is generated before we have a Ticket.ID) -->
<div *ngIf="ViewURL" style="padding:10px">
    There are {{NumAttachments}} attachment(s) which can be viewed at: {{ViewURL}}
</div>

<!-- This is copied from the Ticket ServiceAreaList component.  Would be nice to consolidate this if possible.  -->
<!-- But the contact columns are displayed differently here - because there is no "more" link on a printed report. -->
<!-- If something is not working like it does in ServiceAreaList, it probably needs to be copied over here... -->
<!--
    *** README!
    If updating anything in here from the main service area list, you *MUST* remove all of the styles
    like this from the cells: fxLayout="column" fxLayoutAlign="center start"
    Something about them is not supported by the print view.  If you leave them in and print a ticket with lots of
    service areas (that cause the list to span to another page), a bunch of the cells will have text that sits on top
    of each other and looks all jumbled up.
    Removing those styles allows the cells to be rendered correctly.
    I don't know what purpose they serve in the main list but didn't want to mess with them there at the time.
    This was related to this fresh desk ticket (which has .pdf examples of the problem): https://4iqsolutions.freshdesk.com/a/tickets/680
-->
<iq-view-list *ngIf="ServiceAreas" style="padding:10px; overflow: visible; height: unset">
    <div class="top-bar">
        <div class="legend">
            <div *ngIf="HaveManuallyAdded" class="red">* Manually Added</div>
            <div *ngIf="UsesPositiveResponse">* Responses are current as of {{Now | date: SettingsService.DateTimeFormat }}</div>
        </div>
    </div>

    <div class="iq-list-items" style="padding-bottom:0px; overflow: visible;">
        <div class="iq-list-header no-more-option" style="z-index: unset;" fxLayout>
            <ng-container *ngFor="let col of DisplayedColumns">
                <span class="font-size-header column-fixed {{col.class}} column-header" [fxFlex]="col.width">
                    {{col.name}}
                </span>
            </ng-container>
        </div>

        <iq-list-item *ngFor="let sa of ServiceAreas; trackBy: TrackBy">
            <!-- CssClass is determined ONCE (or if the state changes) - don't do it inside the html or it will be re-evaluated
                 during each change detection! Makes a big difference in performance. -->
            <!-- check on sa.ServiceAreaInfo is because a FL user got an error in here when printing in the middle of an Edit -->
            <div *ngIf="sa.ServiceAreaInfo" class="{{sa.CssClass}} font-size-body" style="text-decoration:none" fxLayout fxFlex="1 0 0">

                <ng-container *ngFor="let col of DisplayedColumns" [ngSwitch]="col.returnPropertyName">

                    <div *ngSwitchCase="'Suppressed'" class="column-fixed" [fxFlex]="col.width">

                        <span *ngIf="sa.ServiceAreaInfo.SuppressedUntilDate">*</span>
                        {{ sa.Suppressed | dynamicFormat : col.formatType : col.format }}
                    </div>

                    <div *ngSwitchCase="'ExtraordinaryCircumstances'" class="column-fixed {{col.class}} {{sa.ServiceAreaInfo.ExtraordinaryCircumstances}}"
                         [fxFlex]="col.width" [ngClass]="{'red': sa.ServiceAreaInfo.ExtraordinaryCircumstances}">
                        {{sa.ServiceAreaInfo.ExtraordinaryCircumstances | dynamicFormat : col.formatType : col.format}}
                    </div>

                    <div *ngSwitchCase="'ServiceArea'" class="column-fixed" [fxFlex]="col.width">
                        <div>
                            <span *ngIf="sa.ManuallyAdded">* </span>
                            {{sa.ServiceAreaInfo.Name}}
                        </div>
                        <div *ngIf="ShowServiceAreaCode" class="font-size-body iq-caption gray-primary-text">{{sa.ServiceAreaInfo.Code}}</div>
                        <div *ngIf="sa.ServiceAreaType.Name !== 'Full'">* {{ sa.ServiceAreaType.Name }}</div>
                    </div>

                    <div *ngSwitchCase="'UtilityTypes'" class="column-fixed" [fxFlex]="col.width">
                        {{ sa.ServiceAreaInfo.UtilityTypes }}
                    </div>

                    <div *ngSwitchCase="'PrimaryContact'" class="column-fixed" [fxFlex]="col.width">
                        <ng-container *ngIf="sa.ServiceAreaInfo.ServiceAreaType.Name !== 'Landlord'; else landlordTemplate">
                            <div>{{sa.ServiceAreaInfo.PrimaryContact | personName}}</div>
                            <div>{{sa.ServiceAreaInfo.PrimaryContact ? (sa.ServiceAreaInfo.PrimaryContact.PhoneNumber | phone) : '' }}</div>
                        </ng-container>
                        <ng-template #landlordTemplate>
                            <div>{{sa.ServiceAreaInfo.PrimaryContact | nameAddressFormat}}</div>
                            <div>{{sa.ServiceAreaInfo.PrimaryContact ? (sa.ServiceAreaInfo.PrimaryContact.PhoneNumber | phone) : '' }} {{(sa.ServiceAreaInfo.PrimaryContact && sa.ServiceAreaInfo.PrimaryContact.FaxNumber) ? (', '+(sa.ServiceAreaInfo.PrimaryContact.FaxNumber | phone) + ' (fax)') : '' }}</div>
                        </ng-template>
                    </div>

                    <div *ngSwitchCase="'AlternateContact'" class="column-fixed" [fxFlex]="col.width">
                        <ng-container *ngIf="sa.ServiceAreaInfo.AlternateContact">
                            <div>{{sa.ServiceAreaInfo.AlternateContact | personName}}</div>
                            <div>{{ sa.ServiceAreaInfo.AlternateContact.PhoneNumber | phone }}</div>
                        </ng-container>
                    </div>

                    <div *ngSwitchCase="'EmergencyContact'" class="column-fixed" [fxFlex]="col.width">
                        <ng-container *ngIf="sa.ServiceAreaInfo.EmergencyContact">
                            <div>{{sa.ServiceAreaInfo.EmergencyContact | personName}}</div>
                            <div>{{ sa.ServiceAreaInfo.EmergencyContact.PhoneNumber | phone }}</div>
                        </ng-container>
                    </div>

                    <div *ngSwitchCase="'PositiveResponse'" class="column-fixed" [fxFlex]="col.width">
                        <ng-container *ngFor="let response of sa.ServiceAreaInfo.CurrentResponses">
                            <div *ngIf="response.ResponseCode" style="padding-bottom: 5px; word-break: break-all;">
                                <div *ngIf="response.UtilityTypeName">
                                    <label class="iq-caption gray-primary-text">Utility Type:</label>
                                    <span style="font-weight: bold">{{response.UtilityTypeName}}:</span>
                                </div>
                                <div>
                                    <div>{{response.ResponseDescription}}</div>
                                    <div *ngIf="response.Respondant"><label class="iq-caption gray-primary-text">Respondant:</label>{{response.Respondant}}</div>
                                    <div *ngIf="response.Comment">{{response.Comment}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngSwitchCase="'ExcavatorComments'" class="column-fixed" fxLayout fxLayoutGap="10px" [fxFlex]="col.width">
                        <div fxFlex fxLayout="column">
                            <ng-container *ngFor="let response of sa.ServiceAreaInfo.ExcavatorComments; last as isLast">
                                <span style="white-space: pre-wrap; padding: 5px 0; word-break: break-all;">
                                    {{response.Message}}
                                </span>
                                <mat-divider style="margin-left: 25px;" *ngIf="!isLast"></mat-divider>
                            </ng-container>
                        </div>
                    </div>

                    <div *ngSwitchCase="'Discussions'" class="column-fixed" fxLayoutGap="10px" [fxFlex]="col.width">
                        <iq-ticket-response-discussion-list [ServiceArea]="sa.ServiceAreaInfo" [ReadOnly]="true"></iq-ticket-response-discussion-list>
                    </div>

                    <span *ngSwitchDefault class="column-fixed" [fxFlex]="col.width">
                        {{sa.ServiceAreaInfo[col.returnPropertyName] | dynamicFormat : col.formatType : col.format}}
                    </span>
                </ng-container>
            </div>
        </iq-list-item>
    </div>
</iq-view-list>

<div *ngIf="Disclaimer" style="padding-top:20px" [innerHTML]="Disclaimer | trustHtml"></div>
