export class PersonLinkLoginRequest {
    constructor(public OccCode: string, public PersonID: string, public CognitoAttributes: any) { }
}

export class PersonLinkLoginResponse {
    FirstName: string;
    LastName: string;
    Email: string;
    HasLogin: boolean;
}
