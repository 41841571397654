import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable({
    providedIn: 'root'
})
export class PhonePageGuardService implements CanActivate {
    constructor(
        private deviceService: DeviceDetectorService, private router: Router
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const parts = state.url.split("/");
        parts.shift();//Remove out the first one because it's always empty

        if (this.deviceService.isMobile()) {

            if (parts[0] !== "phone") {
                this.router.navigate(["./phone"]);
                return false;
            }
        }
        else {
            if (parts[0] === "phone") {
                this.router.navigate(["./home"]);
                return false;
            }
        }

        
        return true;
    }
}
