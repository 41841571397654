
export class SendTicketToExcavatorRequest {

    constructor(
        //  Can be multiple if we created multiple tickets by splitting up the dig site.
        public TicketIDList: string[],
        public ExcavatorContactID: string,
        public EmailAddress: string,
        public FaxNumber: string) { }
}
