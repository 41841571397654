import { trigger, state, style, transition, animate, query, animateChild, group } from '@angular/animations';

export const slideInFromRight = trigger('slideInFromRight', [
    state('void, exit', style({ transform: 'none' })),//, display: 'none'
    state('enter', style({ transform: 'none' })),
    transition('* => enter', [
        style({ transform: 'translateX(100%)' }),
        animate('350ms ease-in', style({ transform: 'translateX(0%)' }))
    ]),
    transition('* => void, * => exit', [
        animate('350ms ease-out', style({ transform: 'translateX(100%)' }))
    ])
]);

export const slideInFromLeft = trigger('slideInFromLeft', [
    state('void, exit', style({ transform: 'none' })),//, display: 'none'
    state('enter', style({ transform: 'none' })),
    transition('* => enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('350ms ease-in', style({ transform: 'translateX(0%)' }))
    ]),
    transition('* => void, * => exit', [
        animate('350ms ease-out', style({ transform: 'translateX(-100%)' }))
    ])
]);

export const slideInFromBottom = trigger('slideInFromBottom', [
    state('void, exit', style({ transform: 'none', display: 'none' })),
    state('enter', style({ transform: 'none', 'opacity': '1' })),
    transition('* => enter', [
        style({ transform: 'translateY(50%)' }),//Start with this style
        animate('350ms ease-in-out', style({ transform: 'translateY(0%)', 'opacity': '.5' }))
    ]),
    //Do no animation on exit.  Just go right to not showing it
    //transition('enter => void, enter => exit', [
    //    //style({ transform: 'translateY(10%)', 'max-height': '10%' }),
    //    //animate('125ms linear', style({ transform: 'translateY(10%)', 'opacity': '.5', 'max-height': '10px' }))
    //    //animate('750ms linear', style({ transform: 'translateY(10%)', 'opacity': '.5' }))
    //])
]);

export const slideInFromTop = trigger('slideInFromTop', [
    state('void, exit', style({ transform: 'none', display: 'none' })),
    state('enter', style({ transform: 'none', 'opacity': '1' })),
    transition('* => enter', [
        style({ transform: 'translateY(-50%)', display: '' }),//Start at this style
        animate('350ms ease-in-out', style({ transform: 'translateY(0%)', 'opacity': '.5' }))
    ]),
    //Do no animation on exit.  Just go right to not showing it
    //transition('enter => void, enter => exit', [
    //    //style({ transform: 'translateY(-10%)', 'max-height': '-10%' }),
    //    //animate('125ms linear', style({ transform: 'translateY(-10%)', 'opacity': '.5', 'max-height': '10px' }))
    //    //animate('750ms linear', style({ transform: 'translateY(-10%)', 'opacity': '.5' }))
    //])
]);

export const fadeInAnimation = trigger('enterTrigger', [
    state('fadeIn', style({
        opacity: '1'
    })),

    transition('void => *',
        [style({ opacity: '0' }), animate('700ms')]
    )
]);




export const PhoneAnimationTriggers = {
    DefaultAnimation: 'DefaultAnimation',
    SlideInFromLeft: 'SlideInFromLeft',
    SlideInFromRight: 'SlideInFromRight'
}

export const phoneRouteAnimations =
    trigger('phoneRouteAnimations', [
        transition('SlideInFromLeft => SlideInFromRight', [
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    bottom: '0',
                    left: '0'
                })
            ]),
            query(':enter', [
                style({ left: '100%' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('300ms ease-out', style({ right: '100%' }))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({ left: '0%' }))
                ])
            ]),
            query(':enter', animateChild()),
        ]),

        transition('SlideInFromRight => SlideInFromLeft', [
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    bottom: '0',
                    left: '0'
                })
            ]),
            query(':enter', [
                style({ right: '100%' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('300ms ease-out', style({ left: '100%' }))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({ right: '0%' }))
                ])
            ]),
            query(':enter', animateChild()),
        ]),


        //Default transition. If nothing set then the page will fade in
        transition('* => *', [
            style({ opacity: '0' }), animate('700ms')
        ]),
    ]);
