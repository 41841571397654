<div fxLayout fxLayoutAlign="center start" style="height: 100%; overflow: auto;">
    <busy-indicator [busyKey]="busyKey"></busy-indicator>
    <mat-card style="width: 500px; text-align:center;">
        <mat-card-title>Create Login</mat-card-title>
        <mat-card-content>
            <div class="api-error" *ngIf="apiError">
                Error on server.
            </div>

            <div *ngIf="loading; else doneLoading;">
                Verifying....
            </div>

            <ng-template #doneLoading>
                <div *ngIf="personLinkedToUser; else personNotLinked" fxLayout="column">
                    <div fxLayout="column">
                        <p>
                            You have already completed registration.  Please update any bookmarks you may have to go to the home page.
                        </p>
                    </div>
                    <div fxLayout fxLayoutAlign="flex-end center">
                        <button mat-button color="primary" (click)="logout(true)">Go to Login</button>
                    </div>
                </div>


                <ng-template #personNotLinked>
                    <!--We can only know the login is linked if they are logged in-->
                    <div *ngIf="loginLinkedToPerson; else linking" fxLayout="column">
                        <ng-container *ngIf="loginLinkedToID; else notCurrentPerson">
                            <div fxLayout="column">
                                <p>
                                    You have already completed registration.  Please update any bookmarks you may have to go to the home page.
                                </p>
                            </div>
                            <div fxLayout fxLayoutAlign="flex-end center">
                                <button mat-button color="primary" (click)="redirectToHome()">Go to home page</button>
                            </div>
                        </ng-container>
                        <ng-template #notCurrentPerson>
                            <div fxLayout="column">
                                <p>
                                    You are currently logged in as '{{username}}' and that login is already attached to a person for {{OccCode}}.
                                    You can't link a login to multiple people in the same center.
                                </p>
                                <p>To link to a different login please logout</p>
                            </div>
                            <div fxLayout fxLayoutAlign="flex-end center">
                                <button mat-button color="primary" (click)="logout()">Logout</button>
                            </div>
                        </ng-template>
                    </div>
                    <ng-template #linking>
                        <div *ngIf="dataNotFound; else createLogin">
                            <p style="color: red">
                                Something went wrong.  Please double check you have the correct and complete url and try again.
                            </p>
                        </div>

                        <ng-template #createLogin>
                            <div fxLayout="column" fxLayoutAlign="center center">
                                <iq-aws-cognito [StartingStep]="6" [PasswordRequirements]="passwordRequirements" [ShowBrandingImage]="false"
                                                  [FederatedLoginReturnState]="federatedLoginReturnState"
                                                style="width: 100%; overflow: auto; max-width: 375px;">

                                    <ng-template iqAwsResetPasswordTemplate>
                                        <auth-forgot-password [PasswordRequirements]="passwordRequirements"></auth-forgot-password>
                                    </ng-template>

                                    <ng-template iqAwsCreateNewUserTemplate>
                                        <auth-link-person-sign-up [PasswordRequirements]="passwordRequirements" [Model]="personInfo"></auth-link-person-sign-up>
                                    </ng-template>
                                </iq-aws-cognito>
                            </div>
                        </ng-template>
                    </ng-template>
                    
                </ng-template>
            </ng-template>
        </mat-card-content>
    </mat-card>
</div>
