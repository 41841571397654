import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SignInState } from 'iqCognito/models';
import { IqAwsCognitoService } from 'iqCognito/Services/iq-aws-cognito.service';
import { AppUser } from 'Models/Security/AppUser.model';
import { PersonLinkLoginRequest, PersonLinkLoginResponse } from 'Models/People/PersonLinkLoginRequest.model';
import { of, Subject } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { AuthenticationService, ExactixFederatedLoginState } from 'Services/AuthenticationService';
import { BusyService } from 'Services/BusyService';
import { passwordRequirements } from 'Services/CognitoAdminService';
import { CommonService } from 'Services/CommonService';
import { MainMenuService } from 'Services/MainMenuService';
import { SettingsService } from 'Services/SettingsService';

@Component({
    selector: 'auth-link-user-to-person',
    templateUrl: './LinkUserToPerson.component.html',
    styleUrls: ['./LinkUserToPerson.component.scss'],
    providers: [CommonService]
})
export class LinkUserToPersonComponent implements OnDestroy {

    federatedLoginReturnState: ExactixFederatedLoginState = { redirectBeforeFetchingUser: true, returnUrl: this.router.url };

    passwordRequirements = passwordRequirements;

    private destroyed$: Subject<void> = new Subject();

    OccCode: string;
    EntityID: string;

    apiError: boolean = false;

    //Setup observable in the service and get the key to that observable back to start and end the indicator
    busyKey: string = this.busyService.createNew();

    dataNotFound: boolean = false;
    loginLinkedToPerson: boolean = false;//If the login is linked to any person
    loginLinkedToID: boolean = false;//If the login is linked to the curent personID trying to link to
    username: string;
    personLinkedToUser: boolean = false;
    loading = true;
    personInfo: PersonLinkLoginResponse;

    private cognitoUser;

    constructor(
        private authService: AuthenticationService,
        private settingsService: SettingsService,
        private commonService: CommonService,
        private busyService: BusyService,
        private mainMenuService: MainMenuService,
        private _CognitoService: IqAwsCognitoService,
        private router: Router
    ) {
        this.mainMenuService.forceClose = true;

        this.busyService.showByKey(this.busyKey);
        this.OccCode = this.commonService.ActivatedRoute.snapshot.paramMap.get('occ');
        this.EntityID = this.commonService.ActivatedRoute.snapshot.paramMap.get('id');

        this._CognitoService.signInEventChange$.pipe(distinctUntilChanged((prev: SignInState, cur: SignInState) => prev.SignedIn === cur.SignedIn),
            mergeMap((state: SignInState) => {
                if (state.SignedIn === true) {
                    this.cognitoUser = state.User;
                    this.username = state.User.getUsername();

                    //check if the login is linked to a person in this client.  Have to check this anytime they login because they
                    //  could use an existing login and not be creating a new one.
                    return this.commonService.HttpClient.get<any>(this.settingsService.ApiBaseUrl + "/Administration/Person/IsLoginLinked/" + this.OccCode + "/" + this.EntityID)
                        .pipe(take(1), map(val => {
                            return { state: state, loginLinked: val.Linked, linkedToID: val.LinkedToID };
                        }));
                    
                }
                else {
                    return of({ state: state, loginLinked: false, linkedToID: false });
                }
            }),
            mergeMap(val => {
                this.loginLinkedToPerson = val.loginLinked;
                this.loginLinkedToID = val.linkedToID;

                if (val.loginLinked === true) {
                    return of({ state: val.state, personData: null });
                }
                else if (this.personInfo)//Don't reload this data if we have it.
                    return of({ state: val.state, personData: this.personInfo });
                else
                    return this.commonService.HttpClient.get<PersonLinkLoginResponse>(this.settingsService.ApiBaseUrl + "/Administration/Person/IsPersonLinked/" + this.OccCode + "/" + this.EntityID)
                        .pipe(take(1), map(personInfo => { return { state: val.state, personData: personInfo }; }));
            }),
            takeUntil(this.destroyed$))
            .subscribe(data => {
                this.loading = false;

                //Can't link a login to a person if the login is already linked to a person
                if (this.loginLinkedToPerson) {
                    this.busyService.hideByKey(this.busyKey);
                    return;
                }

                if (!data || !data.personData) {
                    this.busyService.hideByKey(this.busyKey);
                    this.dataNotFound = true;
                    return;
                }

                this.personInfo = data.personData;

                this.personLinkedToUser = this.personInfo ? this.personInfo.HasLogin : false;

                const isSignedIn = data.state.SignedIn;

                //Can't link a login to a person if the person already has a login
                if (this.personInfo.HasLogin) {
                    this.busyService.hideByKey(this.busyKey);
                    return;
                }

                if (isSignedIn) {
                    this.LinkPerson();
                }

                this.busyService.hideByKey(this.busyKey);
            });

    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();

        this.mainMenuService.forceClose = false;
        this.busyService.hideByKey(this.busyKey);
        this.busyService.removeByKey(this.busyKey);
    }

    private linking = false;
    private LinkPerson() {
        if (this.linking)
            return;

        this.linking = true;

        this.busyService.showByKey(this.busyKey);

        //Make sure we have the latest incase they changed anything.
        this._CognitoService.GetCurrentAuthenticatedUser().pipe(take(1)).subscribe(val => {

            //If we don't get a valid user then we probably need to log them out..
            val.getUserAttributes((err, atts) => {

                const request = new PersonLinkLoginRequest(this.OccCode, this.EntityID, atts);

                this.commonService.HttpClient.post<AppUser>(this.settingsService.ApiBaseUrl + "/Administration/Person/LinkLogin", request)
                    .subscribe(appUser => {
                        this.authService.redirectAfterUserRegistration(appUser, val, atts);
                        this.busyService.hideByKey(this.busyKey);
                    }, (err: HttpErrorResponse) => {
                        this.apiError = true;
                        this.busyService.hideByKey(this.busyKey);
                    });
            });
        });
    }

    logout(redirect = false) {
        this.loginLinkedToPerson = false;
        this.username = null;
        this.personLinkedToUser = false;
        this.loading = true;

        this.authService.logout(redirect);
    }

    redirectToHome() {
        this.authService.SetUserLoggedIn(this.cognitoUser, true);
    }
}
