import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DiscussionTypeEnum } from 'Enums/DiscussionType.enum';
import { TicketAffectedServiceAreaInfo } from 'Models/Tickets/TicketAffectedServiceAreaInfo.model';
import { TicketServiceArea } from 'Models/Tickets/TicketServiceArea.model';
import { SettingsService } from 'Services/SettingsService';
import { AddExcavationDateDialogComponent, AddExcavationDateDialogData } from '../../AddExcavationDate/AddExcavationDateDialog.component';
import { AddMarkingDelayResponseDialogComponent, AddMarkingDelayResponseDialogData } from '../../AddMarkingDelayResponse/AddMarkingDelayResponseDialog.component';

/*
 *  Shows a list of TicketResponseDiscussions for a specific TicketServiceArea.  Meant to be shown in a ticket response list.
 *  Also handles any necessary prompting for responses.
 */
@Component({
    selector: 'iq-ticket-response-discussion-list',
    templateUrl: './TicketResponseDiscussionList.component.html',
    styleUrls: ['./TicketResponseDiscussionList.component.scss']
})
export class TicketResponseDiscussionListComponent {
    private _ServiceArea: TicketAffectedServiceAreaInfo;
    @Input()
    public set ServiceArea(val: TicketAffectedServiceAreaInfo | TicketServiceArea) {
        this._ServiceArea = this.ToServiceAreaInfo(val);
    }
    public get ServiceArea(): TicketAffectedServiceAreaInfo {
        return this._ServiceArea;
    }

    /*
     *  Set this to the full list of all service areas in order to handle discussions that affect all service areas.
     */
    @Input()
    public ServiceAreaList: TicketAffectedServiceAreaInfo[] | TicketServiceArea[];

    @Input()
    public ReadOnly: boolean = false;

    /*
     *  Notifies the owner that a response to a discussion has been entered.
     *  Owner can then use the CheckForRequiredDiscussions() method to re-check for required discussions.
     */
    @Output()
    Changed: EventEmitter<void> = new EventEmitter();

    public DiscussionTypeEnum = DiscussionTypeEnum;

    constructor(private _Dialog: MatDialog, public SettingsService: SettingsService) {
    }

    private ToServiceAreaInfo(val: TicketAffectedServiceAreaInfo | TicketServiceArea): TicketAffectedServiceAreaInfo {
        //  Can't use instanceof on this - probably because we are dealing with objects created from api responses
        //  which are not actually instances of the classes.  They are just objects created from json that are typed
        //  to the class.
        if ((val as any).ServiceAreaInfo)
            return (val as any).ServiceAreaInfo;
        return val as TicketAffectedServiceAreaInfo;
    }

    /**
     * Returns true if any service areas have discussions.
     * @param serviceAreaList
     */
    public static HaveDiscussions(serviceAreaList: TicketAffectedServiceAreaInfo[]): boolean {
        if (!serviceAreaList)
            return false;

        return serviceAreaList.filter(sa => sa.Discussions).some(sa => sa.Discussions && sa.Discussions.length > 0);
    }

    /**
     * Returns DiscussionTypeEnum values of any required Discussion Types that do not have a response.
     * This is static and evaluates discussions for all service areas because some discussions affect all service
     * areas at once while others are individual.  So this allows the component owner to only evaluate this when
     * necessary and to be able to see when all requirements have been met.
     * @param serviceAreaList
     */
    public static CheckForRequiredDiscussions(serviceAreaList: TicketAffectedServiceAreaInfo[]): DiscussionTypeEnum[] {
        if (!serviceAreaList)
            return [];     //  No service areas!

        const requiredDiscussionTypes: DiscussionTypeEnum[] = [];

        serviceAreaList.forEach(sa => {
            if (sa.Discussions) {
                sa.Discussions.forEach(d => {
                    switch (d.DiscussionType) {
                        case DiscussionTypeEnum.MarkingDelayRequested:
                        case DiscussionTypeEnum.RequestActualExcavationDate:
                            //  These require the Excavator to enter a reply.  If we don't have one, notify the container so they
                            //  can display something about it or prevent leaving a dialog or whatever.
                            if (!d.Response && requiredDiscussionTypes.indexOf(d.DiscussionType) === -1)
                                requiredDiscussionTypes.push(d.DiscussionType);
                    }
                });
            }
        });

        return requiredDiscussionTypes;
    }

    public AddExcavationDate(event: Event) {
        //  Needed to prevent click from being handled by the phone view (which opens up the response history)
        event.stopPropagation();
        event.preventDefault();

        const dialogData = new AddExcavationDateDialogData();

        //  This date is entered once and multiple service areas may have requested it.  Find all of the discussions from any service area
        //  and send in to the dialog.  They will all be handled at once (we prompt once, send the date to the server, it will update all records,
        //  and the dialog will set the date in to all of these items so the UI updates on all of them)
        dialogData.TicketDiscussionList = [];
        this.ServiceAreaList.forEach(val => {
            const sa = this.ToServiceAreaInfo(val);
            if (sa.Discussions) {
                sa.Discussions.forEach(d => {
                    if (d.DiscussionType === DiscussionTypeEnum.RequestActualExcavationDate)
                        dialogData.TicketDiscussionList.push(d);
                })
            }
        });

        this._Dialog.open(AddExcavationDateDialogComponent, {
            data: dialogData,
            minWidth: '35%',
            width: '500px',//Smaller than min on large screen, so it will use min on large screen.  But larger than max on small screen, so it will use max on small screen 
            maxWidth: '80%'
        }).afterClosed().subscribe(result => {
            if (result)
                this.Changed.emit();
        });
    }

    public AddMarkingDelayResponse(event: Event) {
        //  Needed to prevent click from being handled by the phone view (which opens up the response history)
        event.stopPropagation();
        event.preventDefault();

        const dialogData = new AddMarkingDelayResponseDialogData();
        dialogData.TicketDiscussion = this._ServiceArea.Discussions.find(f => f.DiscussionType === DiscussionTypeEnum.MarkingDelayRequested);

        this._Dialog.open(AddMarkingDelayResponseDialogComponent, {
            data: dialogData,
            minWidth: '35%',
            width: '500px',//Smaller than min on large screen, so it will use min on large screen.  But larger than max on small screen, so it will use max on small screen 
            maxWidth: '80%'
        }).afterClosed().subscribe(result => {
            if (result)
                this.Changed.emit();
        });
    }
}
