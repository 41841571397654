<div *ngIf="ShowSendEmail || ShowSendFax" [formGroup]="FormGroup" autocomplete="off" class="send-copy-section">
    <div style="text-decoration:underline">
        Send a copy of the ticket<ng-container *ngIf="TicketIDList && TicketIDList.length > 1">s</ng-container>:
    </div>
    <div *ngIf="ShowSendEmail" class="send-copy-row">
        <div>
            <mat-checkbox color="primary" formControlName="SendEmailCopyToCaller" [labelPosition]="'after'" class="iq-ticket-entry-label">By email:</mat-checkbox>
            <mat-error style="font-size:small" iqValidationMessage [control]="FormGroup.controls.SendEmailCopyToCaller"></mat-error>
        </div>
        <mat-form-field style="width:25em; padding-left:2em">
            <input autocomplete="off" type="search" matInput LowerCase placeholder="Email Address" formControlName="EmailAddress" />
            <mat-error iqValidationMessage [control]="FormGroup.controls.EmailAddress"></mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="ShowSendFax" class="send-copy-row">
        <mat-checkbox color="primary" formControlName="SendFaxCopyToCaller" [labelPosition]="'after'" class="iq-ticket-entry-label">By fax:</mat-checkbox>
        <mat-form-field style="width:10em; padding-left:2em">
            <iq-phone placeholder="Fax Number" formControlName="FaxNumber" [AllowExtension]="false"></iq-phone>
            <mat-error iqValidationMessage [control]="FormGroup.controls.FaxNumber"></mat-error>
        </mat-form-field>
    </div>
</div>
