import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { ResponseConfig } from 'Models/Configuration/Response.model';
import { TicketAffectedServiceAreaInfo } from 'Models/Tickets/TicketAffectedServiceAreaInfo.model';
import { TicketResponseCreateRequest } from "Models/Tickets/TicketResponseCreateRequest.model";
import { TicketResponseItem } from 'Models/Tickets/TicketResponseItem.model';
import { TicketResponseService } from 'Pages/Tickets/Services/TicketResponse.service';
import { Observable, of } from 'rxjs';
import { finalize, map, startWith, take } from 'rxjs/operators';
import { CommonService } from 'Services/CommonService';
import { ComponentLookupRegistry } from 'Services/ComponentLookup.service';
import { EnumService } from 'Services/Enum.service';
import { EntityHasIDValidator } from 'Shared/Components/Forms/Validation/Validators/EntityHasID.validator';
import { DynamicContentDirective } from 'Shared/Directives/DynamicContent/DynamicContent.directive';
import { ICustomResponseDataComponent } from "../Custom/ICustomResponseDataComponent";

class UtilityTypeOption {
    constructor(public ID: string, public Name: string, public Response: string)
    { }
}

@Component({
    selector: 'iq-ticket-add-positive-response',
    templateUrl: './AddPositiveResponse.component.html',
    styleUrls: ['./AddPositiveResponse.component.scss'],
    providers: [CommonService]
})
export class AddPositiveResponseComponent implements OnInit {

    @Input()
    public TicketNumber: string;

    @Input()
    public TicketTypeID: string;

    //  These properties are used to limit the Service Area(s) and Utility Type to the item in a list when we are adding directly from a list item.
    //  ** Do not set these unless you want to limit to only match a row in a list!
    //  If we don't do this and the user is linked to multiple service areas, it will let him pick any service area.
    //  And if the ticket/service area has multiple utility types, the same.
    //  Which means the user may pick a service area/utility type that does not match the current list item.
    //  And then we can't correcly set the Response Code that they picked in to the list or remove the item if the response is now complete.
    @Input()
    public LimitToServiceAreaName: string;
    @Input()
    public LimitToUtilityTypeName: string;

    /**
     *  If set, limits the responses to the given TicketServiceArea record.  Otherwise, fetches the list 
     *  for the TicketNumber (or uses values in ServiceAreaInfoList if set) and allows the user to pick.
     *  Do not set this *AND* the ServiceAreaInfoList property at the same time.
     */
    @Input()
    public ServiceAreaInfo: TicketAffectedServiceAreaInfo = null;

    /**
     *  If set, contains the list of TicketAffectedServiceAreaInfo objects for the ticket.  If not set, will
     *  fetch from the server.  Can use this if the caller already has the list (and uses it to display responses - so
     *  passing it in will then have changes made to it so the display is up-to-date).
     *  Do not set this *AND* the ServiceAreaInfo property at the same time.
     */
    @Input()
    public ServiceAreaInfoList: TicketAffectedServiceAreaInfo[];

    @ViewChild('serviceAreaSelect', { read: MatSelect, static: true })
    private _ServiceAreaSelect: MatSelect;

    @ViewChild("responseInput", { read: MatAutocompleteTrigger, static: true })
    private _ResponseAutocompleteTrigger: MatAutocompleteTrigger;

    @ViewChild("commentInput", { read: MatInput, static: true })
    private _CommentInput: MatInput;

    @ViewChild(DynamicContentDirective, { static: true })
    private _CustomResponseContent: DynamicContentDirective;

    //  Check this in the parent component to see if a Save button can be enabled.
    //  Check to make sure the form is valid and that we are not in the process of saving.
    public get CanSave(): boolean {
        return this.FormGroup && this.FormGroup.valid && !this._IsSaving
            && (!this._CustomResponseComponent || this._CustomResponseComponent.IsValid());
    }

    public FormGroup: FormGroup;
    public ServiceAreaInfoFormControl: FormControl;         //  Contains TicketAffectedServiceAreaInfo object - so can show Code, Name, and current response in UI
    public UtilityTypeFormControl: FormControl;
    public ResponseFormControl: FormControl;
    public CommentFormControl: FormControl;
    public RespondantFormControl: FormControl;

    public UtilityTypesForCurrentServiceArea: UtilityTypeOption[] = null;

    private _AvailableResponses: ResponseConfig[] = null;
    public FilteredResponses: Observable<ResponseConfig[]>;

    public IsLocalUser: boolean;

    private _IsSaving: boolean;

    private _CustomResponseCode: string;
    private _CustomResponseComponent: ICustomResponseDataComponent;

    constructor(private _CommonService: CommonService, private _TicketResponseService: TicketResponseService, private _EnumService: EnumService)
    {
        this._CommonService.AuthenticationService.CurrentUserObserver().pipe(take(1)).subscribe(user => this.IsLocalUser = user.IsLocalUser);
    }

    public ngOnInit(): void {
        this._EnumService.Responses.pipe(take(1)).subscribe(items => {
            //  Filter the responses if necessary.  TicketTypeID should *ALWAYS* be set here!
            //  Response is usable if TicketTypeIDs not set/empty or contains our TicketTypeID.
            this._AvailableResponses = items
                .filter(r => !this.TicketTypeID || !r.TicketTypeIDs || (r.TicketTypeIDs.length === 0) || r.TicketTypeIDs.some(id => id === this.TicketTypeID));

            if (this.ResponseFormControl)
                this.ResponseFormControl.setValue(null);        //  To trigger the inital build of values or nothing will display the first time
        });

        this.ServiceAreaInfoFormControl = new FormControl(this.ServiceAreaInfo, [Validators.required, EntityHasIDValidator]);
        this.ServiceAreaInfoFormControl.valueChanges
            .subscribe(() => setTimeout(() => this.OnServiceAreaPicked()));

        this.UtilityTypeFormControl = new FormControl();
        this.ResponseFormControl = new FormControl(null, [Validators.required, EntityHasIDValidator]);
        this.CommentFormControl = new FormControl();
        this.RespondantFormControl = new FormControl(null, [Validators.maxLength(150)]);

        this.FormGroup = new FormGroup({
            ServiceArea: this.ServiceAreaInfoFormControl,
            UtilityType: this.UtilityTypeFormControl,
            Response: this.ResponseFormControl,
            Comment: this.CommentFormControl,
            Respondant: this.RespondantFormControl
        });

        this.InitializeServiceAreaList();

        this.ResponseFormControl.valueChanges.subscribe(response => this.OnResponsePicked(response));

        this.FilteredResponses = this.ResponseFormControl.valueChanges.pipe(startWith(null), map(val => {
            if (!val || val === "")
                return this._AvailableResponses ?? [];

            if (val.ID) {
                //  When we have a response picked, return all responses.  This makes it easier if you pick one and
                //  then need to change it - you get a list of all right away.  Which makes it work better on the phone.
                return this._AvailableResponses;
                //return [val];
            }

            return this._AvailableResponses ? this._AvailableResponses.filter(r => r.Code.startsWith(val.iqToUppercase())) : [];
        }));
    }

    /**
     * Observable returns a TicketResponseItem is the response was saved.  Otherwise returns null if nothing was saved
     */
    public Save(): Observable<TicketAffectedServiceAreaInfo> {
        if (!this.CanSave)
            return of(null);

        const request = new TicketResponseCreateRequest();
        request.TicketNumber = this.TicketNumber;
        request.ServiceAreaID = this.ServiceAreaInfoFormControl.value.ID;
        request.UtilityTypeID = this.UtilityTypeFormControl.value?.ID;
        request.ResponseID = this.ResponseFormControl.value.ID;
        request.Comment = this.CommentFormControl.value;
        request.Respondant = this.RespondantFormControl.value;
        request.Data = this._CustomResponseComponent?.GetData();

        //  This outputs the TicketAffectedServiceAreaInfo with the new response added to it.
        //  If the service area has utility types (including multiple), the responses in the CurrentResponses list will contain *ALL* responses
        //  for all utility types.  That is handled in SetNewCurrentResponse().
        this._IsSaving = true;
        return this._TicketResponseService.CreateResponse(request).pipe(
            map(response => this.SetNewCurrentResponse(response, this.ServiceAreaInfoFormControl.value.ID, this.UtilityTypeFormControl.value?.ID)),
            finalize(() => this._IsSaving = false)
        );
    }

    //  Set the given response in to the correct service area/utility type current response
    private SetNewCurrentResponse(response: TicketResponseItem, serviceAreaID: string, utilityTypeID: string): TicketAffectedServiceAreaInfo {
        const sa = this.ServiceAreaInfoList.find(sa => sa.ID === serviceAreaID);
        if (!sa || !sa.CurrentResponses)
            return null;     //  ???

        sa.SuppressedUntilDate = response.SuppressUntilDate;

        if (utilityTypeID) {
            //  Finds the responses for other utility types, adds in the response (for this utility type), and sorts by the utility type name.
            const responseList = sa.CurrentResponses.filter(r => r.UtilityTypeID !== utilityTypeID);
            responseList.push(response);
            sa.CurrentResponses = responseList.sort((a, b) => a.UtilityTypeName.toLocaleLowerCase().localeCompare(b.UtilityTypeName.toLocaleLowerCase()));
        }
        else
            sa.CurrentResponses = [response];

        const responseList = sa.CurrentResponses.filter(r => r.UtilityTypeID !== utilityTypeID);
        responseList.push(response);

        //  This will recalculate the NeedsResponse and UtilityTypeList and also reset the form values to empty
        this.InitializeForm();

        return sa;
    }

    private InitializeServiceAreaList(): void {
        if (this.ServiceAreaInfo)
            this.SetServiceAreaInfoList([this.ServiceAreaInfo]);
        else if (!this.ServiceAreaInfoList) {
            this._TicketResponseService.GetServiceAreasForAdd(this.TicketNumber).subscribe(val => {
                const serviceAreaInfoList = val.map(tsa => tsa.ServiceAreaInfo)
                    .filter(sa => !this.LimitToServiceAreaName || (this.LimitToServiceAreaName === sa.Name))
                    .sort((a, b) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()));

                if (this.LimitToUtilityTypeName)
                    serviceAreaInfoList.forEach(sa => sa.CurrentResponses = sa.CurrentResponses.filter(r => r.UtilityTypeName === this.LimitToUtilityTypeName));

                this.SetServiceAreaInfoList(serviceAreaInfoList);
            });
        }
        else
            this.InitializeForm();
    }

    private SetServiceAreaInfoList(serviceAreaInfoList: TicketAffectedServiceAreaInfo[]): void {
        this.ServiceAreaInfoList = serviceAreaInfoList;

        this.InitializeForm();
    }

    private InitializeForm(): void {
        if (this.ServiceAreaInfoList.length === 1) {
            //  Exactly 1 so auto select it
            this.ServiceAreaInfoFormControl.setValue(this.ServiceAreaInfoList[0]);
            this.ServiceAreaInfoFormControl.disable();
            //this.OnServiceAreaPicked();
        }
        else
            this.ServiceAreaInfoFormControl.setValue(null);

        this.UtilityTypeFormControl.setValue(null);
        this.ResponseFormControl.setValue(null);
        this.CommentFormControl.setValue(null);
        this.RespondantFormControl.setValue(null);
        this.FormGroup.markAsPristine();
        this.FormGroup.markAsUntouched();

        this.SetNeedsResponseOnServiceAreas();

        this._ServiceAreaSelect.focus();
    }

    //  Adds a "NeedsResponse" flag to each service area that is missing a response.
    //  CurrentResponses is an array because it can contain an item for each utility type.
    private SetNeedsResponseOnServiceAreas() {
        this.ServiceAreaInfoList.forEach(sa => {
            if (sa.CurrentResponses)
                (sa as any).NeedsResponse = sa.CurrentResponses.some(r => !r.ResponseID && r.ResponseRequired);
        });
    }

    public CompareServiceAreaInfo(sa1: TicketAffectedServiceAreaInfo, sa2: TicketAffectedServiceAreaInfo): boolean {
        if (sa1 && sa2)
            return sa1.ID === sa2.ID;

        return !sa1 && !sa2;
    }

    private OnServiceAreaPicked(): void {
        const selectedServiceArea = this.ServiceAreaInfoFormControl.value as TicketAffectedServiceAreaInfo;
        if (selectedServiceArea && selectedServiceArea.CurrentResponses) {
            //  Not sure why, but doing a .sort() directly on this.SelectedServiceArea.CurrentResponses was causing Expression Changed errors if:
            //  1) Service Area uses utility types
            //  2) Has a response entered on one of them already (which one may be important - first or second)
            //  3) Open this dialog to add another response.
            //  The error happens back in the ServiceAreaList.component.html on the line that has this condition:
            //      *ngIf="sa.ServiceAreaInfo.CurrentResponses && sa.ServiceAreaInfo.CurrentResponses.length > 0 && sa.ServiceAreaInfo.CurrentResponses[0].ResponseID"
            //  Changing to sort on the .map() result fixes it.  Something about that sort must be changing the .CurrentResponses instance
            //  (even though it's not supposed to!).
            this.UtilityTypesForCurrentServiceArea = selectedServiceArea.CurrentResponses
                .filter(r => r.UtilityTypeID)
                .map(r => {
                    const responseText = r.ResponseID ? (r.ResponseCode + " - " + r.ResponseDescription) : null;
                    return new UtilityTypeOption(r.UtilityTypeID, r.UtilityTypeName, responseText);
                })
                .sort((a, b) => a.Name.localeCompare(b.Name));
            if (this.UtilityTypesForCurrentServiceArea.length > 0) {
                this.UtilityTypeFormControl.setValidators(Validators.required);

                if (this.UtilityTypesForCurrentServiceArea.length === 1)
                    this.UtilityTypeFormControl.setValue(this.UtilityTypesForCurrentServiceArea[0]);

                this.UtilityTypeFormControl.updateValueAndValidity();
                return;
            }
        }

        this.UtilityTypesForCurrentServiceArea = null;
        this.UtilityTypeFormControl.setValue(null);
        this.UtilityTypeFormControl.clearValidators();
        this.UtilityTypeFormControl.updateValueAndValidity();
    }

    public ResponseDisplayFn(response: ResponseConfig): string | undefined {
        return response?.Code;
    }

    //  Used to force the panel to display when clicked.  Otherwise, if the input already has focus, it's not shown automatically!
    public ShowPanel(): void {
        this._ResponseAutocompleteTrigger.openPanel();
    }

    private OnResponsePicked(response: ResponseConfig): void {
        if (!response?.ID)
            return;     //  String value - user is typing so ignore this

        if (response.RequireComments)
            this.CommentFormControl.setValidators([Validators.required]);
        else
            this.CommentFormControl.clearValidators();
        this.CommentFormControl.updateValueAndValidity();

        if (response.Code === this._CustomResponseCode)
            return;     //  Already set

        //  Check for a custom component that should be created and injected into the form
        const componentKey = this._CommonService.SettingsService.CurrentOneCallCenterCode + "-Response-Code-" + response.Code;
        const customComponentClassRef = ComponentLookupRegistry.get(componentKey);
        if (customComponentClassRef) {
            this._CustomResponseComponent = this._CustomResponseContent.LoadComponent(customComponentClassRef);
            this._CustomResponseCode = response.Code;
            return;
        }

        if (this._CustomResponseComponent) {
            this._CustomResponseContent.ClearComponent();
            this._CustomResponseCode = null;
            this._CustomResponseComponent = null;
            this._CommentInput.focus();
        }
    }
}
