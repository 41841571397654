import { NgModule } from '@angular/core';
import { ServiceAreaResponsesDialogComponent } from 'Pages/Tickets/Responses/Dialogs/ServiceAreaResponsesDialog/ServiceAreaResponsesDialog.component';
import { TicketResponseHistoryListComponent } from 'Pages/Tickets/Responses/Views/TicketResponseHistoryList/TicketResponseHistoryList.component';
import { TicketResponseServiceAreaListComponent } from 'Pages/Tickets/Responses/Views/TicketServiceAreaList/TicketResponseServiceAreaList.component';
import { TicketActionsService } from 'Pages/Tickets/Services/TicketActions.service';
import { CommonService } from 'Services/CommonService';
import { GlobalSharedModule } from 'Shared/Modules/GlobalShared.module';
import { AddExcavationDateDialogComponent } from '../AddExcavationDate/AddExcavationDateDialog.component';
import { AddExcavatorCommentDialogComponent } from '../AddExcavatorComment/AddExcavatorCommentDialog.component';
import { AddMarkingDelayResponseDialogComponent } from '../AddMarkingDelayResponse/AddMarkingDelayResponseDialog.component';
import { AddPositiveResponseDialog } from "../AddPositiveResponse/Dialog/AddPositiveResponseDialog.component";
import { TicketResponseServiceAreaListDialog } from '../Dialogs/TicketResponseServiceAreaListDialog/TicketResponseServiceAreaListDialog.component';
import { TicketWorkCompleteComponent } from "../WorkComplete/TicketWorkComplete.component";
import { TicketResponseSharedModule } from "./TicketResponseShared.module";

@NgModule({
    imports: [
        GlobalSharedModule,
        TicketResponseSharedModule
    ],
    declarations: [
        TicketResponseServiceAreaListComponent,
        TicketResponseServiceAreaListDialog, ServiceAreaResponsesDialogComponent,
        AddExcavationDateDialogComponent, AddMarkingDelayResponseDialogComponent, AddExcavatorCommentDialogComponent,
        AddPositiveResponseDialog, TicketResponseHistoryListComponent,
        TicketWorkCompleteComponent
    ],
    exports: [
        TicketResponseServiceAreaListDialog, TicketResponseHistoryListComponent, ServiceAreaResponsesDialogComponent,
        TicketWorkCompleteComponent,
        TicketResponseSharedModule
    ],
    providers: [
        CommonService,
        TicketActionsService        //  Needed for viewing responses from inside AZ Political Sign details page!
    ]
})
export class TicketResponseModule { }
