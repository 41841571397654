import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PhoneMainMenuComponent } from './PhoneMainMenu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { UtilsModule } from 'Shared/Modules/Utils.module';
import { RouterModule } from '@angular/router';
import { SideSlideoutModule } from 'Shared/Components/Controls/SideSlideout/SideSlideout.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PhoneButtonsModule } from 'Shared/PhoneViewComponents/Components/Buttons/PhoneButtons.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FontAwesomeModule,
        MatButtonModule,
        MatListModule,
        MatDividerModule,
        MatMenuModule,
        MatToolbarModule,
        MatGridListModule,
        MatIconModule,
        UtilsModule,
        SideSlideoutModule,
        PhoneButtonsModule
    ],
    declarations: [
        PhoneMainMenuComponent
    ],
    exports: [
        PhoneMainMenuComponent
    ]

})
export class PhoneMainMenuModule { }
