import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TicketDiscussionChildResponse } from 'Models/Tickets/TicketDiscussionChildResponse.model';
import { BaseAddDialogDataModel } from 'Shared/BaseClasses/BaseAddDialogDataModel';
import { FormControl, Validators } from '@angular/forms';
import { TicketDiscussionService } from '../../Services/TicketDiscussion.service';

export class AddMarkingDelayResponseDialogData extends BaseAddDialogDataModel {
    TicketDiscussion: TicketDiscussionChildResponse;
}

@Component({
    templateUrl: './AddMarkingDelayResponseDialog.component.html',
    styleUrls: ['./AddMarkingDelayResponseDialog.component.scss']
})
export class AddMarkingDelayResponseDialogComponent {
    public ResponseFormControl: FormControl;

    public IsValid: boolean = false;
    public IsSaving: boolean = false;

    constructor(private _DialogRef: MatDialogRef<AddMarkingDelayResponseDialogComponent>, @Inject(MAT_DIALOG_DATA) private _Data: AddMarkingDelayResponseDialogData,
        private _DiscussionService: TicketDiscussionService) {

        this.ResponseFormControl = new FormControl(null, [Validators.required]);
    }

    public OnSave(): void {
        this.IsSaving = true;

        this._DiscussionService.SavePropertyAsync(this._Data.TicketDiscussion.ID, "Response", this.ResponseFormControl.value, null, false)
            .subscribe(val => {
                this._Data.TicketDiscussion.Response = this.ResponseFormControl.value;
                this._DialogRef.close(true);
            }, err => {
                this.IsSaving = false;
            });
    }
}
