
export enum SearchFilterOperatorEnum {
    //  If multiple values given, evaluates as "is one of" - i.e. (prop = val1) or (prop = val2) or (prop = val3) etc...
    Contains = 0,               //  Only "contains" for strings, other types will automatically do Equals
    Equals = 1,
    NotEqual = 2,
    IsNotNull = 3,
    IsNull = 4,
    StartsWith = 5,

    GreaterThan = 10,
    GreaterThanOrEqual = 11,

    LessThan = 20,
    LessThanOrEqual = 21,

    // Evaluated as value1 <= value < value2
    Between = 30,

    Yesterday = 40,
    PastDaysFromToday = 41,     //  Past: Days before (and including) today
    PastHoursFromNow = 42,      //  Past: Hours before current time
    LastWeek = 43,              //  Last Week (last Sunday to last Saturday)
    LastMonth = 44,
    Tomorrow = 45,              //  Future
    DaysFromToday = 46,         //  Future: Days after (and including) today
    HoursFromNow = 47,          //  Future: Hours after current time

    BusinessDaysBeforeAndAfterToday = 48,

    CurrentUser = 55,

    GeometryIntersects = 60,    //  Geometry filter

    IsOneOf = 70,               //  A single FilterValue can contain an enumerable list of values (used to roll up  multiple values in to a single filter)

    //  These custom filter operators are used with a custom filter (defined in the repository MapSearchFilterByProperty method).
    //  Allows us to write a filter function without needing to do some kind of standard operator against a property name.
    CustomArray = 100,          //  Send the search value directly to the function as an array
    CustomOr = 101,             //  Each value in the search value list generates an expression.  Each expression is or'd together.
    CustomAnd = 102,            //  Each value in the search value list generates an expression.  Each expression is and'd together.
    CustomCondition = 103       //  A custom built condition that has no parameters
}
