import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { GeocodeResponse } from '@iqModels/Maps/GeocodeResponse.model';
import { TicketAncillary } from '@iqModels/Tickets/TicketAncillary.model';
import { TicketServiceArea } from '@iqModels/Tickets/TicketServiceArea.model';
import { TicketSiteContact } from '@iqModels/Tickets/TicketSiteContact.model';
import { AdditionalContactTypeEnum } from 'Enums/AdditionalContactType.enum';
import { DigsiteEnteredTypeEnum } from 'Enums/DigsiteEnteredType.enum';
import { DigSiteIntersectionItemTypeEnum } from 'Enums/DigSiteIntersectionItemType.enum';
import { DigSiteOffsetItemTypeEnum } from 'Enums/DigSiteOffsetItemType.enum';
import { DigSiteStreetItemTypeEnum } from 'Enums/DigSiteStreetItemType.enum';
import { EntryFieldValidationErrorSourceEnum } from "Enums/EntryFieldValidationErrorSource.enum";
import { ExcavatorSaveActionEnum } from 'Enums/ExcavatorSaveAction.enum';
import { FieldUIControlTypeEnum } from 'Enums/FieldUIControlType.enum';
import { GeocodeTypeEnum } from 'Enums/GeocodeType.enum';
import { SiteContactSaveActionEnum } from 'Enums/SiteContactSaveAction.enum';
import { TicketAttachmentTypeEnum } from 'Enums/TicketAttachmentType.enum';
import { TicketSiteContactTypeEnum } from 'Enums/TicketSiteContact.enum';
import * as _ from 'lodash';
import { DigSite } from 'Models/DigSites/DigSite.model';
import { DigSiteIntersection } from 'Models/DigSites/DigSiteIntersection.model';
import { DigSiteOffset } from 'Models/DigSites/DigSiteOffset.model';
import { DigSiteStreet } from 'Models/DigSites/DigSiteStreet.model';
import { EntryFieldConfigurationResponse } from "Models/EntryFields/EntryFieldConfigurationResponse.model";
import { EntryFieldDropdownItem } from 'Models/EntryFields/EntryFieldDropdownItem.model';
import { LocationValidationError } from 'Models/Maps/LocationValidationError.model';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketAttachment } from 'Models/Tickets/TicketAttachment.model';
import { TicketDamage } from 'Models/Tickets/TicketDamage.model';
import { TicketDynamicField } from 'Models/Tickets/TicketDynamicField.model';
import { TicketEntryConfigurationResponse } from 'Models/Tickets/TicketEntryConfigurationResponse.model';
import { TicketExcavator } from 'Models/Tickets/TicketExcavator.model';
import { TicketNearStreet } from 'Models/Tickets/TicketNearStreet.model';
import { VerifyTicketBeforeSaveResponse } from 'Models/Tickets/VerifyTicketBeforeSaveResponse.model';
import { IndividualConfig } from 'ngx-toastr';
import { TicketEntryOptionsService } from 'Pages/Tickets/Services/TicketEntryOptions.service';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { BehaviorSubject, merge, Observable, Subject } from "rxjs";
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GeometryUtils } from 'Shared/Components/Maps/GeometryUtils';
import { EntryFormControl } from 'Shared/EntryFields/Forms/EntryFormControl';
import { EntryFormGroupBase } from 'Shared/EntryFields/Forms/EntryFormGroupBase';
import { SecondaryRequiredWithPrimaryValidator } from '../../Validators/SecondaryRequiredWithPrimary.validator';

/**
  * Contains the full Ticket entry FormGroup with all possible FormControls used for ticket entry.
  * Override this class to configure One Call Center specific FormControls.
  */
export class TicketEntryFormGroup extends EntryFormGroupBase {

    //  We need to move *ALL* data that is related to the ticket being viewed or edited out of TicketService and in to this class. TicketService should
    //  not being storing any state information at all.  It should only provide stateless services.
    //  The only exception may be for data related to the ticket list search where we need it to be shared between the list
    //  and details pages so that we can view the next/prev ticket.
    //  Otherwise, everything should be stored in TicketEntryFormGroup so that it is all self contained there and so that we
    //  don't have DI related issues with getting the wrong instance if there are multiple (which happens if we are viewing a ticket
    //  and then start a dialog-based ticket edit).
    //  Or maybe we just create another class to hold this stuff and track that instance inside TicketEntryFormGroup to try to
    //  make it more manageable (so we don't have EVERYTHING in TicketEntryFormGroup).
    //  But for the moment at least, TicketEntryFormGroup is exposing this property so that form controls get it from the correct instance.
    public get EditingTicket(): BehaviorSubject<boolean> { return this.IsEditing; }     //  Moved to base but need this because there are tons of references to it...

    public readonly TicketEntryOptionsService: TicketEntryOptionsService;

    public CreateAnother: boolean;

    public FormCode: string;

    //  TicketService is public to also help address the issue described above.  So now anywhere the TicketEntryFormGroup is set,
    //  we will also have the correct instance of the TicketService.  Eventually, we change this to protected when/if we
    //  stop storing data in TicketService.
    constructor(ticket: Ticket, public TicketService: TicketService) {
        super(TicketEntryFormGroup.CreateFormControls(ticket, TicketService));

        //  See notes above
        this.IsEditing = TicketService.EditingTicket;
        this.TicketEntryOptionsService = TicketService.TicketEntryOptionsService;
        this.CreateAnother = TicketService.CreateAnother;
        this.FormCode = TicketService.TicketConfiguration.Form.Code;

        this.SetupFormForTicket();
    }

    //  This will destroy every single FormControl/FormGroup/FormArray in the ticket entry form.
    //  Which should not be necessary and will definitely cause issues when saving the ticket.
    //  It's a last resort or a tool for helping to narrow down memory leaks.
    /*
    public Destroy(): void {
        this.ClearControl(this);
        this.RemoveAllControlsInFormGroup(this);
        console.warn("Removed all controls from EntryForm", this);
    }
    private RemoveAllControlsInFormGroup(formGroup: FormGroup): void {
        for (const [key, value] of Object.entries(formGroup.controls)) {
            //console.warn("Destroying form", key, value);
            this.ClearControl(value);

            if (value instanceof FormGroup) {
                this.RemoveAllControlsInFormGroup(value as FormGroup);
                console.warn("Removed controls from FormGroup", key);
            } else if (value instanceof FormArray) {
                this.RemoveAllControlsInFormArray(value as FormArray);
                console.warn("Removed controls from FormArray", key);
            }

            formGroup.removeControl(key);
        }
    }
    private RemoveAllControlsInFormArray(formArray: FormArray): void {
        for (let i = 0; i < formArray.length; i++) {
            this.RemoveAllControlsInFormGroup(formArray.at(i) as FormGroup);
            formArray.removeAt(i);
        }
    }
    private ClearControl(control: AbstractControl): void {
        control.clearValidators();
        control.clearAsyncValidators();
    }
    */

    public CanModifyDigSite(): boolean {
        return this.EditingTicket.value;
    }

    /**
     * Creates all of the default/standard FormControls for Ticket Entry
     * @param ticket
     * @param ticketService
     */
    private static CreateFormControls(ticket: Ticket, ticketService: TicketService): { [key: string]: any; } {

        //  *** IMPORTANT:
        //  controlsConfig must be specified in the exact same stucture as the Ticket model.
        //  This allows us to do this.TicketEntryForm.value to get the Ticket model that was entered.
        //  And it means that referencing the items/properties inside it will match the Ticket model
        //  so we can get the form controls using the same structure as the model.

        const ticketConfiguration = ticketService.TicketConfiguration;
        const configFields = ticketConfiguration.Fields;

        const usesCountyInLocations = ticketService.SettingsService.UsesCountyInLocations;

        //  ***** ALL properties in the Ticket Model must be accounted for here in order for
        //  this.TicketEntryForm.value to result in a full Ticket model!
        const controlsConfig = {
            ID: new EntryFormControl(configFields['ID'], ticket.ID),
            OneCallCenterID: new EntryFormControl(configFields['OneCallCenterID'], ticket.OneCallCenterID),
            TicketNumber: new EntryFormControl(configFields['TicketNumber'], ticket.TicketNumber),
            Version: new EntryFormControl(configFields['Version'], ticket.Version),
            HideTicketNumber: new EntryFormControl(configFields['HideTicketNumber'], ticket.HideTicketNumber),      //  Not entered
            TicketLinkID: new EntryFormControl(configFields['TicketLinkID'], ticket.TicketLinkID),
            ParentTicketID: new EntryFormControl(configFields['ParentTicketID'], ticket.ParentTicketID),
            ParentTicketNumber: new EntryFormControl(configFields['ParentTicketNumber'], ticket.ParentTicketNumber),
            ChildTicketNumber: new EntryFormControl(configFields['ChildTicketNumber'], ticket.ChildTicketNumber),
            CopyOfTicketNumber: new EntryFormControl(configFields['CopyOfTicketNumber'], ticket.CopyOfTicketNumber),
            ReferenceTicketNumber: new EntryFormControl(configFields['ReferenceTicketNumber'], ticket.ReferenceTicketNumber),
            ActualGeocodeType: new EntryFormControl(configFields['ActualGeocodeType'], ticket.ActualGeocodeType),
            IsCanceled: new EntryFormControl(configFields['IsCanceled'], ticket.IsCanceled),
            IsDamage: new EntryFormControl(configFields['IsDamage'], ticket.IsDamage),
            IsMeetRequested: new EntryFormControl(configFields['IsMeetRequested'], ticket.IsMeetRequested),
            TicketFunctionID : new EntryFormControl(configFields['TicketFunctionID'], ticket.TicketFunctionID),
            TicketTypeID: new EntryFormControl(configFields['TicketTypeID'], ticket.TicketTypeID),
            LocateTypeID: new EntryFormControl(configFields['LocateTypeID'], ticket.LocateTypeID),
            AgentPersonID: new EntryFormControl(configFields['AgentPersonID'], ticket.AgentPersonID),
            AgentRoleID: new EntryFormControl(configFields['AgentRoleID'], ticket.AgentRoleID),
            AgentPersonName: new EntryFormControl(configFields['AgentPersonName'], ticket.AgentPersonName),
            CreateSource: new EntryFormControl(configFields['CreateSource'], ticket.CreateSource),
            FormID: new EntryFormControl(configFields['FormID'], ticket.FormID),
            TakenStartDate: new EntryFormControl(configFields['TakenStartDate'], ticket.TakenStartDate),
            TakenEndDate: new EntryFormControl(configFields['TakenEndDate'], ticket.TakenEndDate),
            DateCalcSeedDate: new EntryFormControl(configFields['DateCalcSeedDate'], ticket.DateCalcSeedDate),
            Status: new EntryFormControl(configFields['Status'], ticket.Status),

            WorkStartDate: new EntryFormControl(configFields['WorkStartDate'], ticket.WorkStartDate),
            PresentedWorkStartDate: new EntryFormControl(configFields['PresentedWorkStartDate'], ticket.PresentedWorkStartDate),
            ExpiresDate: new EntryFormControl(configFields['ExpiresDate'], ticket.ExpiresDate),
            ResponseDueDate: new EntryFormControl(configFields['ResponseDueDate'], ticket.ResponseDueDate),

            UserConfirmation: this.CreateUserConfirmationFormControl(),

            //  Child entities/tables
            Ancillary: this.CreateAncillaryFormGroup(configFields, ticket.Ancillary),
            Damage: this.CreateDamageFormGroup(configFields, ticket.Damage),
            Excavator: this.CreateExcavatorFormGroup(configFields, ticket.Excavator),
            DigSite: this.CreateDigSiteFormGroup(configFields, ticket.DigSite, usesCountyInLocations),
            NearStreets: this.CreateNearStreetsFormArray(ticketConfiguration, ticket),
            DynamicFields: this.CreateDynamicFieldsFormGroup(ticketConfiguration, ticket),
            DynamicData: this.CreateDynamicDataFormGroup(ticketConfiguration, ticket),
            SiteContacts: this.CreateSiteContactsFormArray(ticketConfiguration, ticket),
            Attachments: this.CreateAttachmentsFormArray(ticketConfiguration, ticket)
        };

        //console.warn("TicketEntryFormGroup = ", controlsConfig);
        return controlsConfig;
    }

    /**
     *  Made up/UI only control used where we need the user to confirm the form values before being allowed to save the ticket.
     *  By default, this is not required.  The specific One Call form must change this to be required if necessary.
     *  That will then prevent saving until it is answered (since it's a required field, it will be validated like all other fields).
     * */
    private static CreateUserConfirmationFormControl(): EntryFormControl {
        const ticketFieldConfig = new EntryFieldConfigurationResponse();
        ticketFieldConfig.PropertyName = "UserConfirmation";
        ticketFieldConfig.UIControlType = FieldUIControlTypeEnum.Dropdown;
        ticketFieldConfig.Required = false;
        ticketFieldConfig.DropdownItems = [ new EntryFieldDropdownItem("Yes", true), new EntryFieldDropdownItem("No", false) ];

        return new EntryFormControl(ticketFieldConfig, null);
    }

    /**
     * Creates a new FormControl that is a checkbox.  The checkbox is used as a way to disable the linked control when the
     * checkbox is checked.  i.e. It's a "same as" or "unknown" checkbox.
     * Event handler must be handled separately since that usually involves extra logic.
     * @param linkedToFormControl
     * @param isSame
     */
    public CreateLinkedCheckboxFormControl(linkedToFormControl: EntryFormControl, isChecked: boolean): EntryFormControl {
        this.DisableControl(linkedToFormControl, isChecked);

        const config = new EntryFieldConfigurationResponse();
        config.UIControlType = FieldUIControlTypeEnum.Checkbox;
        config.ReadOnly = linkedToFormControl.FieldConfiguration.ReadOnly;
        return new EntryFormControl(config, isChecked);
    }

    private static CreateAncillaryFormGroup(configFields: { [key: string]: EntryFieldConfigurationResponse; }, ancillary: TicketAncillary): FormGroup {
        return new FormGroup({

            LegalDate: new EntryFormControl(configFields['Ancillary.LegalDate'], ancillary && ancillary.LegalDate || null),
            RestakeDate: new EntryFormControl(configFields['Ancillary.RestakeDate'], ancillary && ancillary.RestakeDate || null),
            MeetingDate: new EntryFormControl(configFields['Ancillary.MeetingDate'], ancillary && ancillary.MeetingDate || null),
            WorkCompletedDate: new EntryFormControl(configFields['Ancillary.WorkCompletedDate'], ancillary && ancillary.WorkCompletedDate || null),
            NegotiatedWorkStartDate: new EntryFormControl(configFields['Ancillary.NegotiatedWorkStartDate'], ancillary && ancillary.NegotiatedWorkStartDate || null),

            HoursNotice: new EntryFormControl(configFields['Ancillary.HoursNotice'], ancillary && ancillary.HoursNotice || 0),
            NumNotices: new EntryFormControl(configFields['Ancillary.NumNotices'], ancillary && ancillary.NumNotices || 0),
            MeetingDurationMinutes: new EntryFormControl(configFields['Ancillary.MeetingDurationMinutes'], ancillary && ancillary.MeetingDurationMinutes || 0),

            DurationAmount: new EntryFormControl(configFields['Ancillary.DurationAmount'], ancillary ? ancillary.DurationAmount : null),
            DurationPeriod: new EntryFormControl(configFields['Ancillary.DurationPeriod'], ancillary ? ancillary.DurationPeriod : null),
            DurationText: new EntryFormControl(configFields['Ancillary.DurationText'], ancillary && ancillary.DurationText || null),

            WorkDoneFor: new EntryFormControl(configFields['Ancillary.WorkDoneFor'], ancillary && ancillary.WorkDoneFor || null),
            WorkDoneBy: new EntryFormControl(configFields['Ancillary.WorkDoneBy'], ancillary && ancillary.WorkDoneBy || null),
            WorkType: new EntryFormControl(configFields['Ancillary.WorkType'], ancillary && ancillary.WorkType || null),
            DoingWorkYourself: new EntryFormControl(configFields['Ancillary.DoingWorkYourself'], ancillary ? ancillary.DoingWorkYourself : null), //  bool: don't translate false to null!

            MeansOfExcavation: new EntryFormControl(configFields['Ancillary.MeansOfExcavation'], ancillary && ancillary.MeansOfExcavation || null),

            Explosives: new EntryFormControl(configFields['Ancillary.Explosives'], ancillary ? ancillary.Explosives : null),                      //  bool: don't translate false to null!

            PermitNeeded: new EntryFormControl(configFields['Ancillary.PermitNeeded'], ancillary && ancillary.PermitNeeded || null),
            PermitNumber: new EntryFormControl(configFields['Ancillary.PermitNumber'], ancillary && ancillary.PermitNumber || null),

            WhiteLined: new EntryFormControl(configFields['Ancillary.WhiteLined'], ancillary && ancillary.WhiteLined || null),

            JobNumber: new EntryFormControl(configFields['Ancillary.JobNumber'], ancillary && ancillary.JobNumber || null),

            Subdivision: new EntryFormControl(configFields['Ancillary.Subdivision'], ancillary && ancillary.Subdivision || null),
            Lot: new EntryFormControl(configFields['Ancillary.Lot'], ancillary && ancillary.Lot || null),
            Comments: new EntryFormControl(configFields['Ancillary.Comments'], ancillary && ancillary.Comments || null),
            LocationInstructions: new EntryFormControl(configFields['Ancillary.LocationInstructions'], ancillary && ancillary.LocationInstructions || null),
            Remarks: new EntryFormControl(configFields['Ancillary.Remarks'], ancillary && ancillary.Remarks || null),
            WorkDescription: new EntryFormControl(configFields['Ancillary.WorkDescription'], ancillary && ancillary.WorkDescription || null)

            //TicketText: new EntryFormControl(configFields['Ancillary.TicketText'], ancillary && ancillary.TicketText || null),
        });
    }

    private static CreateDamageFormGroup(configFields: { [key: string]: EntryFieldConfigurationResponse; }, damage: TicketDamage): FormGroup {
        return new FormGroup({
            UtilityCompanyName: new EntryFormControl(configFields['Damage.UtilityCompanyName'], damage && damage.UtilityCompanyName || null),
            MemberID: new EntryFormControl(configFields['Damage.MemberID'], damage && damage.MemberID || null),
            UtilityTypes: new EntryFormControl(configFields['Damage.UtilityTypes'], damage && damage.UtilityTypes || null),
            HearOrSmellGas: new EntryFormControl(configFields['Damage.HearOrSmellGas'], damage && damage.HearOrSmellGas || null),
            Called911: new EntryFormControl(configFields['Damage.Called911'], damage && damage.Called911 || null),
            CrewOnSite: new EntryFormControl(configFields['Damage.CrewOnSite'], damage && damage.CrewOnSite || null),
            NotifiedUtility: new EntryFormControl(configFields['Damage.NotifiedUtility'], damage && damage.NotifiedUtility || null),
            PhoneNumberCut: new EntryFormControl(configFields['Damage.PhoneNumberCut'], damage && damage.PhoneNumberCut || null),
            PreviousTicketNumber: new EntryFormControl(configFields['Damage.PreviousTicketNumber'], damage && damage.PreviousTicketNumber || null),
            LocationDescription: new EntryFormControl(configFields['Damage.LocationDescription'], damage && damage.LocationDescription || null),
            FacilityDescription: new EntryFormControl(configFields['Damage.FacilityDescription'], damage && damage.FacilityDescription || null),
            Comments: new EntryFormControl(configFields['Damage.Comments'], damage && damage.Comments || null)
        });
    }

    private static CreateExcavatorFormGroup(configFields: { [key: string]: EntryFieldConfigurationResponse; }, excavator: TicketExcavator): FormGroup {
        //  Additional phones/emails for the main contact
        const contactAdditionalPhone1Type = new EntryFormControl(configFields['Excavator.ContactAdditionalPhone1Type'], excavator ? excavator.ContactAdditionalPhone1Type : null);
        const contactAdditionalPhone1Value = new EntryFormControl(configFields['Excavator.ContactAdditionalPhone1Value'], excavator && excavator.ContactAdditionalPhone1Value || null);
        const contactAdditionalPhone2Type = new EntryFormControl(configFields['Excavator.ContactAdditionalPhone2Type'], excavator ? excavator.ContactAdditionalPhone2Type : null);
        const contactAdditionalPhone2Value = new EntryFormControl(configFields['Excavator.ContactAdditionalPhone2Value'], excavator && excavator.ContactAdditionalPhone2Value || null);

        //  Phones/emails for the additional contact (i.e. a field contact)
        const contactAdditionalContactType1 = new EntryFormControl(configFields['Excavator.ContactAdditionalContactType1'], excavator ? excavator.ContactAdditionalContactType1 : null);
        const contactAdditionalContactValue1 = new EntryFormControl(configFields['Excavator.ContactAdditionalContactValue1'], excavator && excavator.ContactAdditionalContactValue1 || null);
        const contactAdditionalContactType2 = new EntryFormControl(configFields['Excavator.ContactAdditionalContactType2'], excavator ? excavator.ContactAdditionalContactType2 : null);
        const contactAdditionalContactValue2 = new EntryFormControl(configFields['Excavator.ContactAdditionalContactValue2'], excavator && excavator.ContactAdditionalContactValue2 || null);

        //  Do the initial configurations for all of these phone/type fields.  A subscription will be created via RegisterFieldChangeHandlers()
        //  if we are editing (done from there so that the subscriptions will end properly when we are being destroyed).
        this.ConfigureAdditionalContactValue(contactAdditionalPhone1Type.value, contactAdditionalPhone1Value, true);
        this.ConfigureAdditionalContactValue(contactAdditionalPhone2Type.value, contactAdditionalPhone2Value, true);
        this.ConfigureAdditionalContactValue(contactAdditionalContactType1.value, contactAdditionalContactValue1, true);
        this.ConfigureAdditionalContactValue(contactAdditionalContactType2.value, contactAdditionalContactValue2, true);

        return new FormGroup({
            ExcavatorCompanyID: new EntryFormControl(configFields['Excavator.ExcavatorCompanyID'], excavator && excavator.ExcavatorCompanyID || null),
            ExcavatorOfficeID: new EntryFormControl(configFields['Excavator.ExcavatorOfficeID'], excavator && excavator.ExcavatorOfficeID || null),
            ExcavatorContactID: new EntryFormControl(configFields['Excavator.ExcavatorContactID'], excavator && excavator.ExcavatorContactID || null),

            //  Company fields
            CompanyID: new EntryFormControl(configFields['Excavator.CompanyID'], excavator && excavator.CompanyID || null),
            CompanyName: new EntryFormControl(configFields['Excavator.CompanyName'], excavator && excavator.CompanyName || null),
            CompanyTypeID: new EntryFormControl(configFields['Excavator.CompanyTypeID'], excavator && excavator.CompanyTypeID || null),
            CompanyIndustryID: new EntryFormControl(configFields['Excavator.CompanyIndustryID'], excavator && excavator.CompanyIndustryID || null),

            //  Office fields
            OfficeID: new EntryFormControl(configFields['Excavator.OfficeID'], excavator && excavator.OfficeID || null),
            OfficeName: new EntryFormControl(configFields['Excavator.OfficeName'], excavator && excavator.OfficeName || null),
            OfficeAddress1: new EntryFormControl(configFields['Excavator.OfficeAddress1'], excavator && excavator.OfficeAddress1 || null),
            OfficeAddress2: new EntryFormControl(configFields['Excavator.OfficeAddress2'], excavator && excavator.OfficeAddress2 || null),
            OfficeCity: new EntryFormControl(configFields['Excavator.OfficeCity'], excavator && excavator.OfficeCity || null),
            OfficeState: new EntryFormControl(configFields['Excavator.OfficeState'], excavator && excavator.OfficeState || null),
            OfficeZip: new EntryFormControl(configFields['Excavator.OfficeZip'], excavator && excavator.OfficeZip || null),
            OfficeCounty: new EntryFormControl(configFields['Excavator.OfficeCounty'], excavator && excavator.OfficeCounty || null),
            OfficePhone: new EntryFormControl(configFields['Excavator.OfficePhone'], excavator && excavator.OfficePhone || null),

            //  Contact fields
            ExcavatorID: new EntryFormControl(configFields['Excavator.ExcavatorID'], excavator && excavator.ExcavatorID || null),
            ContactName: new EntryFormControl(configFields['Excavator.ContactName'], excavator && excavator.ContactName || null),
            ContactMainPhone: new EntryFormControl(configFields['Excavator.ContactMainPhone'], excavator && excavator.ContactMainPhone || null),
            ContactBestTime: new EntryFormControl(configFields['Excavator.ContactBestTime'], excavator && excavator.ContactBestTime || null),
            ContactEmail: new EntryFormControl(configFields['Excavator.ContactEmail'], excavator && excavator.ContactEmail || null),
            ContactEmailNA: new EntryFormControl(configFields['Excavator.ContactEmailNA'], excavator && excavator.ContactEmailNA || false),

            //  Additional phones/emails for the main contact
            ContactAdditionalPhone1Type: contactAdditionalPhone1Type,
            ContactAdditionalPhone1Value: contactAdditionalPhone1Value,
            ContactAdditionalPhone2Type: contactAdditionalPhone2Type,
            ContactAdditionalPhone2Value: contactAdditionalPhone2Value,

            //  Additional contact (i.e. a field contact) and phones/emails for that contact
            AltContactName: new EntryFormControl(configFields['Excavator.AltContactName'], excavator && excavator.AltContactName || null),
            ContactAdditionalContactType1: contactAdditionalContactType1,
            ContactAdditionalContactValue1: contactAdditionalContactValue1,
            ContactAdditionalContactType2: contactAdditionalContactType2,
            ContactAdditionalContactValue2: contactAdditionalContactValue2,

            //  These are not input controls (they do not have field configs) but we need to set the properties when saving a ticket.
            //  The values are initialized by the server when editing/copying/resuming so keep those values if they are given.  A new ticket will always default to NoChanges.
            CompanySaveAction: new FormControl(excavator && excavator.CompanySaveAction || ExcavatorSaveActionEnum.NoChange),
            OfficeSaveAction: new FormControl(excavator && excavator.OfficeSaveAction || ExcavatorSaveActionEnum.NoChange),
            ContactSaveAction: new FormControl(excavator && excavator.ContactSaveAction || ExcavatorSaveActionEnum.NoChange),
        });
    }

    private ConfigureAllPhoneTypeAndValueFieldSubscriptions(destroyed: Subject<void>): void {
        const contactAdditionalPhone1Type = this.get("Excavator.ContactAdditionalPhone1Type") as EntryFormControl;
        const contactAdditionalPhone1Value = this.get("Excavator.ContactAdditionalPhone1Value") as EntryFormControl;
        const contactAdditionalPhone2Type = this.get("Excavator.ContactAdditionalPhone2Type") as EntryFormControl;
        const contactAdditionalPhone2Value = this.get("Excavator.ContactAdditionalPhone2Value") as EntryFormControl;

        //  Phones/emails for the additional contact (i.e. a field contact)
        const contactAdditionalContactType1 = this.get("Excavator.ContactAdditionalContactType1") as EntryFormControl;
        const contactAdditionalContactValue1 = this.get("Excavator.ContactAdditionalContactValue1") as EntryFormControl;
        const contactAdditionalContactType2 = this.get("Excavator.ContactAdditionalContactType2") as EntryFormControl;
        const contactAdditionalContactValue2 = this.get("Excavator.ContactAdditionalContactValue2") as EntryFormControl;

        //  Doing this configuration (and adding event handlers for changes) allows us to initialize the FormControl properly *BEFORE* is gets referenced
        //  on any forms - prevents "expression changed" errors.  Had been doing this in ExcavatorSectionBase and it ended up not enforcing the validations
        //  correctly and/or triggering "expression changed" errors when the form was validated.
        this.ConfigurePhoneTypeAndValueFieldSubscription(contactAdditionalPhone1Type, contactAdditionalPhone1Value, destroyed);
        this.ConfigurePhoneTypeAndValueFieldSubscription(contactAdditionalPhone2Type, contactAdditionalPhone2Value, destroyed);
        this.ConfigurePhoneTypeAndValueFieldSubscription(contactAdditionalContactType1, contactAdditionalContactValue1, destroyed);
        this.ConfigurePhoneTypeAndValueFieldSubscription(contactAdditionalContactType2, contactAdditionalContactValue2, destroyed);
    }

    private ConfigurePhoneTypeAndValueFieldSubscription(typeFormControl: EntryFormControl, valueFormControl: EntryFormControl, destroyed: Subject<void>): void {
        typeFormControl.valueChanges
            .pipe(takeUntil(destroyed))
            .subscribe(type => TicketEntryFormGroup.ConfigureAdditionalContactValue(type, valueFormControl, false));
    }

    private static ConfigureAdditionalContactValue(type: AdditionalContactTypeEnum, valueFormControl: EntryFormControl, isInitialSetup: boolean): void {
        const fieldConfig = valueFormControl.FieldConfiguration;
        const prevControlType = fieldConfig.UIControlType;

        if (type === AdditionalContactTypeEnum.Email) {
            if (isInitialSetup || (prevControlType !== FieldUIControlTypeEnum.Email)) {
                fieldConfig.UIControlType = FieldUIControlTypeEnum.Email;
                fieldConfig.Required = true;
                valueFormControl.UpdateValidators();

                if (!isInitialSetup && (prevControlType !== FieldUIControlTypeEnum.Email)) {
                    valueFormControl.setValue(null);
                    valueFormControl.markAsDirty();
                    valueFormControl.updateValueAndValidity();
                }
            }
        } else {
            const isEmpty = (type === null) || (type === undefined);

            if (isInitialSetup || (prevControlType !== FieldUIControlTypeEnum.Phone) || isEmpty) {
                //  Need setTimeout or get "expression changed..." error
                fieldConfig.UIControlType = FieldUIControlTypeEnum.Phone;
                fieldConfig.Required = !isEmpty;        //  Required if something picked, else not required
                valueFormControl.UpdateValidators();

                //  Only reset if was email (or is now empty) - in case somthing is entered when type not set
                if (!isInitialSetup && (isEmpty || (prevControlType !== FieldUIControlTypeEnum.Phone))) {
                    valueFormControl.setValue(null);
                    valueFormControl.markAsDirty();
                    valueFormControl.updateValueAndValidity();
                }
            }
        }
    }

    private static CreateDigSiteFormGroup(configFields: { [key: string]: EntryFieldConfigurationResponse; }, digSite: DigSite, usesCountyInLocations: boolean): FormGroup {
        return new FormGroup({
            DigsiteEnteredType: new EntryFormControl(configFields['DigSite.DigsiteEnteredType'], digSite && digSite.DigsiteEnteredType),
            GeometryJson: new EntryFormControl(configFields['DigSite.GeometryJson'], digSite && digSite.GeometryJson),
            BufferFt: new EntryFormControl(configFields['DigSite.BufferFt'], digSite && digSite.BufferFt),
            UnbufferedGeometryJson: new EntryFormControl(configFields['DigSite.UnbufferedGeometryJson'], digSite && digSite.UnbufferedGeometryJson),
            Intersections: new FormGroup({
                Inter1: this.CreateDigSiteIntersectionFormGroup(DigSiteIntersectionItemTypeEnum.Inter1, configFields, "DigSite.Intersections", digSite && digSite.Intersections && digSite.Intersections.Inter1 || null, usesCountyInLocations),
                Inter2: this.CreateDigSiteIntersectionFormGroup(DigSiteIntersectionItemTypeEnum.Inter2, configFields, "DigSite.Intersections", digSite && digSite.Intersections && digSite.Intersections.Inter2 || null, usesCountyInLocations),
                Inter3: this.CreateDigSiteIntersectionFormGroup(DigSiteIntersectionItemTypeEnum.Inter3, configFields, "DigSite.Intersections", digSite && digSite.Intersections && digSite.Intersections.Inter3 || null, usesCountyInLocations),
                Inter4: this.CreateDigSiteIntersectionFormGroup(DigSiteIntersectionItemTypeEnum.Inter4, configFields, "DigSite.Intersections", digSite && digSite.Intersections && digSite.Intersections.Inter4 || null, usesCountyInLocations),
            }),
            FootprintAmount: new EntryFormControl(configFields['DigSite.FootprintAmount'], digSite && digSite.FootprintAmount || null),
            FootprintUnits: new EntryFormControl(configFields['DigSite.FootprintUnits'], digSite ? digSite.FootprintUnits : null),
            BothSidesOfStreet: new EntryFormControl(configFields['DigSite.BothSidesOfStreet'], digSite ? digSite.BothSidesOfStreet : null),
            NearEdgeOfRoad: new EntryFormControl(configFields['DigSite.NearEdgeOfRoad'], digSite ? digSite.NearEdgeOfRoad : null),
            Latitude: new EntryFormControl(configFields['DigSite.Latitude'], digSite ? digSite.Latitude : null),
            Longitude: new EntryFormControl(configFields['DigSite.Longitude'], digSite ? digSite.Longitude : null),
            NearRailroad: new EntryFormControl(configFields['DigSite.NearRailroad'], digSite?.NearRailroad),
        }, SecondaryRequiredWithPrimaryValidator("FootprintAmount", "FootprintUnits", "Required when Amount entered"));
    }

    private static CreateNearStreetsFormArray(ticketConfiguration: TicketEntryConfigurationResponse, ticket: Ticket): FormArray {
        //  FormArray info:
        //  https://angular.io/api/forms/FormArray
        //  Example: https://alligator.io/angular/reactive-forms-formarray-dynamic-fields/
        const formArray = new FormArray([]);

        if (!ticket || !ticket.NearStreets)
            return formArray;

        ticket.NearStreets.forEach(ns => {
            formArray.push(this.CreateNearStreetFormGroup(ticketConfiguration.Fields, ns));
        });

        return formArray;
    }

    private static CreateNearStreetFormGroup(configFields: { [key: string]: EntryFieldConfigurationResponse; }, nearStreet: TicketNearStreet): FormGroup {
        //  Do not need to worry about the change events on NearStreetText - the valueChanges subscription only watches the
        //  individual street component values (not the NearStreetText - since that is not geocodable).  Those values are not changed
        //  unless something is picked from the autocomplete or after we have done a "VerifyEnteredStreet").
        return new FormGroup({
            Order: new EntryFormControl(configFields['NearStreets.Order'], nearStreet ? nearStreet.Order : 0),
            NearStreetText: new EntryFormControl(configFields['NearStreets.NearStreetText'], nearStreet && nearStreet.NearStreetText || null),
            GeometryJson: new EntryFormControl(configFields['NearStreets.GeometryJson'], nearStreet && nearStreet.GeometryJson || null),
            UnbufferedGeometryJson: new EntryFormControl(configFields['NearStreets.UnbufferedGeometryJson'], nearStreet && nearStreet.UnbufferedGeometryJson || null),
            DistanceFt: new EntryFormControl(configFields['NearStreets.DistanceFt'], nearStreet ? nearStreet.DistanceFt : null),
            Prefix: new EntryFormControl(configFields['NearStreets.Prefix'], nearStreet && nearStreet.Prefix || null),
            Name: new EntryFormControl(configFields['NearStreets.Name'], nearStreet && nearStreet.Name || null),
            StreetType: new EntryFormControl(configFields['NearStreets.StreetType'], nearStreet && nearStreet.StreetType || null),
            Suffix: new EntryFormControl(configFields['NearStreets.Suffix'], nearStreet && nearStreet.Suffix || null),
            HundredBlock: new EntryFormControl(configFields['NearStreets.HundredBlock'], nearStreet && nearStreet.HundredBlock || null),
            SideOfStreet: new EntryFormControl(configFields['NearStreets.SideOfStreet'], nearStreet ? nearStreet.SideOfStreet : null),         // Don't translate 0 to null!
            WithinQuarterMile: new EntryFormControl(configFields['NearStreets.WithinQuarterMile'], nearStreet ? nearStreet.WithinQuarterMile : null),  //  bool: don't translate false to null!
            DistanceFromNearStreet: new EntryFormControl(configFields['NearStreets.DistanceFromNearStreet'], nearStreet && nearStreet.DistanceFromNearStreet || null),
        });
    }

    private static CreateDigSiteIntersectionFormGroup(itemType: DigSiteIntersectionItemTypeEnum, configFields: { [key: string]: EntryFieldConfigurationResponse; },
        propNameBase: string, intersection: DigSiteIntersection, usesCountyInLocations: boolean): FormGroup
    {
        propNameBase += ".";

        //  CountyName and PlaceName must updateOn:blur or the autocompletes that are used will trigger a change on every keystroke which
        //  then triggers a geocode on every keystroke (well, debounced to 300ms).  We only want geocodes on blur.
        return new FormGroup({
            ItemType: new EntryFormControl(configFields[propNameBase + 'ItemType'], itemType),
            State: new EntryFormControl(configFields[propNameBase + 'State'], intersection && intersection.State || null, { forceRequired: (itemType === DigSiteIntersectionItemTypeEnum.Inter1) }),
            CountyName: new EntryFormControl(configFields[propNameBase + 'CountyName'], intersection && intersection.CountyName || null, { forceRequired: (usesCountyInLocations && (itemType === DigSiteIntersectionItemTypeEnum.Inter1)), forceUpdateOn: 'blur'  }),
            PlaceName: new EntryFormControl(configFields[propNameBase + 'PlaceName'], intersection && intersection.PlaceName || null, { forceRequired: (itemType === DigSiteIntersectionItemTypeEnum.Inter1), forceUpdateOn: 'blur'  }),
            CallerPlace: new EntryFormControl(configFields[propNameBase + 'CallerPlace'], intersection && intersection.CallerPlace || null),
            Corner: new EntryFormControl(configFields[propNameBase + 'Corner'], intersection ? intersection.Corner : null),            // Don't translate 0 to null!

            Streets: new FormGroup({
                Street: this.CreateDigSiteStreetFormGroup(DigSiteStreetItemTypeEnum.Street, configFields, propNameBase + "Streets", intersection && intersection.Streets && intersection.Streets.Street || null, (itemType === DigSiteIntersectionItemTypeEnum.Inter1)),
                CrossSt: this.CreateDigSiteStreetFormGroup(DigSiteStreetItemTypeEnum.CrossSt, configFields, propNameBase + "Streets", intersection && intersection.Streets && intersection.Streets.CrossSt || null, false),
            }),

            Offsets: new FormGroup({
                Expand1: this.CreateDigSiteOffsetFormGroup(DigSiteOffsetItemTypeEnum.Expand1, configFields, propNameBase + "Offsets", intersection && intersection.Offsets && intersection.Offsets.Expand1 || null),
                Expand2: this.CreateDigSiteOffsetFormGroup(DigSiteOffsetItemTypeEnum.Expand2, configFields, propNameBase + "Offsets", intersection && intersection.Offsets && intersection.Offsets.Expand2 || null),
                Move1: this.CreateDigSiteOffsetFormGroup(DigSiteOffsetItemTypeEnum.Move1, configFields, propNameBase + "Offsets", intersection && intersection.Offsets && intersection.Offsets.Move1 || null),
                Move2: this.CreateDigSiteOffsetFormGroup(DigSiteOffsetItemTypeEnum.Move2, configFields, propNameBase + "Offsets", intersection && intersection.Offsets && intersection.Offsets.Move2 || null)
            })
        });
    }

    private static CreateDigSiteStreetFormGroup(itemType: DigSiteStreetItemTypeEnum, configFields: { [key: string]: EntryFieldConfigurationResponse; },
        propNameBase: string, street: DigSiteStreet, requireStreet: boolean): FormGroup
    {
        propNameBase += ".";

        //  EnteredStreetAddress must updateOn:blur or the autocompletes that are used will trigger a change on every keystroke which
        //  then triggers a geocode on every keystroke (well, debounced to 300ms).  We only want geocodes on blur.
        return new FormGroup({
            ItemType: new EntryFormControl(configFields[propNameBase + 'ItemType'], itemType),
            EnteredStreetAddress: new EntryFormControl(configFields[propNameBase + 'EnteredStreetAddress'], street && street.EnteredStreetAddress || null, { forceRequired: requireStreet, forceUpdateOn: 'blur' }),               //  All address components combined into a single field
            FromAddress: new EntryFormControl(configFields[propNameBase + 'FromAddress'], street && street.FromAddress || null),
            ToAddress: new EntryFormControl(configFields[propNameBase + 'ToAddress'], street && street.ToAddress || null),
            Prefix: new EntryFormControl(configFields[propNameBase + 'Prefix'], street && street.Prefix || null),
            Name: new EntryFormControl(configFields[propNameBase + 'Name'], street && street.Name || null, { forceRequired: requireStreet }),
            StreetType: new EntryFormControl(configFields[propNameBase + 'StreetType'], street && street.StreetType || null),
            Suffix: new EntryFormControl(configFields[propNameBase + 'Suffix'], street && street.Suffix || null),
            HundredBlock: new EntryFormControl(configFields[propNameBase + 'HundredBlock'], street && street.HundredBlock || null),
            SideOfStreet: new EntryFormControl(configFields[propNameBase + 'SideOfStreet'], street ? street.SideOfStreet : null),         // Don't translate 0 to null!

            //  Not a ticket entry control.  Used to track the last value we verified using VerifyEnteredStreet and to know if we did
            //  at all when attempting to geocode.  Because if we are trying to geocode and we haven't done that yet, it means the user typed
            //  something and then initiated the geocode - by saving the ticket - before that could happen.  So in that case, we need to
            //  only send the EnteredStreetAddress and let the server parse it out (which is what VerifyEnteredStreet does).
            LastVerifiedEnteredStreetAddress: new FormControl(street && street.EnteredStreetAddress || null),
        });
    }

    private static CreateDigSiteOffsetFormGroup(itemType: DigSiteOffsetItemTypeEnum, configFields: { [key: string]: EntryFieldConfigurationResponse; }, propNameBase: string, offset: DigSiteOffset): FormGroup {
        propNameBase += ".";

        return new FormGroup({
            ItemType: new EntryFormControl(configFields[propNameBase + 'ItemType'], itemType),
            Amount: new EntryFormControl(configFields[propNameBase + 'Amount'], offset ? offset.Amount : null),
            Units: new EntryFormControl(configFields[propNameBase + 'Units'], offset ? offset.Units : null),                  // Don't translate 0 to null!
            Direction: new EntryFormControl(configFields[propNameBase + 'Direction'], offset ? offset.Direction : null),      // Don't translate 0 to null!
            FollowRoad: new EntryFormControl(configFields[propNameBase + 'FollowRoad'], offset ? offset.FollowRoad : false)   // bool: don't translate false to null!
        });
    }

    private static CreateDynamicFieldsFormGroup(ticketConfiguration: TicketEntryConfigurationResponse, ticket: Ticket): FormGroup {
        const formGroup = new FormGroup({});

        if (ticketConfiguration.DynamicFieldNames) {
            //  TODO: May need to change how this works to include validations that are dependent on the type of field?
            //  Or OCC form could do that in ConfigureCustomFormControls()...
            for (const fieldName of ticketConfiguration.DynamicFieldNames)
                formGroup.addControl(fieldName, this.CreateDynamicFieldFormGroup(ticketConfiguration.Fields, fieldName, ticket.DynamicFields && ticket.DynamicFields[fieldName] || null));
        }

        return formGroup;
    }

    private static CreateDynamicDataFormGroup(ticketConfiguration: TicketEntryConfigurationResponse, ticket: Ticket): FormGroup {
        const formGroup = new FormGroup({});

        for (const [propertyName, config] of Object.entries(ticketConfiguration.Fields)) {
            if (!propertyName.startsWith("DynamicData."))
                continue;
            const fieldName = propertyName.replace("DynamicData.", "");

            let fieldValue = ticket.DynamicData && ticket.DynamicData[fieldName] || null;

            if ((config.UIControlType === FieldUIControlTypeEnum.Checkbox) && (fieldValue !== undefined) && (fieldValue !== null))
                fieldValue = coerceBooleanProperty(fieldValue);     //  Need to coerce these or they are translated as strings (which make checkboxes think it's always true)

            formGroup.addControl(fieldName, new EntryFormControl(config, fieldValue));
        }

        if (ticket.DynamicData?.MultipleTicket) {
            //  This is a special case.  There are no field configurations for it (at least not currently).  The data is just stored
            //  as a json object by a One Call custom component.  Search for "-Create-Multiple-Tickets-Content" to find those components.
            //  i.e. SC811_CreateMultipleTicketsContentComponent
            //  If MultipleTicket exists, we need to add the FormControl now or we won't be able to read the existing values
            //  for a ticket that was saved as Incomplete w/Multiple Ticket info entered.
            //  If a new ticket is created and adds this information, the custom component will add the FormControl as needed.
            formGroup.addControl("MultipleTicket", new FormControl(ticket.DynamicData.MultipleTicket));
        }

        return formGroup;
    }

    protected static CreateDynamicFieldFormGroup(configFields: { [key: string]: EntryFieldConfigurationResponse; }, fieldName: string, dynamicField: TicketDynamicField): FormGroup {
        const propNameBase = 'DynamicFields.' + fieldName + '.';

        let fieldValue = dynamicField ? dynamicField.FieldValue : null;
        const fieldValueConfig: EntryFieldConfigurationResponse = configFields[propNameBase + 'FieldValue'];

        if ((fieldValueConfig.UIControlType === FieldUIControlTypeEnum.Checkbox) && (fieldValue !== undefined) && (fieldValue !== null))
            fieldValue = coerceBooleanProperty(fieldValue);     //  Need to coerce these or they are translated as strings (which make checkboxes think it's always true)

        //  TODO: Need extra info about the field to add validators?
        return new FormGroup({
            FieldName: new EntryFormControl(configFields[propNameBase + 'FieldName'], fieldName),
            FieldValue: new EntryFormControl(fieldValueConfig, fieldValue)
        });
    }

    private static CreateSiteContactsFormArray(ticketConfiguration: TicketEntryConfigurationResponse, ticket: Ticket): FormArray {
        //  FormArray info:
        //  https://angular.io/api/forms/FormArray
        //  Example: https://alligator.io/angular/reactive-forms-formarray-dynamic-fields/
        const formArray = new FormArray([]);

        if (!ticket || !ticket.SiteContacts)
            return formArray;

        ticket.SiteContacts.forEach(sc => {
            formArray.push(this.CreateSiteContactFormGroup(ticketConfiguration.Fields, sc));
        });

        return formArray;
    }

    private static CreateSiteContactFormGroup(configFields: { [key: string]: EntryFieldConfigurationResponse; }, siteContact: TicketSiteContact): FormGroup {
        return new FormGroup({
            ExcavatorContactID: new EntryFormControl(configFields['SiteContacts.ExcavatorContactID'], siteContact ? siteContact.ExcavatorContactID : null),
            ExcavatorOfficeID: new EntryFormControl(configFields['SiteContacts.ExcavatorOfficeID'], siteContact ? siteContact.ExcavatorOfficeID : null),
            SiteContactType: new EntryFormControl(configFields['SiteContacts.SiteContactType'], siteContact ? siteContact.SiteContactType : null),
            Order: new EntryFormControl(configFields['SiteContacts.Order'], siteContact ? siteContact.Order : null),
            Name: new EntryFormControl(configFields['SiteContacts.Name'], siteContact ? siteContact.Name : null),
            MainPhoneType: new EntryFormControl(configFields['SiteContacts.MainPhoneType'], siteContact ? siteContact.MainPhoneType : null),
            MainPhone: new EntryFormControl(configFields['SiteContacts.MainPhone'], siteContact && siteContact.MainPhone || null),
            Email: new EntryFormControl(configFields['SiteContacts.Email'], siteContact && siteContact.Email || null),

            //  These do not have input controls (do not have field configs) but we need to set the SaveAction property when saving a ticket.
            SaveAction: new FormControl(SiteContactSaveActionEnum.NoChange),      //  What if we are continuing an incomplete ticket - need to figure out the right initial value?
            PersonID: new FormControl(siteContact.PersonID),
            Login: new FormControl(siteContact.Login)
        });
    }

    public AddNewSiteContact(changeDetector: ChangeDetectorRef): void {
        const siteContactsFormArray = this.get("SiteContacts") as FormArray;

        const siteContact = new TicketSiteContact();
        siteContact.Order = siteContactsFormArray.controls.length + 1;
        siteContact.SiteContactType = TicketSiteContactTypeEnum.SiteContact;

        siteContactsFormArray.push(TicketEntryFormGroup.CreateSiteContactFormGroup(this.TicketService.TicketConfiguration.Fields, siteContact));

        //  This is necessary or (if there is a required field - and there is) it will cause an ExpressionChangedAfterItHasBeenCheckedError
        //  because it makes the model invalid.
        changeDetector.detectChanges();
    }

    private static CreateAttachmentsFormArray(ticketConfiguration: TicketEntryConfigurationResponse, ticket: Ticket): FormArray {
        //  FormArray info:
        //  https://angular.io/api/forms/FormArray
        //  Example: https://alligator.io/angular/reactive-forms-formarray-dynamic-fields/
        const formArray = new FormArray([]);

        if (!ticket || !ticket.Attachments)
            return formArray;

        ticket.Attachments.forEach(a => {
            formArray.push(this.CreateAttachmentFormGroup(ticketConfiguration.Fields, a));
        });

        return formArray;
    }

    private static CreateAttachmentFormGroup(configFields: { [key: string]: EntryFieldConfigurationResponse; }, attachment: TicketAttachment): FormGroup {
        return new FormGroup({
            FileUploadID: new EntryFormControl(configFields['Attachments.FileUploadID'], attachment ? attachment.FileUploadID : null),
            AttachmentType: new EntryFormControl(configFields['Attachments.AttachmentType'], attachment ? attachment.AttachmentType : TicketAttachmentTypeEnum.ExcavatorUpload),
            Public: new EntryFormControl(configFields['Attachments.Public'], attachment ? attachment.Public : false),
            FileName: new EntryFormControl(configFields['Attachments.FileName'], attachment ? attachment.FileName : null),
            ContentType: new EntryFormControl(configFields['Attachments.ContentType'], attachment ? attachment.ContentType : null),
            Size: new EntryFormControl(configFields['Attachments.Size'], attachment ? attachment.Size : null)
        });
    }

    public AddNewAttachment(/*changeDetector: ChangeDetectorRef*/): FormGroup {
        const attachmentsFormArray = this.get("Attachments") as FormArray;

        const attachment = new TicketAttachment();
        attachment.AttachmentType = TicketAttachmentTypeEnum.ExcavatorUpload;
        attachment.Public = true;
        const formGroup = TicketEntryFormGroup.CreateAttachmentFormGroup(this.TicketService.TicketConfiguration.Fields, attachment);

        //  Not sure why, but if we don't specifically set this to null AFTER creating the formGroup, the required validator is
        //  not checked and the form is not marked as invalid!  Tried setting all of the "mark...()" properties and also "updateValueAndValidity".
        formGroup.get("FileUploadID").setValue(null);

        attachmentsFormArray.push(formGroup);

        //  This is necessary or (if there is a required field - and there is) it will cause an ExpressionChangedAfterItHasBeenCheckedError
        //  because it makes the model invalid.
        //changeDetector.detectChanges();

        return formGroup;
    }

    public IsHomeownerCompanyTypePicked(): boolean {
        const excavatorCompanyTypeID = this.get('Excavator.CompanyTypeID').value;

        return this.TicketEntryOptionsService.IsHomeownerExcavatorCompanyType(excavatorCompanyTypeID);
    }

    //  Note that this method is called from inside the constructor of this class.  Which executes BEFORE any extra code
    //  put in a derived classes constructor!  So if this is overridden, don't expect anything done in a derived constructor
    //  to have been done yet!  Put that in the constructor (and it will end up executing AFTER all of this stuff here).
    protected SetupFormForTicket(): void {
        this.SetupShortcuts();

        this.SetupFormForCurrentExcavatorCompanyType();

        this.BuildGeocodeTypeDropdownItems();
    }

    //  *** These are only registered when editing a ticket!
    public RegisterFieldChangeHandlers(destroyed: Subject<void>): void {
        //  Must monitor just the properties in the DigSite and NearStreet that require re-geocoding.  Otherwise, changing the
        //  distance or geometry will re-trigger it and it will be stuck in an infinite loop!

        //  Debounce this in case we are setting multiple fields at once and for when we are setting the value
        //  from an autocomplete field (which will initially fire a change if we tab and then fire another change
        //  after the autocomplete sets the selected value).
        //  Autocomplete debounces for 200ms so must debounce this longer than that!
        //  TODO: Might also be better if we specified all of the properties inside the Intersections object.
        //  ...but there are lots.  Doing so would prevent an event from firing when we change the EnteredStreet
        //  before the component fields have been verified...
        merge(this.get("DigSite.DigsiteEnteredType").valueChanges,
            //  Not currently enabled because it only affects the 2 Clip flags.  Do not currently have situation where changing the ticket type can
            //  cause different values of those settings.  IN/KY is only one that has different settings on their ticket type with the difference being
            //  between the Design Inquiry ticket type (no clipping) and all others (clipping the county).
            //  And it's not possible to switch between a Design Inquiry and those other types because Design Inquiry has it's own dedicated form.
            //this.get("TicketTypeID").valueChanges,
            this.get("DigSite.Intersections").valueChanges,
            this.get("DigSite.FootprintAmount").valueChanges,
            this.get("DigSite.FootprintUnits").valueChanges,
            //  DigSite.BothSidesOfStreet affects buffer size for NY parcels - do not handle generically like this or will re-geocode using "best" geocode type
            //  and could change the geocode type if user manually changed it!  FD #1936
            //  Would prefer this to be handled generically (here) but since it only applies to NY Parcels AND we can't allow this to trigger a "best" geocode,
            //  it must be handled in a custom handler is in UDIGNYTicketEntryFormGroup.RegisterFieldChangeHandlers().
            //  If this field affects geocoding for another One Call, will need to also handle in a custom handler.
            this.get("DigSite.Latitude").valueChanges,
            this.get("DigSite.Longitude").valueChanges)
            .pipe(takeUntil(destroyed), debounceTime(300))
            .subscribe(() => {
                this.GeocodeTicketDigSiteIfChanged().subscribe();
                this.GeocodeAllNearStreetsIfChanged();
            });

        //  Must monitor just the properties in the NearStreet that require re-geocoding.  Otherwise, changing the
        //  distance or geometry will re-trigger it and it will be stuck in an infinite loop!
        //  A change in any of these will trigger a (debounced) geocode.
        //  The number of NearStreets in the FormArray is pre-defined for the One Call (server always returns all of them).
        //  If we ever need to make that dynamic at the client level, we will need to address dynamically adding these.
        const nearStreetsFormArray = this.get("NearStreets") as FormArray;
        nearStreetsFormArray.controls.forEach(nearStreetFormGroup => {
            merge(nearStreetFormGroup.get("Prefix").valueChanges, nearStreetFormGroup.get("Name").valueChanges,
                        nearStreetFormGroup.get("StreetType").valueChanges, nearStreetFormGroup.get("Suffix").valueChanges)
                .pipe(takeUntil(destroyed), debounceTime(300))
                .subscribe(() => {
                    this.GeocodeNearStreetIfChanged(nearStreetFormGroup as FormGroup);
                });
        });

        this.get("Excavator.CompanyTypeID").valueChanges
            .pipe(takeUntil(destroyed), debounceTime(100))
            .subscribe(() => {
                this.SetupFormForCurrentExcavatorCompanyType();
            });

        //  Watch for changes on these properties to set the ServiceArea list to be dirty.
        //  Note: TicketService watches for changes to the geocode.
        this.get("TicketTypeID").valueChanges
            .pipe(takeUntil(destroyed))
            .subscribe((ticketTypeID) => {
                this.SetServiceAreaListDirty();
                this.OnTicketTypeIDChanged(ticketTypeID);
            });

        this.get("LocateTypeID").valueChanges
            .pipe(takeUntil(destroyed))
            .subscribe(() => this.SetServiceAreaListDirty());

        this.get("DigSite.NearEdgeOfRoad").valueChanges
            .pipe(takeUntil(destroyed))
            .subscribe(() => this.SetServiceAreaListDirty());

        this.ConfigureAllPhoneTypeAndValueFieldSubscriptions(destroyed);
    }

    protected OnTicketTypeIDChanged(ticketTypeID: string): void {
        //  Check to see if this ticket type has a different DefaultDate specified.  If so, change the Default Date.
        //  Needed by KY for their Fiber to the Premises ticket type (which has a 4 day notice instead of 2).
        //  DateConfiguration is not set for AZ because ticket type is initially null.
        if (this.TicketService.TicketConfiguration.DateConfiguration?.DefaultDatesForTicketTypes) {
            let dateForTicketType = this.TicketService.TicketConfiguration.DateConfiguration.DefaultDatesForTicketTypes[ticketTypeID];
            if (!dateForTicketType)
                dateForTicketType = this.TicketService.TicketConfiguration.DateConfiguration.DefaultDatesForTicketTypes["_DEFAULT_"];
            if (dateForTicketType)
                this.TicketService.TicketConfiguration.DateConfiguration.DefaultDate = dateForTicketType;
        }
    }

    private SetupFormForCurrentExcavatorCompanyType(): void {
        this.OnExcavatorCompanyTypeIDChanged(this.IsHomeownerCompanyTypePicked());
    }

    protected OnExcavatorCompanyTypeIDChanged(isHomeownerPicked: boolean): void {
        this.DisableProperties(this.TicketService.TicketConfiguration.DisabledHomeownerFields, isHomeownerPicked);
    }

    public ResetExcavatorInfo(): void {
        const emptyExcavator = new TicketExcavator();
        const emptyExcavatorFormControl = TicketEntryFormGroup.CreateExcavatorFormGroup(this.TicketService.TicketConfiguration.Fields, emptyExcavator);
        this.get("Excavator").setValue(emptyExcavatorFormControl.value);
    }

    public ResetDigSite(): void {
        const emptyDigsite = new DigSite();
        emptyDigsite.DigsiteEnteredType = DigsiteEnteredTypeEnum.Street;
        const inter1 = new DigSiteIntersection();
        inter1.ItemType = DigSiteIntersectionItemTypeEnum.Inter1;
        inter1.State = this.get("DigSite.Intersections.Inter1.State").value;        //  Preserve the state on Inter1
        emptyDigsite.Intersections = { Inter1: inter1 };

        //  Use the existing method to create the DigSite Form Group using the empty dig site object.
        //  This will allow us to then fetch a fully formed DigSite object to use to set the DigSite.  If we don't do this
        //  and properties are missing, the Form Controls will throw errors!  So this ensures that we have a full
        //  DigSite object using the main method that initializes those controls in the first place (so we don't have to duplicate it).
        const usesCountyInLocations = this.TicketService.SettingsService.UsesCountyInLocations;
        const emptyDigSiteFormControl = TicketEntryFormGroup.CreateDigSiteFormGroup(this.TicketService.TicketConfiguration.Fields, emptyDigsite, usesCountyInLocations);

        this.get("DigSite").setValue(emptyDigSiteFormControl.value);
        this.get("ActualGeocodeType").setValue(GeocodeTypeEnum.NA);

        this.ClearNearStreets();

        this.TicketService.geocodeService.Clear();
    }

    private ClearNearStreets(): void {
        const nearStreetFormArray = this.get("NearStreets") as FormArray;
        const numNearStreets = nearStreetFormArray.controls.length;

        let i: number;
        for (i = 0; i < numNearStreets; i++) {
            const emptyNearStreet = new TicketNearStreet();
            emptyNearStreet.Order = i;

            //  See ResetDigSite() for why we're creating a FormGroup for each item
            const emptyNearStreetFormControl = TicketEntryFormGroup.CreateNearStreetFormGroup(this.TicketService.TicketConfiguration.Fields, emptyNearStreet);

            nearStreetFormArray.controls[i].setValue(emptyNearStreetFormControl.value);
        }
    }

    public GeocodeTicketDigSiteIfChanged(requestedGeocodeType?: GeocodeTypeEnum): Observable<GeocodeResponse> {
        return new Observable<GeocodeResponse>(observer => {
            //  Must use getRawValue() or disabled fields (like State) will be excluded!
            const currentGeocodeType = this.get("ActualGeocodeType").value as GeocodeTypeEnum;
            const digsite = (this.get("DigSite") as FormGroup).getRawValue() as DigSite;
            const ticketTypeID = this.get("TicketTypeID").value as string;

            //  If not allowed to change the dig site don't re-geocode!
            if (!this.CanModifyDigSite()) {
                observer.next(new GeocodeResponse(currentGeocodeType, digsite.GeometryJson, digsite.BufferFt, digsite.UnbufferedGeometryJson));
                observer.complete();
                return;
            }

            this.TicketService.geocodeService.GeocodeDigSiteIfChanged(currentGeocodeType, digsite, ticketTypeID, requestedGeocodeType)
                .subscribe(result => {
                    //  This never returns a null object
                    if (!result.IsPreviousResult) {
                        //  Nothing here should be done again if we returned the previous result - that means nothing changed from the last time!
                        //  If there are issues, this change was made (along with some changes inside GeocodeDigSiteIfChanged()) on 1/25/2021.
                        if (result.WarningMessage) {
                            let toastrConfig: Partial<IndividualConfig> = null;
                            if (result.WarningMustBeAcknowledged) {
                                //  Timeout is disabled so user is forced to click to dismiss it.  Per DigSafe (FD 1375) because users were not
                                //  paying attention to the "Parcel and Street segments are far away from each other..." warning.
                                toastrConfig = { disableTimeOut: true };
                            }
                            this.TicketService.ToastrService.warning(result.WarningMessage, null, toastrConfig);
                        }

                        this.SetNewDigSiteGeometry(result.GeocodeType, result.GeometryJson, result.BufferFt, result.UnbufferedGeometryJson);

                        if ((result.GeocodeType === GeocodeTypeEnum.LatLonCoordinate) && result.Place) {
                            //  If a lat/lon geocode returns state/county/place values, it's because they were not provided and it
                            //  looked them up from the coordinate.  So set the values in to the form.
                            this.get("DigSite.Intersections.Inter1.State").setValue(result.State);
                            this.get("DigSite.Intersections.Inter1.CountyName").setValue(result.County);
                            this.get("DigSite.Intersections.Inter1.PlaceName").setValue(result.Place);
                        }

                        if ((result.NearRailroad !== null) && (result.NearRailroad !== undefined))
                            this.get("DigSite.NearRailroad").setValue(result.NearRailroad);

                        this.SetLocationValidationErrors(result.ValidationErrors);
                    }

                    observer.next(result);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    //  This one needs to emit the event because it has it's own form control (for the masked edit).
    //  We now have support for masked edit in the FormControl so need to figure out if we can change how
    //  that works and then get rid of this special handling for it.
    private _ForceTicketValidationEmitEventForControlName: string = "DigSite.Latitude";

    private SetLocationValidationErrors(validationErrors: LocationValidationError[]): void {
        //  More special handling needed here for clearing a previous error...
        const forceClearFormControl = this.get(this._ForceTicketValidationEmitEventForControlName) as EntryFormControl;
        forceClearFormControl.ClearValidationErrorOfSource(EntryFieldValidationErrorSourceEnum.Location, true);

        const digSiteFormGroup = this.get("DigSite") as FormGroup;
        EntryFormGroupBase.ClearValidationErrorsFromAllFormFields(digSiteFormGroup, EntryFieldValidationErrorSourceEnum.Location);

        if (!validationErrors)
            return;

        validationErrors.forEach(err => {
            const control = this.get(err.PropertyName) as EntryFormControl;
            if (control) {
                const emitEvent = (err.PropertyName === this._ForceTicketValidationEmitEventForControlName);
                control.SetValidationError(EntryFieldValidationErrorSourceEnum.Location, err.Message, err.IsError, emitEvent);
            } else
                console.error("SetLocationValidationErrors: control " + err.PropertyName + "not found");
        });
    }

    public SetManualDigsite(geometryJson: object, bufferFt: number, unbufferedGeometry: object): void {
        if (!geometryJson)
            return;

        this.SetNewDigSiteGeometry(GeocodeTypeEnum.Manual, geometryJson, bufferFt, unbufferedGeometry);

        this.TicketService.geocodeService.SetManualDigsite(geometryJson, bufferFt, unbufferedGeometry);
    }

    private SetNewDigSiteGeometry(geocodeType: GeocodeTypeEnum, geometryJson: object, bufferFt: number, unbufferedGeometry: object): void {
        const actualGeocodeTypeFormControl = this.get("ActualGeocodeType");
        const geometryJsonFormControl = this.get("DigSite.GeometryJson");
        const bufferFtFormControl = this.get("DigSite.BufferFt");
        const unbufferedGeometryJsonFormControl = this.get("DigSite.UnbufferedGeometryJson");

        //  Always do all of this stuff if the geocode failed (is empty) - especially setting the values.
        //  A failed geocode needs to fire the event every time it happens or the ZoomToBestFit() handling in the map will not
        //  know to update itself (and the place or county could have changed).
        if (geometryJson && (actualGeocodeTypeFormControl.value === geocodeType) && (geometryJsonFormControl.value === geometryJson))
            return;

        actualGeocodeTypeFormControl.setValue(geocodeType);
        geometryJsonFormControl.setValue(geometryJson);
        bufferFtFormControl.setValue(bufferFt);
        unbufferedGeometryJsonFormControl.setValue(unbufferedGeometry);

        if (this.EditingTicket.value)
            this.TicketService.ServiceAreasAreDirty.next(true);

        this.BuildGeocodeTypeDropdownItems();

        this.CalculateDistanceToAllNearStreets();
    }

    public GeocodeAllNearStreetsIfChanged(): void {
        const nearStreetsFormArray = this.get("NearStreets") as FormArray;

        nearStreetsFormArray.controls.forEach(nearStreetFormGroup => this.GeocodeNearStreetIfChanged(nearStreetFormGroup as FormGroup));
    }

    private GeocodeNearStreetIfChanged(nearStreetFormGroup: FormGroup): void {
        //  Must use getRawValue() or disabled fields (like State) will be excluded!
        const nearStreet = nearStreetFormGroup.getRawValue() as TicketNearStreet;
        const digSiteEnteredType = this.get("DigSite.DigsiteEnteredType").value as DigsiteEnteredTypeEnum;
        const digsiteInter1 = (this.get("DigSite.Intersections.Inter1") as FormGroup).getRawValue() as DigSiteIntersection;

        this.TicketService.geocodeService.GeocodeNearStreetIfChanged(nearStreet.NearStreetText, digSiteEnteredType, digsiteInter1)
            .subscribe(result => {
                let json = result && result.UnbufferedGeometryJson || null;
                nearStreetFormGroup.get("UnbufferedGeometryJson").setValue(json);

                json = result && result.GeometryJson || null;
                nearStreetFormGroup.get("GeometryJson").setValue(json);

                this.CalculateDistanceToNearStreet(nearStreetFormGroup);

                //  The only validation error that can be returned is on the NearStreetText (if street is not found).
                //  And the server doesn't know the "Order" (so always sets as "0" in the PropertyName) and we can't clear all
                //  "near street validation errors" because there can be multiple near streets that are geocoded individually.
                const nearStreetTextFormControl = nearStreetFormGroup.controls["NearStreetText"] as EntryFormControl;
                const validationError = (result?.ValidationErrors && (result.ValidationErrors.length > 0)) ? result.ValidationErrors[0] : null;
                if (validationError)
                    nearStreetTextFormControl.SetValidationError(EntryFieldValidationErrorSourceEnum.Client, validationError.Message, validationError.IsError);
                else
                    nearStreetTextFormControl.ClearValidationErrorOfSource(EntryFieldValidationErrorSourceEnum.Client);
            });
    }

    private CalculateDistanceToAllNearStreets(): void {
        const nearStreetsFormArray = this.get("NearStreets") as FormArray;

        nearStreetsFormArray.controls.forEach(nearStreetFormGroup => this.CalculateDistanceToNearStreet(nearStreetFormGroup as FormGroup));
    }

    private CalculateDistanceToNearStreet(nearStreetFormGroup: FormGroup): void {
        //  Calculate against the unbuffered geometries if we have them (and we should always)
        const digSiteGeoJson = this.get("DigSite.UnbufferedGeometryJson").value || this.get("DigSite.GeometryJson").value;
        const nearStreetGeoJson = nearStreetFormGroup.get("UnbufferedGeometryJson").value || nearStreetFormGroup.get("GeometryJson").value;

        const distanceFt = GeometryUtils.DistanceFtBetweenGeoJsonObjects(digSiteGeoJson, nearStreetGeoJson);
        nearStreetFormGroup.get("DistanceFt").setValue(distanceFt);
    }

    /**
     *  Builds the list of possible geocode types (for the Notify By field in the header) that the user can pick
     *  to force a geocode of that type (or to reset after manually drawing).
     */
    private BuildGeocodeTypeDropdownItems(): void {

        //  Don't do this if we're not editing!
        if (!this.EditingTicket.value)
            return;

        //  Must use getRawValue() or disabled fields (like State) will be excluded!
        const digsite = (this.get("DigSite") as FormGroup).getRawValue() as DigSite;

        const digsiteEnteredType = this.get("DigSite.DigsiteEnteredType").value as DigsiteEnteredTypeEnum;
        const actualGeocodTypeFormcontrol = this.get("ActualGeocodeType") as EntryFormControl;
        const actualGeocodeType = actualGeocodTypeFormcontrol.value as GeocodeTypeEnum;
        const allowedGeocodeTypes = this.TicketService.TicketConfiguration.AllowedGeocodeTypes;

        //  TODO: Filter by allowed geocode types!

        const dropdownItems: EntryFieldDropdownItem[] = [];

        if (this.CanModifyDigSite()) {
            dropdownItems.push(new EntryFieldDropdownItem("Find Best", GeocodeTypeEnum.NA));

            //  Assume all of these are possible (based on what can possibly be entered)...
            //  ...but not necessarily based on what the user is allowed to do.
            if (_.includes(allowedGeocodeTypes, GeocodeTypeEnum.County))
                dropdownItems.push(this.CreateDropDownItemForGeocodeType(GeocodeTypeEnum.County));
            if (_.includes(allowedGeocodeTypes, GeocodeTypeEnum.Place))
                dropdownItems.push(this.CreateDropDownItemForGeocodeType(GeocodeTypeEnum.Place));
            if (_.includes(allowedGeocodeTypes, GeocodeTypeEnum.Street))
                dropdownItems.push(this.CreateDropDownItemForGeocodeType(GeocodeTypeEnum.Street));

            //  Note: All of the other lookup types can fallback to County/Place/Street/Address/Parcel - so these do not depend
            //  on the DigsiteEnteredType at all.
            const inter1 = digsite.Intersections[DigSiteIntersectionItemTypeEnum[DigSiteIntersectionItemTypeEnum.Inter1]];
            const street = inter1.Streets[DigSiteStreetItemTypeEnum[DigSiteStreetItemTypeEnum.Street]];
            if (street.FromAddress) {
                if (_.includes(allowedGeocodeTypes, GeocodeTypeEnum.Address))
                    dropdownItems.push(this.CreateDropDownItemForGeocodeType(GeocodeTypeEnum.Address));
                if (_.includes(allowedGeocodeTypes, GeocodeTypeEnum.Parcel))
                    dropdownItems.push(this.CreateDropDownItemForGeocodeType(GeocodeTypeEnum.Parcel));
                if (_.includes(allowedGeocodeTypes, GeocodeTypeEnum.AddressPoint))
                    dropdownItems.push(this.CreateDropDownItemForGeocodeType(GeocodeTypeEnum.AddressPoint));
            }

            if (digsiteEnteredType === DigsiteEnteredTypeEnum.Intersection) {
                const cross = inter1.Streets[DigSiteStreetItemTypeEnum[DigSiteStreetItemTypeEnum.CrossSt]];
                if (!_.isEmpty(cross.Name)) {
                    if (_.includes(allowedGeocodeTypes, GeocodeTypeEnum.Intersection))
                        dropdownItems.push(this.CreateDropDownItemForGeocodeType(GeocodeTypeEnum.Intersection));
                }
            }

            if (digsiteEnteredType === DigsiteEnteredTypeEnum.BetweenIntersections) {
                if (_.includes(allowedGeocodeTypes, GeocodeTypeEnum.BetweenIntersection))
                    dropdownItems.push(this.CreateDropDownItemForGeocodeType(GeocodeTypeEnum.BetweenIntersection));
            }

            if (digsiteEnteredType === DigsiteEnteredTypeEnum.BoundedBy) {
                if (_.includes(allowedGeocodeTypes, GeocodeTypeEnum.BoundedBy))
                    dropdownItems.push(this.CreateDropDownItemForGeocodeType(GeocodeTypeEnum.BoundedBy));
            }

            if (digsiteEnteredType === DigsiteEnteredTypeEnum.LatLon) {
                if (_.includes(allowedGeocodeTypes, GeocodeTypeEnum.LatLonCoordinate))
                    dropdownItems.push(this.CreateDropDownItemForGeocodeType(GeocodeTypeEnum.LatLonCoordinate));
            }

            if (actualGeocodeType === GeocodeTypeEnum.Manual)
                dropdownItems.push(this.CreateDropDownItemForGeocodeType(GeocodeTypeEnum.Manual));

        } else {
            //  Dig site is not editable (i.e. an AZ edit or in view mode).  So only add in the current geocode type
            dropdownItems.push(this.CreateDropDownItemForGeocodeType(actualGeocodeType));
        }

        actualGeocodTypeFormcontrol.FieldConfiguration.DropdownItems = dropdownItems;
    }

    private CreateDropDownItemForGeocodeType(geocodeType: GeocodeTypeEnum): EntryFieldDropdownItem {
        const desc = this.TicketService.geocodeService.GetGeocodeTypeDescription(geocodeType);
        return new EntryFieldDropdownItem(desc, geocodeType);
    }

    public VerifyTicketBeforeSave(): Observable<VerifyTicketBeforeSaveResponse> {
        return new Observable<VerifyTicketBeforeSaveResponse>(observer => {
            //  This also returns the Affected Service Areas for us.  So if we have not already geocoded (and if the map
            //  is displayed, we should have), do that first.
            this.GeocodeTicketDigSiteIfChanged()
                .subscribe(() => {
                    if (!this.valid) {
                        //  The form can become invalid if the geocode returned validation errors.  Can reproduce that by entering an
                        //  intersection, letting it geocode, wipe out the cross street and then immediately click save (with focus staying
                        //  in the cross street field).  Since the geocode hasn't happened when the save process starts, we don't get the
                        //  validation error until we do the geocode.
                        observer.next(null);
                        observer.complete();
                        return;
                    }

                    const ticket: Ticket = this.getRawValue();
                    this.TicketService.VerifyTicketBeforeSave(ticket)
                        .subscribe(response => {
                            observer.next(response);
                            observer.complete();
                        },
                        err => {
                            observer.error(err);
                            observer.complete();
                        });
                },
                err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public FindAffectedServiceAreas(): Observable<TicketServiceArea[]> {
        return new Observable<TicketServiceArea[]>(observer => {

            //  Must geocode first in case it hasn't been done or something has changed
            this.GeocodeTicketDigSiteIfChanged()
                .subscribe(geocodeResult => {
                    const ticketTypeID = this.get("TicketTypeID").value;
                    const locateTypeID = this.get("LocateTypeID").value;
                    const nearEdgeOfRoad = this.get("DigSite.NearEdgeOfRoad").value;
                    const ticketNumber = this.get("TicketNumber").value;
                    const ticketID = this.get("ID").value;      //  Will be Guid.Empty (or maybe null) for a new ticket or will be the Ticket.ID that we are updating

                    //  If no geocode, still need to call FindAffectedServiceAreas.  It will see it's empty and set the ServiceAreasAreDirty
                    //  flag to false to avoid repeatedly calls.
                    const geometryJson = geocodeResult ? geocodeResult.GeometryJson : null;

                    this.TicketService.FindAffectedServiceAreas(geometryJson, ticketTypeID, locateTypeID, nearEdgeOfRoad, ticketNumber, ticketID)
                        .subscribe(serviceAreas => {
                            observer.next(serviceAreas);
                            observer.complete();
                        },
                            err => {
                                observer.error(err);
                                observer.complete();
                            });
                },
                err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    private SetServiceAreaListDirty(): void {
        //  Something changed that requires us to recalculate the affected service area list.  Mark the list dirty
        //  so that they next time we need it, it will be re-calculated.
        if (this.EditingTicket.value)
            this.TicketService.ServiceAreasAreDirty.next(true);
    }
}
