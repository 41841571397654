import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { SettingsService } from 'Services/SettingsService';
import { AuthenticationService } from 'Services/AuthenticationService';

import { AppUser } from 'Models/Security/AppUser.model';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * Collection of services we tend to need all over the place.  Makes it much easier to get them injected and
 * pass them down to base classes.
 * Don't go overboard here!  Only include what we really need all the time!
 *
 * To use this service, list it in the "providers" section of a @Component and then add it to that components
 * constructor.  By listing it in the @Components providers, an instance will be created for THAT component
 * as opposed to creating a single instance for the entire application.  That is necessary because some of the
 * classes we are injecting here are not actually services and must be scoped to the component.
 * See: https://blog.thoughtram.io/angular/2015/05/18/dependency-injection-in-angular-2.html#transient-dependencies-and-child-injectors
 */
@Injectable()
export class CommonService {

    constructor(public HttpClient: HttpClient, public SettingsService: SettingsService,
        public ActivatedRoute: ActivatedRoute, public FormBuilder: FormBuilder, public Location: Location,
        public Dialog: MatDialog, public ToastrService: ToastrService,
        public AuthenticationService: AuthenticationService, private _DeviceService: DeviceDetectorService) {
    }

    public get CurrentUser(): AppUser {
        return this.AuthenticationService.CurrentUser;
    }

    private _IsPhone: boolean = null;
    public get IsPhone(): boolean {
        //  Cached because don't know how much work is done in that isMobile() function.  So if we we want to
        //  put this in an .html template, need to make sure it's efficient and not doing a ton of work
        //  on every "check for changes".
        if (this._IsPhone === null)
            this._IsPhone = this._DeviceService.isMobile();
        return this._IsPhone;
    }
}
