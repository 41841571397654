import { Component, Input, ViewChild } from '@angular/core';
import { AuthComponentBase } from '../../auth-component-base';
import { FormGroup } from '@angular/forms';
import { ViewStateEnum, PasswordRequirements } from '../../models';
import { take, finalize } from 'rxjs/operators';
import { IqAwsCognitoService } from '../../Services/iq-aws-cognito.service';
import { iQCreateLoginSharedComponent } from '../../SharedControls/create-login/create-login.component';


@Component({
  selector: 'iq-aws-cognito-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
  host: { '[class.iq-aws-cognito-sign-up]': 'true' }
})
export class iQSignUpComponent extends AuthComponentBase {

  private _passwordRequirements: PasswordRequirements;
  @Input() set PasswordRequirements(val: PasswordRequirements) {
    let defaultRequirements = new PasswordRequirements();
    this._passwordRequirements = { ...defaultRequirements, ...val };
  }
  get PasswordRequirements(){
    return this._passwordRequirements;
  }

  groupValid: boolean = false;

  @ViewChild(iQCreateLoginSharedComponent, { read: iQCreateLoginSharedComponent }) loginGroup: iQCreateLoginSharedComponent;

  ShouldDisplay(): boolean {
    return this.authState.state == ViewStateEnum.createNewUser;
  }

  BuildForm(): FormGroup {
    return null;
  }

  constructor(protected awsCognitoService: IqAwsCognitoService) {
    super(awsCognitoService);

  }
  
  onValueIsValid(isValid: boolean) {
    this.groupValid = isValid;  
  }

  onReturnToLogin() {
    this.awsCognitoService.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
  }

  onSignUp() {
    let user = this.loginGroup.GetValue();
    
    if (user == null){
      return;
    }

    this.isBusy = true;
    
    this.awsCognitoService.CreateNewUser(user).pipe(take(1), finalize(() => this.isBusy = false))
      .subscribe(null, err => this.SetError(err));
  }
}
