import { Component, Input } from '@angular/core';

@Component({
    selector: 'iq-phone-back-button',
    templateUrl: './PhoneBackButton.component.html',
    styleUrls: ['./PhoneBackButton.component.scss']
})
export class PhoneBackButtonComponent {

    @Input() disabled: boolean;

    constructor() {
    }
}
