import { Directive, ElementRef, forwardRef, Input, EventEmitter, Output, Injector, OnInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

export class TextareaInputLimiterConformResult {
    constructor(
        public ConformedValue: string,
        public NewCursorPosition: number,
        public NumLines: number,
        public Truncated: boolean,
        public InputHint: string)
    { }
}

/**
 *  Put this directive on a textarea control (and set, at a minimum the MaxColumns value) to limit
 *  the input to a specific number of characters per column and (optionally) max number of rows.
 *  Supports a regular ngModel or FormControl (via ControlValueAccessor).  If another ControlValueAccessor
 *  can also manually call the ConformValue method from an event handler in another control.
 * */
@Directive({
    selector: '[iqInputLimiter]',
    host: {
        '(input)': 'OnInputEvent($event)'
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextareaInputLimiterDirective),
            multi: true,
        }
    ]
})
export class TextareaInputLimiterDirective implements ControlValueAccessor, OnInit, OnDestroy {

    private _TextAreaElement: HTMLTextAreaElement;

    /**
     *  The maximum number of characters per line.  Must be set or not limiting will be done.
     */
    @Input("MaxColumns")
    public MaxColumns: number;

    /**
     *  The maximum number of rows.  If <= 0, rows will not be limited.
     */
    @Input("MaxRows")
    public MaxRows: number;

    @Output("InputHint")
    public InputHint: EventEmitter<string> = new EventEmitter<string>();

    constructor(elementRef: ElementRef<HTMLElement>, private injector: Injector) {
        this._TextAreaElement = elementRef.nativeElement as HTMLTextAreaElement;
    }

    public ngOnDestroy(): void {
        this._TextAreaElement = null;
    }

    ngOnInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            const formControl = ngControl.control as FormControl;
            if (formControl && formControl.value) {
                const val = formControl.value as string;
                this._TextAreaElement.value = val;
                this._TextAreaElement.setSelectionRange(val.length, val.length);
                this.OnInputEvent(null);
            }
        }
    }

    private OnInputEvent(event: InputEvent) {
        //  This will truncate any additional lines.  If we want to prevent entering a keystroke,
        //  would need to do ConformInput in the keydown event for printable characters characters only
        //  (like we do in TicketFieldDirective).
        const result = TextareaInputLimiterDirective.ConformInput(this._TextAreaElement.value, this._TextAreaElement.selectionStart, this.MaxColumns, this.MaxRows);
        this.SetValue(result.ConformedValue, result.NewCursorPosition);
        this.InputHint.next(result.InputHint);
    }

    private _WriteToForm: (value: any) => void;
    registerOnChange(fn: (value: any) => void) {
        this._WriteToForm = fn;
    }

    registerOnTouched(fn: any) {
    }

    writeValue(value: any) {
    }

    public static ConformInput(value: string, cursorPositionOnInput: number, charsPerLine: number, maxRows: number = 0): TextareaInputLimiterConformResult {
        if (charsPerLine <= 0)
            return new TextareaInputLimiterConformResult(value, cursorPositionOnInput, 0, false, null);

        let lines = value.split('\n');

        let charsProcessed = 0;
        for (let i = 0; i < lines.length; i++) {
            const currLine = lines[i];
            if (currLine.length > charsPerLine) {

                //  See if we can break the line at a space.
                let breakAtPos = charsPerLine - 1;
                for (let j = charsPerLine - 1; j >= 0; j--) {
                    if (currLine.charAt(j) === ' ') {
                        breakAtPos = j;
                        break;
                    }
                }

                lines[i + 1] = currLine.substring(breakAtPos + 1) + (lines[i + 1] || "");
                lines[i] = currLine.substring(0, breakAtPos + 1);

                if (cursorPositionOnInput > (charsProcessed + breakAtPos + 1))
                    cursorPositionOnInput += 1;        //  Account for the newline that was inserted before the current cursor position
            }

            charsProcessed += currLine.length;
        }

        const truncated = (maxRows && (lines.length > maxRows));
        if (maxRows)
            lines = lines.slice(0, maxRows);
        const inputHint = this.BuildInputHint(lines, charsPerLine, maxRows);
        return new TextareaInputLimiterConformResult(lines.join('\n'), cursorPositionOnInput, lines.length, truncated, inputHint);
    }

    private static BuildInputHint(lines: string[], charsPerLine: number, maxRows: number): string {
        if (maxRows <= 0)
            return null;        //  No hint necessary if rows are not limited - can enter as much as you want and will just auto-wrap.

        let remaining: number;
        let kind: string;
        if (lines.length < maxRows) {
            remaining = maxRows - lines.length;
            kind = "line";
        } else {
            //  When max rows reached, show number of chars remaining on the last line.
            //  Still may be possible to enter more characters in lines before (depending on line breaks) but
            //  don't know any better way to show all of those possibilities without over complicating it.
            remaining = charsPerLine - lines[maxRows - 1].length;
            kind = "character";
        }

        const hint = 'max ' + maxRows + ' lines of ' + charsPerLine + ' characters: '
                        + remaining + ' ' + kind + ((remaining === 1) ? "" : "s") + " remaining";
        return hint;
    }

    private SetValue(value: string, caretPos: number): void {
        if (this._WriteToForm)
            this._WriteToForm(value);
        this._TextAreaElement.value = value;

        //  Must get & restore the caret position or it will be set to the end of the input
        if ((caretPos >= 0) && this._TextAreaElement.setSelectionRange)
            this._TextAreaElement.setSelectionRange(caretPos, caretPos);
    }
}
