import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { DrawingToolBase } from "Shared/Components/Maps/Tools/DrawingToolBase";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { MapFeatureStyleEnum } from "Enums/MapFeatureStyle.enum";
import Map from 'ol/Map';
import { Draw, Interaction } from "ol/interaction";
import GeometryType from "ol/geom/GeometryType";
import { Collection } from "ol";
import { Circle } from "ol/geom";
import { distance as coordinate_distance } from "ol/coordinate";
import { DigSiteSizeControl } from "../Controls/DigSiteSizeControl";

export class DrawCircleTool extends DrawingToolBase {

    private _NumPoints: number = 0;

    constructor(map: Map, mapToolService: MapToolService, private _VectorLayer: VectorLayerBase) {
        super(map, mapToolService);

        this.Init();
    }

    public OnDestroy(): any {
        this._VectorLayer = null;
        return super.OnDestroy();
    }

    protected CreateInteraction(): Interaction {
        const sizeControl = DigSiteSizeControl.FindSelfInMap(this.Map);

        //  ol.interaction.Draw: https://openlayers.org/en/latest/apidoc/module-ol_interaction_Draw.html
        const interaction = new Draw({
            features: new Collection(),      //  We draw into a collection so that we can pre-process when drawing is finished before adding to the _VectorLayer.
            type: (GeometryType.CIRCLE as any),     //  Bug in TypeScript or OpenLayers jsdocs does not recognize the type correctly
            style: (feature, resolution) => {
                if (sizeControl)
                    sizeControl.ShowSizeOfFeatureBeingDrawn(feature);
                return this._VectorLayer.BuildStyleForFeature(feature, true, MapFeatureStyleEnum.Circle, resolution);
            },
            geometryFunction: (coordinates: number[][], geometry) => {
                this._NumPoints = 2;      //  This will always contain 2 points - setting this changes the tooltip.

                //  (more or less) Reproduced from source: https://github.com/openlayers/openlayers/blob/master/src/ol/interaction/Draw.js
                //  It's not exposed in a way that we can call it...
                let circle = geometry as Circle;
                if (!circle)
                    circle = new Circle([NaN, NaN]);
                const radius = coordinate_distance(coordinates[0], coordinates[1]);
                circle.setCenterAndRadius(coordinates[0], radius);

                //  Validate the size of the circle against the configured buffer - prevents it from being too small.
                this.ValidateDrawnGeometry(circle);

                return circle;
            }
        });

        this.AddListener(interaction.on("drawend", (evt: any /* DrawEvent not exported! */) => {
            this._NumPoints = 0;

            //  Clean the drawing and then add to the source.  This fixes self-intersecting polygons and breaks
            //  them up into multiple features if necessary.  Also applies any extra validation needed (minimum size?).
            this._VectorLayer.CleanAndAddFeature(evt.feature, false, !this.MapToolService.AllowMultipleShapes);

            setTimeout(() => this.MapToolService.ActivateTool.next(MapConstants.TOOL_EDIT_GEOMETRY));
        }));

        return interaction;
    }

    protected OnPointerMove(evt: any): void {
        if (evt.dragging) {
            return;
        }

        let helpMsg = 'Click to start drawing';
        if (this._NumPoints > 0)
            helpMsg = 'Click to end drawing';

        this.SetHelpMessage(helpMsg, evt.coordinate);
    }
}
