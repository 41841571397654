import { MapLayer } from "Models/Configuration/Maps/MapLayer.model";
import { Feature } from "ol";
import { MVT } from "ol/format";
import { VectorTile as ol_layer_VectorTile } from "ol/layer";
import Map from 'ol/Map';
import RenderFeature from "ol/render/Feature";
import { VectorTile as ol_source_VectorTile } from "ol/source";
import { Fill, Icon, Stroke, Style, Text } from "ol/style";

export class MapFeaturesTileLayer {

    private _Source: ol_source_VectorTile;
    private _Layer: ol_layer_VectorTile;

    constructor(private _Map: Map, apiBaseUrl: string, oneCallCenterCode, public MapLayer: MapLayer) {
        const mapFeatureTileURL = apiBaseUrl + "/Maps/Tiles/MapFeatures/" + oneCallCenterCode + "/" + MapLayer.ID + "/{z}/{x}/{y}";

        this.CreateLayer(mapFeatureTileURL);
    }

    public OnDestroy(): void {
        this._Map = null;
    }

    private CreateLayer(mapFeatureTileURL: string): void {

        //  ** Note that the VectorTile source has a grid size of 512 pixels.  Where the image based XYZ source
        //  is 256.  This results in the vector tiles being 1 zoom level less than the zoom level of the image tiles.
        //  The resolutions we calculate are from the "map" - not the source - which seems to match up with the
        //  image source resolution.  So when relating vector tile zoom levels to resolutions, we need to add 1.

        const minZoom = this.MapLayer.MapFeatureTypes.reduce((prev, cur) => prev.DisplayZoom < cur.DisplayZoom ? prev : cur).DisplayZoom;

        this._Source = new ol_source_VectorTile({
            format: new MVT(),
            url: mapFeatureTileURL,
        });
        this._Layer = new ol_layer_VectorTile({
            maxResolution: this._Map.getView().getResolutionForZoom(minZoom),
            minResolution: this._Map.getView().getResolutionForZoom(20 + 1),
            declutter: true,
            source: this._Source,
            style: (feature, resolution) => this.BuildStyleForFeature(feature, resolution)
        });

        if (!this.MapLayer.IsInitiallyVisible)
            this._Layer.setVisible(false);

        //  For LayerSwitcher - and only if allowed to toggle visibility
        if (this.MapLayer.CanToggleVisibility) {
            this._Layer.set("title", this.MapLayer.Name);
            this._Layer.set("displayInLayerSwitcher", true);
        }

        this._Map.addLayer(this._Layer);
    }

    public Refresh(): void {
        if (this._Source)
            this._Source.refresh();
    }

    public BuildStyleForFeature(feature: Feature<any> | RenderFeature, resolution: number): Style | Style[] {
        const cachedStyle = this.GetStyle(feature.get("MapFeatureTypeID"));
        if (!cachedStyle)
            return null;

        if (resolution > cachedStyle.DisplayResolution)
            return null;                                //  Not visible;

        let labelStyle = cachedStyle.LabelStyle;
        if (labelStyle) {
            if (resolution > cachedStyle.LabelResolution)
                labelStyle = null;
            else
                labelStyle.getText().setText(feature.get('Name'));
        }

        const styles: Style[] = [cachedStyle.FeatureStyle];
        if (labelStyle)
            styles.push(labelStyle);

        return styles;
    }

    private _CachedStyles: { [mapFeatureTypeID: string]: CachedStyle } = {};

    private GetStyle(mapFeatureTypeID: string): CachedStyle {
        let cachedStyle = this._CachedStyles[mapFeatureTypeID];
        if (cachedStyle)
            return cachedStyle;

        const mapStyle = this.MapLayer.MapFeatureTypes.find(f => f.ID === mapFeatureTypeID);
        if (!mapStyle || !mapStyle.DisplayStyle || mapStyle.DisplayStyle === '') {
            this._CachedStyles[mapFeatureTypeID] = null;
            return null;
        }

        const displayStyleOptions = JSON.parse(mapStyle.DisplayStyle);
        const labelStyleOptions = (mapStyle.LabelStyle && mapStyle.LabelStyle.length > 0) ? JSON.parse(mapStyle.LabelStyle) : null;

        const featureStyle = new Style();
        if (displayStyleOptions.fill)
            featureStyle.setFill(new Fill(displayStyleOptions.fill));
        if (displayStyleOptions.stroke)
            featureStyle.setStroke(new Stroke(displayStyleOptions.stroke));
        if (displayStyleOptions.text) {
            const text = new Text(displayStyleOptions.text);
            if (displayStyleOptions.text.fill)
                text.setFill(new Fill(displayStyleOptions.text.fill));
            if (displayStyleOptions.text.stroke)
                text.setStroke(new Stroke(displayStyleOptions.text.stroke));
            featureStyle.setText(text);
        }
        if (displayStyleOptions.icon)
            featureStyle.setImage(new Icon(displayStyleOptions.icon));

        let labelStyle: Style = null;
        if (labelStyleOptions && (mapStyle.LabelZoom > 0)) {
            const text = new Text(labelStyleOptions);
            if (labelStyleOptions.fill)
                text.setFill(new Fill(labelStyleOptions.fill));
            if (labelStyleOptions.stroke)
                text.setStroke(new Stroke(labelStyleOptions.stroke));
            labelStyle = new Style();
            labelStyle.setText(text);
        }

        const displayResolution = this._Map.getView().getResolutionForZoom(mapStyle.DisplayZoom);
        const labelResolution = labelStyle ? this._Map.getView().getResolutionForZoom(mapStyle.LabelZoom) : null;

        cachedStyle = new CachedStyle(featureStyle, labelStyle, displayResolution, labelResolution);
        this._CachedStyles[mapFeatureTypeID] = cachedStyle;
        return cachedStyle;
    }
}

class CachedStyle {
    constructor(public FeatureStyle: Style, public LabelStyle: Style, public DisplayResolution: number, public LabelResolution: number) { }
}
