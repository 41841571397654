import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentLookup } from 'Services/ComponentLookup.service';
import { DateWithPickerComponent } from 'Shared/Components/Forms/FormControls/DateWithPickerComponent';
import { ICustomResponseDataComponent } from "../ICustomResponseDataComponent";

@ComponentLookup('SC811-Response-Code-110')
@Component({
    templateUrl: './SC811_ResponseCode110_ExtraordinaryCircumstances.component.html',
    styleUrls: ['./SC811_ResponseCode110_ExtraordinaryCircumstances.component.scss']
})
export class SC811_ResponseCode110_ExtraordinaryCircumstancesComponent implements OnInit, ICustomResponseDataComponent {

    @ViewChild('markedOnDatePicker', { read: DateWithPickerComponent, static: true })
    private _MarkedOnDatePicker: DateWithPickerComponent;

    private _FormGroup: FormGroup;
    public MarkedOnDateFormControl: FormControl;
    public CircumstanceFormControl: FormControl;

    public MinDate: Date;
    public MaxDate: Date;

    constructor()
    { }

    public ngOnInit(): void {
        //  Default is tomorrow at 8am.
        const defaultDate = new Date();
        defaultDate.setDate(defaultDate.getDate() + 1);
        defaultDate.setHours(8, 0, 0, 0);

        //  Min is today @ midnight, Max is 10 days
        this.MinDate = new Date();
        this.MinDate.setHours(0, 0, 0);

        this.MaxDate = new Date();
        this.MaxDate.setDate(this.MaxDate.getDate() + 10);
        this.MaxDate.setHours(0, 0, 0);

        this.MarkedOnDateFormControl = new FormControl(defaultDate, [Validators.required]);
        this.CircumstanceFormControl = new FormControl(null, [Validators.required]);

        this._FormGroup = new FormGroup({
            MarkedOnDate: this.MarkedOnDateFormControl,
            Circumstance: this.CircumstanceFormControl
        });

        setTimeout(() => this._MarkedOnDatePicker.focus());
    }

    public IsValid(): boolean {
        return this._FormGroup?.valid ?? false;
    }

    public GetData(): object {
        return this._FormGroup.getRawValue();
    }
}
