import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { TicketDiscussion } from 'Models/Tickets/TicketDiscussion.model';
import { TicketDiscussionChildResponse } from 'Models/Tickets/TicketDiscussionChildResponse.model';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';

@Injectable({
    providedIn: 'root'
})
export class TicketDiscussionService extends CRUDBaseService<TicketDiscussion> {
    protected apiPath: string = "Ticket/Discussion";

    //Currently all the permissions are around just viewing the item because that is where they are entered.  The server needs to make sure they have the
    //  correct role type to do things.
    ViewPermission: PermissionsEnum = PermissionsEnum.TicketResponse_View;//Needs to be overriden and set to the proper permission
    EditPermission: PermissionsEnum = PermissionsEnum.TicketResponse_View;//Needs to be overriden and set to the proper permission
    CreatePermission: PermissionsEnum = PermissionsEnum.TicketResponse_View;//Needs to be overriden and set to the proper permission
    DeletePermission: PermissionsEnum = PermissionsEnum.TicketResponse_View;//Needs to be overriden and set to the proper permission
    CopyPermission: PermissionsEnum = null;//Needs to be overriden and set to the proper permission

    constructor(protected services: CRUDServices) {
        super(services);
    }

    public CreateExcavatorComment(insert: TicketDiscussion[]): Observable<TicketDiscussionChildResponse[]> {
        //  This returns updated text for the service areas current responses
        return this.services.http.post<TicketDiscussionChildResponse[]>(this.services.settingsService.ApiBaseUrl + "/" +
            this.apiPath + "/CreateExcavatorComment", insert);
    }
}
