import { NgModule } from '@angular/core';
import { CommonService } from 'Services/CommonService';
import { GlobalSharedModule } from 'Shared/Modules/GlobalShared.module';
import { AddPositiveResponseComponent } from "../AddPositiveResponse/AddPositiveResponse.component";
import { TicketResponseDiscussionListComponent } from '../Views/TicketResponseDiscussionList/TicketResponseDiscussionList.component';
import { SC811_ResponseCode60_MarkingScheduleComponent } from "../Custom/SC811/SC811_ResponseCode60_MarkingSchedule.component";
import { SC811_ResponseCode110_ExtraordinaryCircumstancesComponent } from "../Custom/SC811/SC811_ResponseCode110_ExtraordinaryCircumstances.component";


//  Components dynamically created via the @ComponentLookup() method for custom response prompting
const _DynamicComponents: any[] = [
    SC811_ResponseCode60_MarkingScheduleComponent, SC811_ResponseCode110_ExtraordinaryCircumstancesComponent
]

const _Components: any[] = [
    AddPositiveResponseComponent, TicketResponseDiscussionListComponent,
    ..._DynamicComponents
];

/*
 *  Contains Ticket Response components that are shared between desktop and phone
 */
@NgModule({
    imports: [
        GlobalSharedModule
    ],
    declarations: [
        ..._Components
    ],
    exports: [
        ..._Components
    ],
    providers: [
        CommonService,
        //  This is necessary to prevent tree-shaking from not including these dialogs in the production optimized build.
        //  Which will then cause ticket edits for those ticket functions to not work correct/use the default ticket entry form!
        ..._DynamicComponents
    ]
})
export class TicketResponseSharedModule { }
