import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { HidableButton } from "Shared/Components/Maps/Controls/HidableButton";
import { Feature, Collection, PluggableMap } from "ol";
import { Geometry } from 'ol/geom';
import { EventsKey } from "ol/events";
import { unByKey } from "ol/Observable";

export class UndoEditButton extends HidableButton {

    //private _FeatureHistory: Collection<Feature<any>[]> = new Collection();
    private _FeatureHistory: Collection<Geometry[]> = new Collection();
    private _IgnoreChanges: boolean = false;

    private _AddFeatureEventsKey: EventsKey;
    private _RemoveFeatureEventsKey: EventsKey;
    private _ClearEventsKey: EventsKey;

    constructor(private _MapToolService: MapToolService, private _VectorLayer: VectorLayerBase) {
        super({
            html: '<i class="fas fa-undo-alt"></i>',
            title: 'Undo',
            handleClick: () => this.OnClick()
        });

        //  Note: Can't catch the change notification because dragging a geometry or vertex fires a notification
        //  on every part of the drag - not when it's finished.  Those changes need to call Snapshot manually
        //  (which is done from EditGeometryTool).
        this._AddFeatureEventsKey = _VectorLayer.Layer.getSource().on("addfeature", () => this.Snapshot());
        this._RemoveFeatureEventsKey = _VectorLayer.Layer.getSource().on("removefeature", () => this.Snapshot());
        this._ClearEventsKey = _VectorLayer.Layer.getSource().on("clear", () => this.Clear());
    }

    public setMap(map?: PluggableMap): void {
        if (!map) {
            if (this._AddFeatureEventsKey) {
                unByKey(this._AddFeatureEventsKey);
                this._AddFeatureEventsKey = null;
            }
            if (this._RemoveFeatureEventsKey) {
                unByKey(this._RemoveFeatureEventsKey);
                this._RemoveFeatureEventsKey = null;
            }
            if (this._ClearEventsKey) {
                unByKey(this._ClearEventsKey);
                this._ClearEventsKey = null;
            }

            this._MapToolService = null;
            this._VectorLayer = null;
        }

        super.setMap(map);
    }

    public Clear(): void {
        if (this._IgnoreChanges)
            return;

        this._FeatureHistory.clear();
        this.SetHidden(true);
    }

    public Snapshot(): void {
        if (this._IgnoreChanges)
            return;

        //  Extract the geometry and clone it to new instances.  Extracting the open layers geometry
        //  like this (instead of reading/writing geojson - which was being done up until 1/16/2021) is necessary
        //  in order to preserve circles.
        const geomList = this._VectorLayer.Layer.getSource().getFeatures().map(f => f.getGeometry().clone());
        this._FeatureHistory.push(geomList);

        //  Only show if there is more than 1.  The first one is the initial feature.  Undoing that would be the
        //  same as the Cancel/Discard button in the DigsiteEditor - so let that button handle it (and confirm it).
        if (this._FeatureHistory.getLength() > 1)
            this.SetHidden(false);
    }

    private OnClick() {
        this._FeatureHistory.pop();

        const source = this._VectorLayer.Layer.getSource();
        if (this._FeatureHistory.getLength() === 0) {
            source.clear();
            this._MapToolService.CloseMapToolsExcept.next(null);        //  Resets tools since this is the same as doing a Cancel/Discard
        }
        else {
            //  Must ignore changes while we do this because the change events on the source will fire as we go!
            this._IgnoreChanges = true;
            source.clear();

            //  Create new features from the geometry.  Must also clone these geometries or if we undo and then edit
            //  again, it will be modifying the geometry we have cached.  So another undo will end up doing nothing.
            const geomList = this._FeatureHistory.item(this._FeatureHistory.getLength() - 1);
            const features = geomList.map(g => {
                const f = new Feature();
                f.setGeometry(g.clone());
                return f;
            });
            source.addFeatures(features);

            this._IgnoreChanges = false;
            if (this._FeatureHistory.getLength() <= 1)
                this.SetHidden(true);
        }
    }
}
