import { Directive, ElementRef, OnInit, Renderer2, Injector, Optional, Self, OnDestroy } from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';

//The reason this is called '-dontUse" is because it will set the form value.  So if we are subscribing to the
//  valueChange event on any control that uses this it will fire that event twice for every character entered,
//  which is not good.  Maybe it can be fixed by implementing ControlValueAccessor??...
//  ...But it is needed by the Political signs project because it listens on value change, but
//  the control used updates on blur to "not geocode one every keystroke".  Not sure how this doesn't cause that to
//  fire geocoding though, but I didn't want to break that, so here it stays
@Directive({
    //selector: 'input[UpperCase]',
    selector: '[UpperCaseChangeValue-dontUse]',
    host: {
        '(input)': 'OnInputEvent($event.target)',
    }
})
export class UpperCaseChangeValueTextDirective implements OnInit, OnDestroy {
    private _FormControl: FormControl;

    constructor(private ref: ElementRef, private renderer: Renderer2, private _Injector: Injector, @Optional() @Self() private _Control: NgControl) {
    }

    public ngOnDestroy(): void {
        this.ref = null;
        this._FormControl = null;
    }

    //This will get called when loaded and will set the initial value to uppercase
    ngOnInit() {
        //  This handles the visual display so the user will only ever see upper case.
        //  But the actual value will still be lower.  That is converted by the OnInputEvent() handler.
        this.renderer.setStyle(this.ref.nativeElement, 'text-transform', 'uppercase');

        if (this._Control)
            this._FormControl = this._Control.control as FormControl;
    }

    /**
     * This event is called AFTER the FormControls value has been changed.  There isn't an event that we can catch
     * before that happens.
     * @param targetElem
     */
    private OnInputEvent(targetElem: HTMLInputElement) {
        if (!targetElem.value)
            return;

        const upper = targetElem.value.iqToUppercase();

        if (this._FormControl && (targetElem.value !== upper)) {
            //  The form value gets updated before we can upcase it.  So best we can do is set it here after-the-fact.
            //  We probably don't need to emitEvents here but letting that happen just to be safe.
            //  Autocomplete searches should be case insensitive and are debounced so firing the events should not
            //  cause issues with those.  But don't know what something else may be doing in response to changes...
            //  Setting onlySelf should always be safe since the model (or another field) validity should never be influenced
            //  by just the case of a field.
            //  ** Must set emitModelToViewChange=false or characters do not display at all in some autocomplete fields!  Don't know
            //     what the difference is with the ones that don't work and those that do.  But saw it when linking an excavator
            //     contact to a person (on the "search people" field).
            this._FormControl.setValue(upper, { onlySelf: true, emitModelToViewChange: false});
        }

        const caretPos = targetElem.selectionStart;
        targetElem.value = upper;

        if (targetElem.setSelectionRange)
            targetElem.setSelectionRange(caretPos, caretPos);
    }
}

//Do it this way so that if we have to change it we can just change this directive
@Directive({
    //selector: 'input[UpperCase]',
    selector: '[UpperCase]',
})
export class UpperCaseTextDirective implements OnInit {
    constructor(private ref: ElementRef, private renderer: Renderer2) {
    }

    //This will get called when loaded and will set the initial value to uppercase
    ngOnInit() {
        this.renderer.setStyle(this.ref.nativeElement, 'text-transform', 'uppercase');
    }
}

