import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PhoneHeaderLeftDirective, PhoneHeaderComponent, PhoneHeaderCenterDirective, PhoneHeaderRightDirective } from './PhoneHeader.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PhoneMainMenuModule } from '../MainMenu/PhoneMainMenu.module';


@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        MatToolbarModule,
        PhoneMainMenuModule
    ],
    declarations: [
        PhoneHeaderComponent,
        PhoneHeaderLeftDirective,
        PhoneHeaderCenterDirective,
        PhoneHeaderRightDirective
    ],
    exports: [
        PhoneHeaderComponent,
        PhoneHeaderLeftDirective,
        PhoneHeaderCenterDirective,
        PhoneHeaderRightDirective
    ]

})
export class PhoneHeaderModule { }
