import { Component, Renderer2 } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'Services/AuthenticationService';
import { BusyService } from 'Services/BusyService';
import { SettingsService } from 'Services/SettingsService';
import { phoneRouteAnimations } from 'Shared/Utils/Animations';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        phoneRouteAnimations
    ]
})
export class AppComponent {

    //Animations for route navigation
    prepareRouteAnimation(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
    }

    private destroyed$: Subject<void> = new Subject();

    isPhoneSize: boolean = false;
    showDefaultHeader: boolean = false;
    isSignedIn = this.authService.UserIsSignedIn;
    /** If we aren't showing the login page, and they aren't signed in then we want to show a back button to bring them to the loging page */
    showingLoginPage: boolean = true;

    private fontClass: string;
    private clientClass: string;
    private paletteClass: string;
    fontSize: string;//Needed to show the checked one

    //Setup observable in the service and get the key to that observable back to start and end the indicator
    busyKey: string = this.busyService.createNew();

    constructor(
        private deviceService: DeviceDetectorService,
        private renderer: Renderer2,
        public settingsService: SettingsService,
        private router: Router,
        private busyService: BusyService,
        private authService: AuthenticationService
    ) {

        this.isPhoneSize = this.deviceService.isMobile();

        this.fontSize = localStorage.getItem('fontSize');

        this.fontClass = this.fontSize === 'small' ? 'iq-typography-small' : this.fontSize === 'large' ? 'iq-typography-large' : 'iq-typography-default';
        this.clientClass = 'client-fouriq';

        let paletteColor: string = localStorage.getItem('colorPalette');
        //  Changed the theme names to be more meaningful so migrate any that have been saved.  Can remove this eventually.

        try {
            switch (paletteColor) {
                case "a":
                    paletteColor = "green";
                    localStorage.setItem('colorPalette', paletteColor);
                    break;
                case "b":
                    paletteColor = "blue";
                    localStorage.setItem('colorPalette', paletteColor);
                    break;
                case "c":
                    paletteColor = "gray";
                    localStorage.setItem('colorPalette', paletteColor);
                    break;
            }
        } catch { }     //  Ignore error if local storage is full!

        this.paletteClass = 'color-palette-' + (!paletteColor ? this.settingsService.DefaultColorPalette : paletteColor);

        this.renderer.addClass(document.body, this.clientClass);
        this.renderer.addClass(document.body, this.fontClass);
        this.renderer.addClass(document.body, this.paletteClass);

        if (this.isPhoneSize) {
            this.renderer.addClass(document.body, 'iq-phone-view');

            this.showingLoginPage = this.router.url === '/login';

            //Used to determine which header to use (can deep link to non-phone optimized links like an error page).
            //  And also to show a busy spinner if the page is taking a long time to load on the phone(it needs to kind of act like an app)
            this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(val => {
                if (val instanceof NavigationEnd) {
                    this.busyService.hideByKey(this.busyKey);

                    this.showingLoginPage = this.router.url === '/login';

                    //This is only so that if they go to a page that isn't optimized for a phone they will be able to navigate.  If we want to block all pages on a phone that aren't optimized then this
                    //  can be removed.
                    const parts = val.url.split("/");
                    parts.shift();//Remove out the first one because it's always empty
                    this.showDefaultHeader = parts[0] !== "phone";
                }
                else if (val instanceof NavigationStart) {
                    this.busyService.showByKey(this.busyKey);
                }
                else if (val instanceof NavigationCancel) {//If they click a link that goes to the page they are currently on this will fire and we can stop showing the busy spinner                    
                    this.busyService.hideByKey(this.busyKey);
                }
            });
        
        }
    }
}
