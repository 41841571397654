import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginPageOptions } from 'Models/Configuration/LoginPageOptions.model';
import { NameIDEntity } from 'Models/Configuration/NameIDEntity.model';
import { County } from "Models/Maps/County.model";
import { ServiceAreaEmergencyContact } from "Models/ServiceAreas/ServiceAreaEmergencyContact.model";
import { ServiceAreaEmergencyContactsRequest } from "Models/ServiceAreas/ServiceAreaEmergencyContactsRequest.model";
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserRegistrationService } from 'Pages/UserRegistration/Services/UserRegistration.service';
import { take, takeUntil } from 'rxjs';
import { AuthenticationService } from 'Services/AuthenticationService';
import { MainMenuService } from 'Services/MainMenuService';
import { SettingsService } from 'Services/SettingsService';
import { AnonymousPageBase } from 'Shared/BaseClasses/AnonymousPageBase';

@Component({
    templateUrl: './EmergencyContacts.component.html',
    styleUrls: ['./EmergencyContacts.component.scss']
})
export class EmergencyContactsComponent extends AnonymousPageBase {

    public IsPhoneView: boolean = false;

    public OccCodeList: string[] = [];
    public StateList: string[] = [];
    public CountyList: County[] = [];
    public ContactTypeList: NameIDEntity[] = [];

    public SelectedOccCodeFormControl: FormControl;
    public SelectedStateFormControl: FormControl;
    public SelectedCountyFormControl: FormControl;
    public SelectedContactTypeFormControl: FormControl;

    public ContactList: ServiceAreaEmergencyContact[] = [];

    private _ShowEmergencyContactsPageOccCodes: { [occCode: string]: NameIDEntity[]; }
    private _HasMultipleStates: boolean = false;
    private _UsesCounty: boolean = true;

    constructor(mainMenuService: MainMenuService, authenticationService: AuthenticationService, deviceService: DeviceDetectorService,
        userRegService: UserRegistrationService, private _Http: HttpClient, private _SettingsService: SettingsService,
        private _Router: Router)
    {
        super(mainMenuService, authenticationService);

        this.IsPhoneView = deviceService.isMobile();

        this.SelectedOccCodeFormControl = new FormControl();
        this.SelectedOccCodeFormControl.valueChanges
            .pipe(takeUntil(this.Destroyed))
            .subscribe(occCode => this.OccCodeSelected(occCode));

        //  Don't currently have a situation with multiple states AND uses counties.  If/when we do, need to
        //  add handling for that here (to fetch the counties for only the selected state).
        //  Or just always fetch all counties and then filter in memory.
        this.SelectedStateFormControl = new FormControl();
        this.SelectedStateFormControl.valueChanges
            .pipe(takeUntil(this.Destroyed))
            .subscribe(() => this.FetchReportData());

        this.SelectedCountyFormControl = new FormControl();
        this.SelectedCountyFormControl.valueChanges
            .pipe(takeUntil(this.Destroyed))
            .subscribe(() => this.FetchReportData());

        this.SelectedContactTypeFormControl = new FormControl();
        this.SelectedContactTypeFormControl.valueChanges
            .pipe(takeUntil(this.Destroyed))
            .subscribe(() => this.FetchReportData());

        authenticationService.IsUserAuthorized.pipe(take(1))
            .subscribe(authorized => {
                if (authorized) {
                    //  If the user has a login, the contact types are included in the GetUserInfo.
                    authenticationService.CurrentUserObserver().pipe(take(1))
                        .subscribe(userInfo => {
                            if ((userInfo.EmergencyContactPageContactTypes?.length ?? 0) === 0) {
                                //  This should not happen but can on our dev site if we use the switch function to a One Call that
                                //  does not have this configured.  Just redirect to /home.
                                this._Router.navigateByUrl("/home");
                                return;
                            }

                            this._ShowEmergencyContactsPageOccCodes = {};
                            this._ShowEmergencyContactsPageOccCodes[userInfo.CurrentOneCallCenterCode] = userInfo.EmergencyContactPageContactTypes;
                            this.ConfigReceived();
                        });
                }
                else {
                    //  If anonymous, configs we need come from the UserRegistrationService
                    userRegService.LoginPageOptions().subscribe(options => {
                        this._ShowEmergencyContactsPageOccCodes = options.ShowEmergencyContactsPageOccCodes;
                        this.ConfigReceived();
                    });
                }
            });
    }

    private ConfigReceived(): void {
        this.OccCodeList = Object.keys(this._ShowEmergencyContactsPageOccCodes)
            .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
        if (this.OccCodeList.length === 1)
            this.SelectedOccCodeFormControl.setValue(this.OccCodeList[0]);
    }

    private OccCodeSelected(occCode: string): void {
        this.StateList = [];
        this.CountyList = [];
        this.ContactTypeList = this._ShowEmergencyContactsPageOccCodes[occCode];

        this.SelectedStateFormControl.setValue(null);
        this.SelectedCountyFormControl.setValue(null);

        if (this.ContactTypeList.length === 1)
            this.SelectedContactTypeFormControl.setValue(this.ContactTypeList[0].ID);
        else
            this.SelectedContactTypeFormControl.setValue(null);

        //  DigSafe has multiple states and does not use counties.
        //  All others have a single state and use counties.
        this._HasMultipleStates = SettingsService.HasMultipleStates(occCode);
        this._UsesCounty = SettingsService.UsesCountyInLocations(occCode);

        if (this._HasMultipleStates) {
            this.FetchStates();
        } else if (this._UsesCounty)
            this.FetchCounties();
    }

    private FetchStates(): void {
        this.StateList = [];
        this.CountyList = [];

        const url = this._SettingsService.ApiBaseUrl + "/Maps/State/GetStates/" + this.SelectedOccCodeFormControl.value;
        this._Http.get<string[]>(url)
            .subscribe(stateList => {
                this.StateList = stateList;
                if (this._UsesCounty)
                    this.FetchCounties();
            });
    }

    private FetchCounties(): void {
        this.CountyList = [];

        const url = this._SettingsService.ApiBaseUrl + "/Maps/County/List/" + this.SelectedOccCodeFormControl.value;
        this._Http.get<County[]>(url)
            .subscribe(countyList => this.CountyList = countyList);
    }

    private FetchReportData(): void {
        if (!this.CanRunReport()) {
            this.ContactList = [];
            return;
        }

        //  TODO: Support multiple?
        const contactTypeIDList = [this.SelectedContactTypeFormControl.value];

        const url = this._SettingsService.ApiBaseUrl + "/PersonServiceArea/EmergencyContacts";
        const request = new ServiceAreaEmergencyContactsRequest(this.SelectedOccCodeFormControl.value, this.SelectedStateFormControl.value, this.SelectedCountyFormControl.value, contactTypeIDList);

        this._Http.post<ServiceAreaEmergencyContact[]>(url, request)
            .subscribe(contactList => this.ContactList = contactList);
    }

    private CanRunReport(): boolean {
        if (!this.SelectedOccCodeFormControl.value)
            return false;
        if (this._HasMultipleStates && !this.SelectedStateFormControl.value)
            return false;
        if (this._UsesCounty && !this.SelectedCountyFormControl.value)
            return false;
        if (!this.SelectedContactTypeFormControl.value)
            return false;

        return true;
    }
}
