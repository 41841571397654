import { EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, NgControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IQ_VALIDATOR_PATTERNS } from '@iqValidation/ValidationPatterns.model';
import { EntityEnum } from 'Enums/EntityType.enum';
import { FieldUIControlTypeEnum } from 'Enums/FieldUIControlType.enum';
import { EntryFieldValidationErrorSourceEnum } from "Enums/EntryFieldValidationErrorSource.enum";
import { EntryFieldConfigurationResponse } from "Models/EntryFields/EntryFieldConfigurationResponse.model";
import { EntryFieldValidationError } from "Models/EntryFields/EntryFieldValidationError.model";
import { Observable, of } from 'rxjs';
import { IAutoCompleteSearchService } from 'Services/AutoCompleteSearch.service';
import { CommonService } from 'Services/CommonService';
import { TextMaskIsValid } from 'Shared/Components/Forms/Validation/Validators/TextMaskIsValid.validator';
import { EntryFormGroupBase } from './EntryFormGroupBase';

export class EntryFormControl extends FormControl {
    get FieldConfiguration(): EntryFieldConfigurationResponse {
        return this._FieldConfig;
    }

    get EntryFormGroup(): EntryFormGroupBase {
        return this.root as EntryFormGroupBase;
    }

    //  If this control is wired up to an AutoCompleteSearch, this is the instance of that autocomplete.
    //  Set by AutoCompleteSearch.Init().  Used by EntryFieldDirective to send input/blur/focus events.
    public Autocomplete: IAutoCompleteSearchService;

    public FieldIsEnabled(): boolean {
        //  If don't have IsEditing, then the FormGroup/root of this control is not an EntryFormGroup.
        //  Which is normal for some dialogs.  So just ignore that and go by the ReadOnly flag.
        if (this.EntryFormGroup.IsEditing && !this.EntryFormGroup.IsEditing.value)
            return false;

        return !this._FieldConfig.ReadOnly;
    }

    //  Change to dictionary?
    private _ValidationErrors: EntryFieldValidationError[] = null;

    /**
     * Sets a validation error on the control.
     * @param source
     * @param message
     * @param isError
     * @param emitEvent
     */
    public SetValidationError(source: EntryFieldValidationErrorSourceEnum, message: string, isError: boolean = true, emitEvent: boolean = false): void {
        if (!this._ValidationErrors)
            this._ValidationErrors = [];

        //  (At least currently), only allowing 1 validation error of each "source" so remove an existing one.
        this._ValidationErrors = this._ValidationErrors.filter(e => e.Source !== source);

        this._ValidationErrors.push(new EntryFieldValidationError(source, message, isError));

        this.UpdateValidityAfterValidationChange(emitEvent);
    }

    /**
     * Returns the validation error for the given source or null if there is no validation error.
     * @param source
     */
    public GetValidationErrorOfSource(source: EntryFieldValidationErrorSourceEnum): EntryFieldValidationError {
        if (!this._ValidationErrors)
            return null;
        return this._ValidationErrors.find(e => e.Source === source);
    }

    /*
     *  Returns the Validation Errors that are actually errors - not warnings.
     */
    public GetValidationErrors(): EntryFieldValidationError[] {
        if (!this._ValidationErrors)
            return null;
        return this._ValidationErrors.filter(e => e.IsError);
    }

    /*
     *  Returns the Validation Errors that are actually warnings - not errors.
     */
    public GetValidationWarning(): EntryFieldValidationError {
        if (!this._ValidationErrors)
            return null;
        return this._ValidationErrors.find(e => !e.IsError);
    }

    /*
     *  Clear all validation errors from the control.
     */
    public ClearAllValidationErrors(emitEvent: boolean = false): void {
        if (!this._ValidationErrors || this._ValidationErrors.length === 0)
            return;

        this._ValidationErrors = null;

        this.UpdateValidityAfterValidationChange(emitEvent);
    }

    /**
     * Clear all validation errors from the control for the given source.
     */
    public ClearValidationErrorOfSource(source: EntryFieldValidationErrorSourceEnum, emitEvent: boolean = false): void {
        if (!this._ValidationErrors)
            return;

        const numErrors = this._ValidationErrors.length;
        this._ValidationErrors = this._ValidationErrors.filter(e => e.Source !== source);

        //  If no changes made, do not need to do anything about the validity
        if (numErrors !== this._ValidationErrors.length)
            this.UpdateValidityAfterValidationChange(emitEvent);
    }

    private UpdateValidityAfterValidationChange(emitEvent: boolean): void {
        //  Must call updateValueAndValidity to make the formControl re-evaluate the validation errors.
        //  Then need to manually trigger the EntryFieldWrapper to update itself (with any validation error changes).
        this.updateValueAndValidity({ onlySelf: false, emitEvent: emitEvent });     //  onlySelf must be false so that validity of entire model gets updated (otherwise, model root could think it's still valid!)
        this.CheckValid.next(true);
    }

    public readonly CheckValid: EventEmitter<boolean> = new EventEmitter();

    public AdditionalValidators?: ValidatorFn | ValidatorFn[] | null;

    constructor(private _FieldConfig: EntryFieldConfigurationResponse, initialValue: any,
        options: {
            additionalValidators?: ValidatorFn | ValidatorFn[];
            forceRequired?: boolean;
            forceUpdateOn?: string;
        } = {}) {

        super(initialValue, EntryFormControl.GetControlOptions(_FieldConfig, options.forceUpdateOn));

        this.AdditionalValidators = options.additionalValidators;

        this.UpdateValidators(options.forceRequired);
    }

    /**
     * Call this to re-create the validators on the form control if something has been changed (such as the Required flag)
     * that affects the validators.  These are added when the FormControl is created so changes to those properties
     * will not automatically rebuild the validators!
     * @param forceRequired
     */
    public UpdateValidators(forceRequired?: boolean): void {
        //  All controls get this validator in case the server returns a validation error or the web app manually
        //  adds a validation error.
        const validators: ValidatorFn[] = [EntryFormControl.ErrorValidator];

        //  todo: forceRequired is temporary just to give a way to force some dig site fields to be required
        //  (only on the 1st intersection - which we can't configure in the field config).
        //  Need to build a custom dig site validator that will take into account the type of dig site to make sure
        //  all of the correct fields are required.  Then will need to pass that validator in here which will allow
        //  us to configure other custom validators where needed.
        if ((this._FieldConfig && this._FieldConfig.Required) || forceRequired)
            validators.push(Validators.required);

        if (this._FieldConfig) {
            switch (this._FieldConfig.UIControlType) {
                case FieldUIControlTypeEnum.Email:
                    validators.push(Validators.pattern(IQ_VALIDATOR_PATTERNS.emailPattern));
                    break;
                case FieldUIControlTypeEnum.Phone:
                    validators.push(Validators.pattern(IQ_VALIDATOR_PATTERNS.phonePattern));
                    break;
                case FieldUIControlTypeEnum.TextMask:
                    validators.push(TextMaskIsValid(this._FieldConfig.TextMask));
            }
        }

        if (this.AdditionalValidators) {
            if (this.AdditionalValidators instanceof Array)
                this.AdditionalValidators.forEach(v => validators.push(v));
            else
                validators.push(this.AdditionalValidators);
        }

        this.setValidators(validators);
    }

    private static ErrorValidator(control: EntryFormControl): ValidationErrors | null {
        if (control) {
            const validationErrors = control.GetValidationErrors();
            if (validationErrors) {
                switch (validationErrors.length) {
                    case 0:
                        break;
                    case 1:
                        return { message: validationErrors[0].Message };
                    default:        //  multiple!
                        return { message: validationErrors.map(e => e.Message).join(", ") };
                }
            }
        }

        return null;
    }

    private static GetControlOptions(fieldConfig: EntryFieldConfigurationResponse, forceUpdateOn?: string): any {
        //  This allows overriding the default handling for the field for special cases
        if (forceUpdateOn)
            return { updateOn: forceUpdateOn };

        //  On 4/29/2020, changed to use "change" by default with "blur" being the exception only where needed.
        //  This was needed as of Angular 9 because when using "blur", our autocompletes did not work correctly.
        //  Typing something, picking a result, then tabbing would result in the control being set to what
        //  was typed - not what was picked.  Angular seemed to not update the FormControl at all.
        //  But everything seems to work fine like this and this is the default anyway.  Originally, it was
        //  using "blur" by default because when the autocompletes were initially built, I don't think I
        //  was implementing them correctly and that was the only way I could find to get them to work right.
        //  ** On 8/10/2020: Had to set a couple individual fields (in EntryFormGroup) to blur that use the
        //  AutoCompleteSearch.service (EnteredStreetAddress for streets and the CountyName and PlaceName for intersections).
        //  Changes to them were firing on every keystroke which then triggered a geocode.  We only want geocodes on blur....
        //  That autocomplete works fine using blur.  I believe the original problem on 4/29 was when using
        //  Angular's standard autocomplete on some fields that have FieldValues defined.
        if (fieldConfig) {
            switch (fieldConfig.UIControlType) {
                //  These have special keyboard event handling in EntryField.directive so they still need to
                //  be "blur".  Or we need to make changes there to make them work using "change".
                case FieldUIControlTypeEnum.Date:
                case FieldUIControlTypeEnum.Time:
                case FieldUIControlTypeEnum.DateTime:
                case FieldUIControlTypeEnum.Phone:
                case FieldUIControlTypeEnum.MultipleSelectAutocomplete:
                    return { updateOn: 'blur' };
            }
        }

        return { updateOn: 'change' };

        //  This is how it used to be before 4/29/2020 if we need to revert.
        ////  dropdowns, checkboxes, and text masks need to update on change
        //if (fieldConfig) {
        //    switch (fieldConfig.UIControlType) {
        //        case FieldUIControlTypeEnum.Dropdown:
        //        case FieldUIControlTypeEnum.Checkbox:
        //        case FieldUIControlTypeEnum.TextMask:
        //            return { updateOn: 'change' };
        //    }
        //}

        ////  Updating on "blur" so that we only trigger saves when the user leaves the field.
        ////  ** This is important because we need to do lookups and geocodes on the blur event so that we are not
        ////  doing those things on every single keystroke!
        ////  ** If we need an actual keystroke change event, the EntryFieldDirective has an emitter for that.

        ////  But this also prevents updating the validity of the field until then.
        ////  See here for example of a custom FormControl: https://material.angular.io/guide/creating-a-custom-form-field-control
        ////  Look for FocusMonitor - maybe that could be used?
        ////  Or a custom directive to detect focus changes (like here: https://material.angular.io/guide/creating-a-custom-form-field-control)
        //return { updateOn: 'blur' };
    }

    public static GetFieldConfigurationForControl(ngControl: NgControl): EntryFieldConfigurationResponse {
        if (!ngControl) {
            console.error("** ERROR: ngControl not set when looking for EntryFormControl");
            return null;
        }

        const formControl = ngControl.control as EntryFormControl;
        if (!formControl) {
            console.error("** ERROR: EntryFormControl not configured for control:", ngControl);
            return null;
        }

        const fieldConfig = formControl.FieldConfiguration;
        if (!fieldConfig) {
            //  If this happens, someone forgot to set this when creating the EntryFormControl or the server is not
            //  returning the field config for some reason!
            console.error("** ERROR: FieldConfiguration not set for control:", ngControl);
            return null;
        }

        return fieldConfig;
    }

    public GetFieldValues(commonService: CommonService): Observable<string[]> {
        if (!this.FieldConfiguration.CanConfigureValues) {
            //  Field does not have configurable FieldValues.  This is normal for the Ticket.Ancillary.Remarks field which is
            //  on the VerifyLocation & AffectedServiceAreas dialogs.  Not all One Calls configure values on that field.
            return of(null);
        }

        //  If DropdownItems is set, it contains the values already (it's set by the api when fetching a ticket for new/edit).
        if (this.FieldConfiguration.DropdownItems)
            return of(this.FieldConfiguration.DropdownItems.map(di => di.Name));

        return EntryFormControl.GetFieldValuesForProperty(this.FieldConfiguration.EntityType, this.FieldConfiguration.PropertyName, null, commonService);
    }

    /**
     * Returns the configured FieldValues for a property.
     * If rootFormGroup is given, we first try to find the property in that FormGroup to see if we can return
     * the FieldValues from existing DropdownItems.
     * @param entityType
     * @param propertyName
     * @param rootFormGroup
     * @param commonService
     */
    public static GetFieldValuesForProperty(entityType: EntityEnum, propertyName: string, rootFormGroup: AbstractControl, commonService: CommonService): Observable<string[]> {
        if (!entityType || !propertyName || propertyName === "")
            return of(null);

        if (rootFormGroup) {
            //  See if there is a FormControl with the propertyName.  If so and it contains DropdownItems, can return
            //  the values from there.  i.e. SC has a secondary work type field - this avoids an extra server call by fetching
            //  the values from the WorkType field that we already have.
            const formControl = rootFormGroup.get(propertyName);
            if (formControl instanceof EntryFormControl)
                return formControl.GetFieldValues(commonService);
        }

        const url = commonService.SettingsService.ApiBaseUrl + "/Config/FieldValues/ValuesForField/" + entityType.toString() + "/" + propertyName;
        return commonService.HttpClient.get<string[]>(url);
    }
}
