import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { GeometryTransformer } from "Shared/Components/Maps/GeometryTransformer";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { GeometryUtils } from "Shared/Components/Maps/GeometryUtils";
import * as _ from 'lodash';
import Map from 'ol/Map';
import { Overlay } from "ol";
import { Interaction } from "ol/interaction";
import { unByKey } from "ol/Observable";
import OverlayPositioning from "ol/OverlayPositioning";
import { Geometry, Circle as ol_geom_Circle, LineString } from "ol/geom";
import { DebouncedFunc } from "lodash";
import { Subject, takeUntil } from "rxjs";
import { EventsKey } from "ol/events";

/**
 *  Base class for a drawing tool.  Provides handling for a floating/overlay help message,
 *  interaction active state changes, and pointer movements.
 * */
export abstract class DrawingToolBase {

    //  The main interaction of the tool.  This controls if the tool is active or not.
    //  Can activate other interactions in response to the "change:active" event on this interaction.
    private _Interaction: Interaction;
    get Interaction(): Interaction {
        return this._Interaction;
    }

    private _ListenerEventKeys: EventsKey[] = [];

    //  Not handled using _ListenerEventKeys because need to turn it on/off
    private _PointerMoveEventKey: EventsKey = null;

    private _HelpTooltipElement: HTMLDivElement;
    private _HelpTooltipOverlay: Overlay;

    private _MouseOutHandler: () => void;

    protected Destroyed: Subject<void> = new Subject();

    constructor(protected Map: Map, protected MapToolService: MapToolService) {
    }

    //  Returns null so can also simplifier dereferencing as: this._Tool = this._Tool.OnDestroy();
    public OnDestroy(): any {
        this.Destroyed.next();
        this.Destroyed.complete();

        if (this._MouseOutHandler && this.Map) {
            this.Map.getViewport().removeEventListener("mouseout", this._MouseOutHandler);
            this._MouseOutHandler = null;
        }

        this._ListenerEventKeys.forEach(eventKey => unByKey(eventKey));
        this._ListenerEventKeys = [];
        if (this._PointerMoveEventKey)
            unByKey(this._PointerMoveEventKey);     //  Unregisters event (no matter what it is attached to): https://gis.stackexchange.com/questions/241487/how-to-unlisten-an-event-in-openlayers-4

        if (this._HelpTooltipOverlay)
            this.Map.removeOverlay(this._HelpTooltipOverlay);
        this._HelpTooltipElement = null;
        this._HelpTooltipOverlay = null;

        this._Interaction.setMap(null);
        this._Interaction = null;

        this._DebouncedSetHelpMessage.cancel();     //  Necessary or lodash holds reference to this instance and prevents gc
        this._DebouncedMoveHelpMessage.cancel();

        this.Map = null;
    }

    /**
     *  Derived class must call this from it's constructor.  Can't do it from the constructor in this base
     *  because the derived class may need properties in it's own constructor (like a VectorLayer) in order
     *  to create the intersection(s).
     * */
    protected Init(): void {
        this._Interaction = this.CreateInteraction();
        this.ConfigureInteraction();
        this.CreateHelpTooltip();

        this.MapToolService.CloseMapToolsExcept
            .pipe(takeUntil(this.Destroyed))
            .subscribe(tool => {
            if ((tool !== this) && this.Interaction.getActive())
                this.Interaction.setActive(false);
        });
    }

    protected AddListener(eventKey: EventsKey): void {
        this._ListenerEventKeys.push(eventKey);
    }

    public IsActive(): boolean {
        return this._Interaction && this._Interaction.getActive();
    }

    protected abstract CreateInteraction(): Interaction;

    private ConfigureInteraction(): void {
        this.AddListener(this._Interaction.on("change:active", (evt) => this.OnActiveStateChanged(!(evt as any).oldValue)));        //  TODO: Don't know what type this is...
    }

    protected OnActiveStateChanged(isActive: boolean): void {
        if (this._PointerMoveEventKey) {
            unByKey(this._PointerMoveEventKey);     //  Unregisters event (no matter what it is attached to): https://gis.stackexchange.com/questions/241487/how-to-unlisten-an-event-in-openlayers-4
            this._PointerMoveEventKey = null;
        }

        if (isActive) {
            this._PointerMoveEventKey = this.Map.on('pointermove', evt => this.OnPointerMove(evt));
            this.MapToolService.CloseMapToolsExcept.next(this);
        }
        else {
            this._HelpTooltipElement.classList.add('hidden');

            //  Must reposition the overlay to the top,left or it will be stuck where it was.  If you then zoom out enough, it's
            //  still stuck in the same x,y pixel position so it can cause the map view to GROW!  Overflow is hidden so you don't
            //  see it (unless you manually change it).  When overflow is activated, it jacks up the mouse wheel zoom in/out
            //  because the browser then wants to scroll the overflow.
            this._HelpTooltipOverlay.setPosition([0, 0]);
        }
    }

    private CreateHelpTooltip(): void {
        this._HelpTooltipElement = document.createElement('div');
        this._HelpTooltipElement.className = 'iq-ol-draw-tooltip hidden';

        this._HelpTooltipOverlay = new Overlay({
            element: this._HelpTooltipElement,
            offset: [-15, 0],
            positioning: (OverlayPositioning.CENTER_RIGHT as any)     //  Bug in TypeScript or OpenLayers jsdocs does not recognize the type correctly
        });
        this.Map.addOverlay(this._HelpTooltipOverlay);

        //  TODO: Probably want to only add this when tool is active...
        this._MouseOutHandler = () => {
            this._HelpTooltipElement.classList.add('hidden');
        }
        this.Map.getViewport().addEventListener('mouseout', this._MouseOutHandler);
    }

    /**
     * Override this to change the help message or anything else that needs to be done when the pointer moves.
     * Call SetHelpMessage to set the help message.
     * @param evt
     */
    protected OnPointerMove(evt: any): void {
    }

    protected SetHelpMessage(helpMsg: string, coord: [number, number], positionToLeft: boolean = true): void {
        //  Debounce this because calling it repeatedly on every mouse position event causes the rendering to be jittery
        this._DebouncedSetHelpMessage(helpMsg, coord, positionToLeft);
    }

    private _DebouncedSetHelpMessage: DebouncedFunc<(helpMsg: string, coord: [number, number], positionToLeft: boolean) => void> = _.debounce(this.ExecuteSetHelpMessage, 500);

    private ExecuteSetHelpMessage(helpMsg: string, coord: [number, number], positionToLeft: boolean): void {
        if (_.isEmpty(helpMsg))
            this._HelpTooltipElement.classList.add('hidden');
        else {
            this._HelpTooltipElement.innerHTML = helpMsg;

            this.ExecuteMoveHelpMessage(coord, positionToLeft);

            this._HelpTooltipElement.classList.remove('hidden');
        }
    }

    protected MoveHelpMessage(coord: [number, number], positionToLeft: boolean = true): void {
        //  Debounce this because calling it repeatedly on every mouse position event causes the rendering to be jittery
        this._DebouncedMoveHelpMessage(coord, positionToLeft);
    }

    private _DebouncedMoveHelpMessage: DebouncedFunc<(coord: [number, number], positionToLeft: boolean) => void> = _.debounce(this.MoveHelpMessage, 500);

    private ExecuteMoveHelpMessage(coord: [number, number], positionToLeft: boolean = true): void {
        this._HelpTooltipOverlay.setPosition(coord);

        if (positionToLeft) {
            this._HelpTooltipOverlay.setOffset([15, 0]);
            this._HelpTooltipOverlay.setPositioning(OverlayPositioning.CENTER_LEFT as any);        //  Bug in TypeScript or OpenLayers jsdocs does not recognize the type correctly
        }
        else {
            this._HelpTooltipOverlay.setOffset([-15, 0]);
            this._HelpTooltipOverlay.setPositioning(OverlayPositioning.CENTER_RIGHT as any);       //  Bug in TypeScript or OpenLayers jsdocs does not recognize the type correctly
        }
    }

    /**
     * Check the geometry to make sure it complies with minimum buffers and such.  If not, the geometry
     * will be modified so that it does.
     * If the geometry is modified, returns true.  If not modified, returns false.
     * @param geom
     */
    protected ValidateDrawnGeometry(geom: Geometry): boolean {
        if (!geom)
            return false;

        switch (geom.getType()) {
            case 'Circle':
                return this.ValidateDrawnCircle(geom as ol_geom_Circle);
            default:
                return false;
        }
    }

    private ValidateDrawnCircle(circle: ol_geom_Circle): boolean {
        try {

            //  In order to measure this properly to compare against the minimum buffer, we need to transform
            //  it into a geographic projection(UTM).
            const center = circle.getCenter();
            const lineToEdge = new LineString([center, [center[0] + circle.getRadius(), center[1]]]);
            const lineInMeters = GeometryTransformer.ToGeography(lineToEdge);
            const lengthFt = lineInMeters ? lineInMeters.getLength() * MapConstants.FEET_PER_METER : 0;

            if (lengthFt >= this.MapToolService.CircleMinimumRadiusFt)
                return false;

            //  Circle is too small.  Create a new one from the same center point  and using our configured
            //  buffer as the radius.  The radius in that Circle will be translated into the map projection so
            //  that we can set it into the Circle object that we were given (which will then update it on the map).
            const newCircle = GeometryUtils.CreateCircle(center, this.MapToolService.CircleMinimumRadiusFt * MapConstants.METERS_PER_FOOT);
            circle.setRadius(newCircle.getRadius());

            return true;
        } catch {
            //  Geometry is not bounds of US!
            return false;
        }
    }
}
