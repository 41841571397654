import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

//  Based on this: https://medium.com/@Idan_Co/angular-print-service-290651c721f9
@Injectable({
    providedIn: 'root'
})
export class PrintingService {
    //  Can put this on an element to cause it to not excluded from a report:
    //      [class.isPrinting]="PrintingService.IsPrinting"
    //  or - easier - just add do this: class="hide-when-printing"
    public IsPrinting = false;

    public Data: any;

    private _PreviousUrl: string;
    private _UrlFragment: string;

    private _ReportTitle: string;

    constructor(private router: Router, private _Location: Location, private _TitleService: Title,
        private _DeviceService: DeviceDetectorService) {
        //private _SettingsService: SettingsService, private _HttpClient: HttpClient) {
    }

    public PrintDocument(title: string, moduleName: string, urlPaths: string[], data: any = null) {
        this.IsPrinting = true;

        this.Data = data;
        this._ReportTitle = title;
        this.CaptureCurrentRouteInfo();

        //  Empty/null, module name is "/" for root
        if (!moduleName || moduleName === "")
            moduleName = "/";

        //  skipLocationChange needed to prevent the route change from being part of the navigation history.  Otherwise, the user
        //  can use the forward/back buttons (which is bad) and the print view will be all jacked up (which is worse).
        this.router.navigate([moduleName, { outlets: { print: urlPaths } }], { fragment: this._UrlFragment, skipLocationChange: true });
    }

    //  Reads and saves the current route - including the current Fragment if there is one.
    private CaptureCurrentRouteInfo(): void {
        //  This will not include the fragment
        this._PreviousUrl = this.router.url;

        const path = this._Location.path(true);
        let hashIndex = path.lastIndexOf("#");
        if (hashIndex > 0)
            this._UrlFragment = path.substring(hashIndex + 1);
        else
            this._UrlFragment = undefined;

        //  If there is a fragment, also make sure it's part of the url.  It may or may not be included in the router.url...
        //  This is necessary to make sure that we stay on the correct tab (just setting the fragment is sometimes not enough).
        if (this._UrlFragment && this._UrlFragment !== "") {
            hashIndex = this._PreviousUrl.lastIndexOf("#");
            if (hashIndex > 0)
                this._PreviousUrl = this._PreviousUrl.substring(0, hashIndex);
            this._PreviousUrl += "#" + this._UrlFragment;
        }
        //console.warn("CaptureCurrentRouteInfo", this._PreviousUrl, this.urlFragment);
    }

    //The route is ready so call print and then set everything back so the displayed page isn't printing but the print dialog shows what is to be printed
    public OnDataReady() {
        this.Print();
    }

    private Print(): void {
        setTimeout(() => {
            //  This affects the header that is automatically generated.  And it must be set right before calling print().
            const previousTitle = this._TitleService.getTitle();
            if (this._ReportTitle)
                this._TitleService.setTitle(this._ReportTitle);

            this.SetupAfterPrintWatcher();

            window.print();
            //this.LogEvent("window.print() finished");

            this._TitleService.setTitle(previousTitle);
        });
    }

    private _Timeout: NodeJS.Timeout;

    private SetupAfterPrintWatcher(): void {
        //  On desktop browsers, window.print() blocks until printing is done (or at least until it's been rendered in to the preview).
        //  On mobile, it does *NOT* block!  This causes an issue because we need to unset IsPrinting and fix up the url.
        //  And that can't be done until after printing has finished.
        //  All of the browsers trigger the "afterPrint" event differently (if at all!).  The best solution to handle all of them
        //  is using a combination of the onafterprint event and a listener on matchMedia().
        //  See https://www.tjvantoll.com/2012/06/15/detecting-print-requests-with-javascript/
        //  and https://stackoverflow.com/questions/18325025/how-to-detect-window-print-finish
        //  Not doing this (when we were assuming window.print() blocked), nothing would print at all on Mobile - see FD #1465.

        let mediaQueryList: MediaQueryList = null;
        const mediaQueryListener = (mql) => {
            //console.warn("mediaQueryListener", mql);
            //this.LogEvent("mediaQueryListener: matches=" + mql.matches, JSON.stringify(mql));

            if (!mql.matches)
                afterPrint();
        }

        const afterPrint = () => {
            //this.LogEvent("afterPrint: starting: isDesktop=" + this._DeviceService.isDesktop());

            //  This is a mess because iOS doesn't fire the print events in any sensible manner...
            //  It fires the afterPrint event (both via media query *AND* window.afterprint) immediately after the
            //  preview is rendered!  On an iPad, the printer to use is not even picked by default.  So you see the
            //  preview correctly when you first print, but then after you pick the printer, all of the after print
            //  events have already fired so now it's re-rendered WITHOUT printing enabled (using the wrong route)!
            //  I tried everything...the last resort was to add a timeout (only if not a desktop browser) to the cleanup
            //  which is hopefully enough time to allow the user to print before it cleans everything up.
            const delay = this._DeviceService.isDesktop() ? 0 : 15000;
            if (this._Timeout)
                clearTimeout(this._Timeout);
            this._Timeout = setTimeout(() => {
                if (this.IsPrinting) {
                    this.PrintingComplete();

                    window.onafterprint = null;
                    if (mediaQueryList)
                        mediaQueryList.removeListener(mediaQueryListener);
                }
            }, delay);
        };

        if (window.matchMedia) {
            mediaQueryList = window.matchMedia('print');
            mediaQueryList.addListener(mediaQueryListener);
        }

        window.onafterprint = () => {
            //this.LogEvent("window.onafterprint");
            afterPrint();
        }
    }

    public PrintingComplete(): void {
        //this.LogEvent("PrintingComplete");
        this.IsPrinting = false;

        //  Need to use the entire previous url (instead of the commented out method) or the route does not reset
        //  if it's a route in a submodule.
        //  skipLocationChange needed to prevent the route change from being part of the navigation history.  Otherwise, the user
        //  can use the forward/back buttons (which is bad) and the print view will be all jacked up (which is worse).
        //  Note that Angular 11 removed the "fragment" option in navigateByUrl.  But our _PreviousUrl already contains
        //  the fragment (like this: /tickets/view/20aadcd4-ebff-11eb-97f9-00155de36dc3#tab2) so it was not even necessary
        //  to be setting it in the first place.
        this.router.navigateByUrl(this._PreviousUrl, { skipLocationChange: true });
    }

    /* Can use this to send log events (as errors) to the server so that we can try to debug what's happening in iOS.
     * Which doesn't let you see the browser console unless you use a Mac and also sacrifice 3 goats.
    private _Order: number = 0;
    public LogEvent(msg: string, data: string = undefined): void {
        console.warn(msg);

        this._Order++;
        const request = new JSErrorRequest(window.location.href, "Printing #" + this._Order + ": " + msg, data);
        this._HttpClient.post(this._SettingsService.ApiBaseUrl + "/System/Logging/JSError", request)
            .subscribe(response => console.log("Sent log report to server"));
    }
    */
}
