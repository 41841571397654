import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ListFilterGroup } from '@iqModels/Configuration/ConfiguredListColumnsAndFilters/ListFilterGroup.model';
import { EntityEnum } from 'Enums/EntityType.enum';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from 'Services/SettingsService';
import { SearchFilter } from '@iqModels/Searching/SearchFilter.model';
import { map } from 'rxjs/operators';
import { ListColumnService } from '@iqSharedComponentControls/Lists/ListColumn.service';
import { SearchOrderBy } from '@iqModels/Searching/SearchOrderBy.model';
import { SelectOption } from '../../../../../../Models/Configuration/SelectOption.model';



/*
 *
 *
 Used to keep track of what the user has picked to display, and what the options are.  All saving and stuff needs to be in the Crud service
 *
 *
 */
@Injectable({
    providedIn: 'root'
})
export class ListFilterService {
    protected apiPath: string = "/Config/ListFilterGroup";

    constructor(private http: HttpClient, private settingsService: SettingsService, private listColumnService: ListColumnService) {
    }

    //Add any configuration calls in here so that the cached values will get cleared when a person switches one calls
    //or logs out(may not be needed when they logout, because to login they get redirected to the IdSvr, but just to be safe we clear it out)
    public ClearCache() {
        this._userDisplayedFilters = {};
        this._userAvailableFilters = {};
        this._lastSavedFilter = {};
        this._subs = {};
    }

    public copyFilters(filters: SearchFilter[]): SearchFilter[] {
        const newFilter = [];
        if (filters) {
            for (let i = 0; i < filters.length; i++) {
                if (!filters[i].Values)
                    continue;

                const vals = [];
                for (let l = 0; l < filters[i].Values.length; l++)
                    vals.push(filters[i].Values[l]);

                newFilter.push(new SearchFilter(filters[i].PropertyName, filters[i].Operator, vals, filters[i].IgnoreFilter, filters[i].QuickTextSearch, filters[i].IsOrFilter));
            }
        }

        return newFilter;
    }

    public copyOrderBys(orderBys: SearchOrderBy[]): SearchOrderBy[] {
        const newOrderBys = [];

        if (orderBys) {
            for (let i = 0; i < orderBys.length; i++)
                newOrderBys.push(new SearchOrderBy(orderBys[i].PropertyName, orderBys[i].Descending));
        }

        return newOrderBys;
    }

    public copyFilterGroup(val: ListFilterGroup): ListFilterGroup {
        let newSaved: ListFilterGroup = null;

        //Have to create a new one to break the reference so we can compare with this later
        if (val) {
            newSaved = new ListFilterGroup(val.ID);
            newSaved.Entity = val.Entity;
            newSaved.Filters = this.copyFilters(val.Filters);
            newSaved.OrderBy = this.copyOrderBys(val.OrderBy);

            newSaved.RoleTypes = [];
            if (val.RoleTypes) {
                for (let i = 0; i < val.RoleTypes.length; i++)
                    newSaved.RoleTypes.push(new SelectOption(val.RoleTypes[i].Value, val.RoleTypes[i].Name));
            }

            newSaved.IsDefault = val.IsDefault;
            newSaved.IsSystemWide = val.IsSystemWide;
            newSaved.IsSystemDefault = val.IsSystemDefault;
            newSaved.Name = val.Name;
            newSaved.Selected = val.Selected;
            newSaved.xmin = val.xmin;
        }

        return newSaved;
    }

    public IsRequiredProperty(filter: SearchFilter): boolean {
        switch (filter.PropertyName) {
            case "TakenEndDate":
            case "TakenStartDate":
            case "WorkStartDate":
            case "ExpiresDate":
            case "ResponseDueDate":
                return true;
        }
    }


    private _lastSavedFilter: { [key: number]: ListFilterGroup } = {};//This is used if the user modifies the Filter list and doesn't save it. If the current one is the saved one, then this should be empty
    //If not using EntityEnum then the number needs to be unique so we don't get confilcts
    getLastSavedFilter(type: EntityEnum | number) {
        //Return a copy so that if we use this to set the filters then change it we will be changing a new isntance and not the saved one
        return this.copyFilterGroup(this._lastSavedFilter[type]);
    }
    //If not using EntityEnum then the number needs to be unique so we don't get confilcts
    setLastSavedFilter(type: EntityEnum | number, val: ListFilterGroup) {
        //Add a new copy so that if we change the one passed in we don't change the one we have saved here
        this._lastSavedFilter[type] = this.copyFilterGroup(val);
    }

    private _userDisplayedFilters: { [key: number]: ListFilterGroup } = {};
    //If not using EntityEnum then the number needs to be unique so we don't get confilcts
    getLastDisplayedFilter(type: EntityEnum | number) {
        //Return a copy so that if we use this to set the filters then change it we will be changing a new isntance and not the saved one
        return this.copyFilterGroup(this._userDisplayedFilters[type]);
    }

    public UpdateDisplayedFilterGroup(item: ListFilterGroup) {
        if (item.Entity === EntityEnum.None) {
            console.warn('Need to provide an entity type if you want to save the columns');
            return;//if none then nothing to do
        }

        this._userDisplayedFilters[item.Entity] = item;
        
        if (this._subs[item.Entity])
            this._subs[item.Entity].next(item);
    }

    //If not using EntityEnum then the number needs to be unique so we don't get confilcts
    public UpdateDisplayedFilters(type: EntityEnum | number, filters: SearchFilter[], orderBy: SearchOrderBy[] = null) {
        if (type === EntityEnum.None) {
            console.warn('Need to provide an entity type if you want to save the columns');
            return;//if none then nothing to do
        }

        if (this._userDisplayedFilters[type]) {
            this._userDisplayedFilters[type].Filters = filters;
            this._userDisplayedFilters[type].OrderBy = orderBy;
        }
        else {
            //for pages that don't allow the user to save filters to the DB
            const group = new ListFilterGroup(null)
            group.Entity = type;
            group.Filters = filters;
            group.OrderBy = orderBy;
            this.UpdateDisplayedFilterGroup(group);
            return;
        }

        if (this._subs[type])
            this._subs[type].next(this._userDisplayedFilters[type]);
    }

    //Observable so we can subscribe to know when they change
    private _subs: { [key: number]: BehaviorSubject<ListFilterGroup> } = {};
    //If not using EntityEnum then the number needs to be unique so we don't get confilcts
    public GetUserCurrentDisplayedFilters(type: EntityEnum | number): Observable<ListFilterGroup> {
        if (type === EntityEnum.None) {
            console.warn('Need to provide an entity type if you want to save the columns');
            return new BehaviorSubject(null);//if none then nothing to do
        }

        if (!this._subs[type])
            this._subs[type] = new BehaviorSubject(this._userDisplayedFilters[type]);

        return this._subs[type].pipe(map(val => this.copyFilterGroup(val)));//Need to copy this so that the value we emit isn't the saved value or if the components change the emitted value it will change the saved value
    }


    private _userAvailableFilters: { [key: number]: ListFilterGroup[] } = {};
    public GetUserAllDisplayedFilters(type: EntityEnum): Observable<ListFilterGroup[]> {
        return new Observable<ListFilterGroup[]>(observer => {
            if (!this._userAvailableFilters[type]) {
                //  Don't have data cached yet - fetch it now
                this.http.get<ListFilterGroup[]>(this.settingsService.ApiBaseUrl + this.apiPath + "/GetAllForType/" + type)
                    .subscribe(data => {
                        const filters = data;
                        this._userAvailableFilters[type] = filters;

                        //  Don't need to make a copy - these can't be modified and if we refresh, we set the cached property to null to force a new download
                        observer.next(filters);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                    });
            }
            else {
                //  Already have data cached so return it immediately
                //  Don't need to make a copy - these can't be modified and if we refresh, we set the cached property to null to force a new download
                observer.next(this._userAvailableFilters[type]);
                observer.complete();
            }
        });
    }
}
