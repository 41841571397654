export enum ServiceAreaTypeEnum {
    Full = 0,
    LBP = 1,
    Landlord = 2
}

export enum ServiceAreaTypeEnumDescriptions {
    Full = "Full",
    LBP = "LBP",
    Landlord = "Landlord"
}
