import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigSiteRuleActionEnum } from 'Enums/DigSiteRules/DigSiteRuleAction.enum';
import { DirectionEnum, DirectionEnumDescription } from 'Enums/Direction.enum';
import { DigSite } from 'Models/DigSites/DigSite.model';
import { DigSiteRuleValidationErrorResponse } from 'Models/Errors/DigSiteRuleValidationErrorResponse.model';
import { MapRouteSegment } from "Models/Maps/MapRouteSegment.model";
import { SplitGeocodeItemResponse } from "Models/Maps/SplitGeocodeItemResponse.model";
import { VerifyTicketBeforeSaveResponse } from 'Models/Tickets/VerifyTicketBeforeSaveResponse.model';
import { TicketEntrySectionEventService } from 'Pages/Tickets/Services/TicketEntrySectionEvent.service';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { CommonService } from 'Services/CommonService';
import { ComponentLookupRegistry } from 'Services/ComponentLookup.service';
import { GeocodeService } from 'Services/GeocodeService';
import { InformationDialogComponent } from 'Shared/Components/Controls/Dialog/Information/InformationDialog.component';
import { DialogModel } from 'Shared/Components/Controls/Dialog/Models/Dialog.model';
import { DynamicContentDirective } from 'Shared/Directives/DynamicContent/DynamicContent.directive';
import { TicketEntryDialogBase } from '../../Base/TicketEntryDialogBase.component';
import { TicketEntrySectionBase } from '../../Base/TicketEntrySectionBase.component';
import { DigsiteMapComponent } from '../DigsiteMap/DigsiteMap.component';
import { TicketEntryFormGroup } from "../InputControls/TicketEntryFormGroup";

export interface ICustomCreateMultipleTicketsContentComponent {
    Init(ticketEntryFormGroup: TicketEntryFormGroup): void;
    IsValid(): boolean;
    SaveFormValues(totalLengthFt: number): void;
}

export class CreateMultipleTicketsDialogData {
    constructor(
        public TicketEntryForm: TicketEntryFormGroup,
        public VerifyInfo: VerifyTicketBeforeSaveResponse,
        public DigSiteRule: DigSiteRuleValidationErrorResponse) {
    }
}

/**
 *  Dialog returns the VerifyTicketBeforeSaveResponse.  Which is updated if we are creating multiple tickets
 *  (because the data from this dialog can affect the content shown on the VerifyLocation dialog).
 *  Returns null if the user picks "Back to ticket" which will cause us to abort the save process.
 */
@Component({
    templateUrl: './CreateMultipleTicketsDialog.component.html',
    styleUrls: ['./CreateMultipleTicketsDialog.component.scss'],
    providers: [
        CommonService,
        TicketEntrySectionEventService.Provider,
        { provide: TicketEntrySectionBase, useExisting: forwardRef(() => CreateMultipleTicketsDialogComponent) }      //  so we can use QueryList<EntrySectionBase> to find
    ]
})
export class CreateMultipleTicketsDialogComponent extends TicketEntryDialogBase implements OnInit {

    @ViewChild(DynamicContentDirective, { static: true })
    private _CustomContent: DynamicContentDirective;

    @ViewChild(DigsiteMapComponent, { static: true })
    private _DigsiteMap: DigsiteMapComponent;

    private _CustomComponent: ICustomCreateMultipleTicketsContentComponent;

    public TotalLengthFt: number;
    public Segments: SplitGeocodeItemResponse[] = [];

    public DirectionEnum = DirectionEnum;
    public DirectionEnumDescription = DirectionEnumDescription;

    private _IsBusy: boolean = false;       //  set when re-calling VerifyTicketBeforeSave

    constructor(commonService: CommonService, sectionEventService: TicketEntrySectionEventService, private _TicketService: TicketService,
        private _GeocodeService: GeocodeService,
        private _DialogRef: MatDialogRef<CreateMultipleTicketsDialogComponent, VerifyTicketBeforeSaveResponse>,
        @Inject(MAT_DIALOG_DATA) public Data: CreateMultipleTicketsDialogData)
    {
        super(commonService, sectionEventService, Data.TicketEntryForm);

        const digsite = (this.Data.TicketEntryForm.get("DigSite") as FormGroup).getRawValue() as DigSite;
        const ticketTypeID = (this.Data.TicketEntryForm.get("TicketTypeID") as FormControl).value as string;
        const lengthFt = this.Data.DigSiteRule.MinLengthFt ?? 1320;
        this._GeocodeService.SplitDigSite(lengthFt, digsite, ticketTypeID).subscribe(response => this.OnSplitDigSiteResponseReceived(response));
    }

    public ngOnInit(): void {
        //  Check for a custom component that should be created and injected into the form
        const componentKey = this.CommonService.SettingsService.CurrentOneCallCenterCode + "-Create-Multiple-Tickets-Content";
        const customComponentClassRef = ComponentLookupRegistry.get(componentKey);
        if (customComponentClassRef) {
            this._CustomComponent = this._CustomContent.LoadComponent(customComponentClassRef) as ICustomCreateMultipleTicketsContentComponent;
            this._CustomComponent.Init(this.Data.TicketEntryForm);
        }
    }

    public CanCreateMultipleTickets(): boolean {
        return !this._IsBusy && this._CustomComponent?.IsValid();
    }

    private OnSplitDigSiteResponseReceived(response: SplitGeocodeItemResponse[]): void {
        this.Segments = response ?? [];
        this.TotalLengthFt = this.Segments.map(s => s.LengthFt).reduce((prev, curr) => prev + curr, 0);

        const routeList = this.Segments.map(s => new MapRouteSegment(s.Number, "#" + s.Number.toString(), s.UnbufferedGeometryJson));
        this._DigsiteMap.ShowRoute(routeList);

        const numSegmentsWithNoServiceAreas = this.Segments.filter(s => !s.NumAffectedServiceAreas);
        if (numSegmentsWithNoServiceAreas.length > 0) {
            //  Must show using a timeout or the dialog does not display (probably conflicts with the main dialog or displays under it).
            setTimeout(() => this.ShowNoServiceAreasWarning(numSegmentsWithNoServiceAreas.length), 200);
        }
    }

    private ShowNoServiceAreasWarning(numSegmentsWithNoServiceAreas: number): void {
        let msg = String(numSegmentsWithNoServiceAreas) + " segment";
        if (numSegmentsWithNoServiceAreas === 1)
            msg += " of this group does";
        else
            msg += "s of this group do";
        msg += " not include any member service areas.  This will cause " + String(numSegmentsWithNoServiceAreas) + " notice";
        if (numSegmentsWithNoServiceAreas > 1)
            msg += "s";
        msg += " to be created with no member service areas.";
        msg += "<p><span style='color:red'>Please verify the work area, if needed.</span>";

        this.CommonService.Dialog.open(InformationDialogComponent, {
            panelClass: 'iq-warn',
            data: new DialogModel("Segments do not affect Service Areas", msg),
            width: '50%'
        });
    }

    public OnSaveMultipleTickets(): void {
        this._CustomComponent.SaveFormValues(this.TotalLengthFt);
        this._TicketService.Ticket.value.CreateMultipleTicketsForSegments = this.Segments;

        //  Need to re-call VerifyTicketBeforeSave because this can affect the content we show on the
        //  VerifyLocation dialog.
        this._IsBusy = true;
        this.TicketEntryForm.VerifyTicketBeforeSave().subscribe(verifyResponse => {
            //  Need to manually remove any other Digsite Rules for Length since they have been resolved by this dialog.
            //  This allows configuring limits (errors/warnings) that will only be applied when NOT splitting up the digsite.
            //  TODO: Would be nice if these dig site rules would be calculated based off the segments instead of the
            //  full digsite geometry (since we are re-calling VerifyTicketBeforeSave anyway).  But right now at least,
            //  didn't have any need other than to remove them here.
            if (verifyResponse.InvalidDigSiteRules)
                verifyResponse.InvalidDigSiteRules = verifyResponse.InvalidDigSiteRules.filter(r => !this.IgnoreDigsiteRule(r));

            this._DialogRef.close(verifyResponse);
        }, () => this._IsBusy = false );
    }

    private IgnoreDigsiteRule(digsiteRule: DigSiteRuleValidationErrorResponse): boolean {
        return ((digsiteRule.DigSiteRuleAction === DigSiteRuleActionEnum.ShowError) || (digsiteRule.DigSiteRuleAction === DigSiteRuleActionEnum.ShowMessage))
                && digsiteRule.HasLengthRuleType;
    }
}
