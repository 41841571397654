import { Component, Inject } from '@angular/core';
import { IqAwsCognitoService } from '../../../Services/iq-aws-cognito.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CognitoUser } from '@aws-amplify/auth';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { controlsEqualValidator } from '../../../validation';
import { PasswordRequirements } from '../../../models';
import { take } from 'rxjs/operators';
import { FormComponentBase } from '../../../form-component-base';

export interface iQChangePasswordDialogComponentData {
  PasswordRequirements: PasswordRequirements;
}

@Component({
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.css']
})
export class iQChangePasswordDialogComponent extends FormComponentBase {
  BuildForm(): FormGroup {
    return new FormGroup({
      OldPassword: new FormControl(null, [Validators.required]),
      NewPassword: new FormControl(null, [Validators.required]),
      ConfirmNewPassword: new FormControl(null, [Validators.required])
    }, [controlsEqualValidator('NewPassword', 'ConfirmNewPassword', 'passwords must match')]);
  }

  PasswordRequirements: PasswordRequirements;
  loggedInUser: CognitoUser;
  isSignedIn: boolean;

  get oldPassword() { return this.group.get("OldPassword"); }
  get newPassword() { return this.group.get("NewPassword"); }
  get confirmNewPassword() { return this.group.get("ConfirmNewPassword"); }

  constructor(protected awsCognitoService: IqAwsCognitoService, public dialogRef: MatDialogRef<iQChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: iQChangePasswordDialogComponentData, private snackBar: MatSnackBar) {
    super();

    let defaultRequirements = new PasswordRequirements();
    this.PasswordRequirements = { ...defaultRequirements, ...dialogData.PasswordRequirements };

    this.isBusy = true;

    awsCognitoService.signInEventChange$.pipe(take(1))
      .subscribe(val => {
        this.isSignedIn = val.SignedIn;

        if (val.SignedIn) {
          this.loggedInUser = val.User;
        }
        
        this.isBusy = false;
        
      }, (err) => {
        this.isBusy = false;
        this.SetError(err);
      });
  }

  ngOnInit() {
    super.ngOnInit();

    this.SetPasswordRequirements(this.newPassword, this.PasswordRequirements);

    this.dialogRef.updateSize("500px");
  }

  onChangePassword(){
    if (!this.group.valid)
      return;

    this.awsCognitoService.ChangePassword(this.loggedInUser,this.oldPassword.value, this.newPassword.value)
    .subscribe(success=>{
      
      this.snackBar.open("Password Changed", null);
      this.dialogRef.close();

    }, err => {
      let msg = err.message;

      if(err.message === "Incorrect username or password.")
        msg = "Old Password was not valid.";

      this.snackBar.open(msg, null);
    });
  }
}
