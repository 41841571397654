import { SearchFilterOperatorEnum } from "Enums/SearchFilterOperator.enum";

export class SearchFilterValue {
    constructor(public FilterValue: any, public DisplayValue: string
        , /** There are a few issues with this that need to be fixed if used.  First one is that if it's hidden from the UI then the column in the list needs to not show the column as filtered.  The second is if this is set and the column is allowed to change the filter on it, it needs to somehow know when to apply these non-shown filters and when not to (i.e. if the column is service areas and there are 2 values set and not shown to the user, we need to allow the user to change the filter to only see one of the two values)
            */ public HideInUI: boolean = false
        )
    {
    }
}

export class SearchFilter {

    //If you add a property here make sure you update the 'Copy' function in the ListFilter.service so it will get set properly when read out

    constructor(public PropertyName: string, public Operator: SearchFilterOperatorEnum, public Values: SearchFilterValue[],
        public IgnoreFilter: boolean = false,
        public QuickTextSearch: boolean = false,//If true, then it should be the value in the quick text search at the top of the search pages.  i.e. the quick ticket number search on tickets search page
        public IsOrFilter = false)//If true it will be put in an OR filter that is AND'd to the other (normal) AND filters
    {
    }

    IsEqual(comp: SearchFilter): boolean {
        if (comp.IgnoreFilter !== this.IgnoreFilter)
            return false;
        if (comp.IsOrFilter !== this.IsOrFilter)
            return false;
        if (comp.Operator !== this.Operator)
            return false;
        if (comp.PropertyName !== this.PropertyName)
            return false;
        if (comp.QuickTextSearch !== this.QuickTextSearch)
            return false;

        if (comp.Values && !this.Values)
            return false;

        if (!comp.Values && this.Values)
            return false;

        if (comp.Values && this.Values) {
            if (comp.Values.length !== this.Values.length)
                return false;

            for (let index = 0; index < comp.Values.length; index++) {
                const compValue = comp.Values[index];
                if (!this.Values.find(f => f.DisplayValue === compValue.DisplayValue && f.FilterValue === compValue.FilterValue && f.HideInUI === compValue.HideInUI))
                    return false;
            }
        }

        return true;
    }
}
