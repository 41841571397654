import { Observable } from "rxjs";
import { SearchResponse } from "@iqModels/Searching/SearchResponse.model";
import { SearchRequest } from "@iqModels/Searching/SearchRequest.model";
import { SearchFilterOperatorEnum } from "Enums/SearchFilterOperator.enum";

export class SearchColumn {

    constructor(public returnPropertyName: string,//Property to use to get the value form the response item
        public name: string,//Name to use in the UI for the column
        public column: string,//The column name to use on the calls to the server (columns to return, orderby)
        public filterColumn: string,
        public filterOperator: SearchFilterOperatorEnum = SearchFilterOperatorEnum.Contains)//The column name to use on the calls to the server to filter
    {
    }

    //Used for if we need to search on other columns.  i.e. Agent on Tickets, we used either the AgentPersonID (for exact person) or Agent.Fullname (for name containing)
    OtherFilterColumnNames: string[] = [];

    /**
     *  If false, the sort options will be hidden on the pop-up sort/filter dialog when clicking on the column header.
     */
    public CanSort: boolean = true;

    /**
     *  If false, the filter options will be hidden on the pop-up sort/filter dialog when clicking on the column header
     *  and also hidden in the column chooser in the filter builder dialog.
     */
    public CanFilter: boolean = true;

    /**
     *  If false, clicking on the column header will not open the sort/filter dialog.
     */
    public get canSearchAndFilter(): boolean { return this.CanSort || this.CanFilter; }
    public set canSearchAndFilter(canSortAndFilter: boolean) {
        this.CanSort = canSortAndFilter;
        this.CanFilter = canSortAndFilter;
    }

    //A class name to put on the column
    class: string;

    //This is used to set a specific width of the column in the list.  Need to be a format that fxFlex accepts
    width: string = "";

    disabled: boolean;

    filterOptions: Observable<any[]>;

    //If the display value needs to be formatted from what is returned from the server then set this and update the 'dynamicFormat' pipe to handle the format
    formatType: 'date' | 'yesNo' | 'phone' | 'entityType' | null;
    //If formatting the value this is the type of format, if needed. i.e. to format a value to a date, the formatType would be 'date' and this property will be the date format to use ('short', 'MM/dd/YYY', etc)
    format: any;

    //If the filter popup should use date controls set this to true
    useDateSearch: boolean;

    public ShowFutureDateOptions: boolean = true;

    //If set then the text searches won't allow an text to be added to the filter until it's as long as this number
    minNumberOfCharsBeforeValid: number;

    //Set to true if the filter popup needs to be autocomplete.  Need to supply 'autoCompleteSearchFunction' value if this is true
    autoComplete: boolean;

    //Set this to the property of the search results you want to use for the filter value
    autocompleteResultFilterValue: string = "ID";
    //Set this to the property of the search results you want to use for the display value
    autocompleteResultDisplayValue: string = "Name";
    //Function to use for autocomplete calls
    autoCompleteSearchFunction: (filter: SearchRequest) => Observable<SearchResponse>;

    usePersonSearch: boolean;

    useNumberSearch: boolean;

    //Use this if you need to show a message on a filter dialog.  Like if the values can't be changed use this to explain why
    disabledOptionsMessage: string;

    hidden: boolean;
}
