import { formatDate } from '@angular/common';
import { AfterContentInit, Directive, ElementRef, Input, OnDestroy, Optional, Self } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from 'Services/SettingsService';
import { DateUtils } from 'Shared/Utils/DateUtils';
import { conformToMask } from 'text-mask-core';
import adjustCaretPosition from 'text-mask-core/src/adjustCaretPosition';

enum DateComponentEnum {
    Month = 0,
    Day = 1,
    Year = 2,
    Hour = 3,
    Minute = 4,
    AmPm = 5
}

@Directive({
    selector: '[date-editor]',
    host: {
        '(keydown)': 'OnKeydownEvent($event)',
        '(input)': 'OnInputEvent($event.target)',
        '(focus)': 'OnFocus($event.target)',
        '(blur)': 'OnBlur($event.target)',
        '(click)': 'OnClick()'
    }
})
export class DateEditorDirective implements AfterContentInit, OnDestroy {

    //  Note the comma...  ng-date-picker puts that in there and can't find any way to prevent it.
    //  Think that may come from the locale date/time formatting and could not find any way to override that.
    //  ng-date-picker does have a way to make a custom DateTimeAdapter that may be able to handle that
    //  but...looks like a lot of work and there's no complete example...  https://danielykpan.github.io/date-time-picker/
    private _Config = {
        LastComponent: DateComponentEnum.AmPm,
        UseMilitaryTime: false,
        DateFormat: "MM/dd/yyyy, hh:mm a",      //  If we ever need different formats, also need to be able to handle in ParseDateFromInput()!!!
        AutoCorrectDatePipeFormat: "mm/dd/yyyy, HH:MM",
        Mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ',', ' ', /\d/, /\d/, ':', /\d/, /\d/, ' ', /[AaPp]/, 'M'],
        PlaceholderChar: '_',
        Placeholder: '__/__/____, __:__ _M',
        AMPMIndex: 18
    }

    private _ForceTime: string = null;
    private _Min: Date = null;
    private _Max: Date = null;

    //  'both' | 'calendar' | 'timer'
    @Input("date-editor")
    set Options(options: { PickerType?: 'both' | 'calendar' | 'timer', ForceTime?: string, Min?: Date, Max?: Date }) {
        if (options) {
            this._ForceTime = options.ForceTime;
            this._Min = options.Min;
            this._Max = options.Max;

            if (options.PickerType === "calendar") {
                this._Config.LastComponent = DateComponentEnum.Year;
                this._Config.DateFormat = "MM/dd/yyyy";
                this._Config.AutoCorrectDatePipeFormat = "mm/dd/yyyy";
                this._Config.Mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
                this._Config.Placeholder = "__/__/____";
                this._Config.AMPMIndex = null;
                if (!this._ForceTime)
                    this._ForceTime = "00:00";      //  If not set in options, always force to 00:00 or will get set to time of midnight in UTC time!
            }
        }
    }

    private _FormControl: FormControl;
    private _PreviousConformedValue: string = '';
    private _EmitChangeEventOnBlur: boolean = false;

    private _Destroyed: Subject<void> = new Subject();

    constructor(private _Element: ElementRef, @Optional() @Self() private _Control: NgControl, settingsService: SettingsService) {
        if (settingsService.UsesMilitaryTime) {
            this._Config.UseMilitaryTime = true;
            this._Config.DateFormat = "MM/dd/yyyy, HH:mm a";
        }
    }

    ngOnDestroy() {
        this._Destroyed.next();
        this._Destroyed.complete();

        this._FormControl = null;
    }

    ngAfterContentInit(): void {
        if (this._Control)
            this._FormControl = this._Control.control as FormControl;

        if (this._FormControl) {
            setTimeout(() => {
                this.SetDate(DateUtils.ConvertFromString(this._FormControl?.value));

                //  Initialize the cursor position to the beginning.  Otherwise, sometimes the initial focus thinks it's
                //  at the end of the input and it selects the last component.
                const targetElement = this._Element.nativeElement as HTMLInputElement;
                if (targetElement.setSelectionRange)
                    targetElement.setSelectionRange(0, 0);
            });

            //  Need to subscribe for changes in case the field is a phone field (or if it's LATER CHANGED to a phone field)
            //  so that we can display the masked value.
            //  And if the form control value changes, need to mask the new value (i.e. user picks from an autocomplete)
            this._FormControl.valueChanges
                .pipe(takeUntil(this._Destroyed))
                .subscribe(value => setTimeout(() => this.SetDate(DateUtils.ConvertFromString(value))));
        }
    }

    private OnKeydownEvent(event: KeyboardEvent) {
        let component: DateComponentEnum = null;

        switch (event.key) {
            case "ArrowRight":
                component = this.ComponentFromCurrentCaretPosition() + 1;
                if (component > this._Config.LastComponent)
                    component = this._Config.LastComponent;
                break;
            case "ArrowLeft":
                component = this.ComponentFromCurrentCaretPosition() - 1;
                if (component < DateComponentEnum.Month)
                    component = DateComponentEnum.Month;
                break;
            case "ArrowUp":
                //  Arrow up/down needs to set this so that it stays selected (get re-selected) - otherwise,
                //  the selection is reset.
                component = this.ComponentFromCurrentCaretPosition();
                this.UpDownComponent(component, true);
                break;
            case "ArrowDown":
                //  Arrow up/down needs to set this so that it stays selected (get re-selected) - otherwise,
                //  the selection is reset.
                component = this.ComponentFromCurrentCaretPosition();
                this.UpDownComponent(component, false);
                break;
            case "End":
                component = this._Config.LastComponent;
                break;
            case "Home":
                component = DateComponentEnum.Month;
                break;
            case "a":
            case "A":
                component = this.ComponentFromCurrentCaretPosition();
                this.SetAMPM("A");
                break;
            case "p":
            case "P":
                component = this.ComponentFromCurrentCaretPosition();
                this.SetAMPM("P");
                break;
            case "0":
            case "1":
            case "2":
            case "3":
            case "4":
            case "5":
            case "6":
            case "7":
            case "8":
            case "9":
                if (this.ComponentFromCurrentCaretPosition() === DateComponentEnum.AmPm) {
                    //  Don't allow numerics on the am/pm field or it messes up the mask
                    event.stopPropagation();
                    event.preventDefault();
                }
                //  Otherwise, let these pass and get handled normally
                break;
            case "Tab":
            case "PageUp":
            case "PageDown":
                //  Let these pass and get handled normally
                break;
            default:
                //  Prevent all other input.
                //  ** Do not call stopPropagation().  Doing that will prevent any other OnKeydown handlers from being able to
                //  handle the input - which is necessary to handle shortcut keys for FL work start.
                //  But calling preventDefault will keep the keypress from being handled any other way - so it will not trigger an
                //  input event.
                event.preventDefault();
                break;
        }

        //  Allow -1 so that ArrowLeft at start of field will (re)select the first component - otherwise, it
        //  removes the selection.
        if (component !== null) {
            this.SelectDateTimeComponent(component);
            event.stopPropagation();
            event.preventDefault();
        }
    }

    private SetAMPM(ampm: string): void {
        const index = this._Config.AMPMIndex;
        if ((index === null) || (index === undefined))
            return;     //  AM/PM not in current mask

        //  Always just set the A or P char into the input.  Helps fix the input value if a
        //  bad character got entered and the mask got messed up (otherwise the date will be invalid).
        let value: string = this._Element.nativeElement.value;
        value = value.substr(0, index) + ampm + value.substr(index + 1);

        const date = this.ParseDateFromInput(value);
        if (DateUtils.IsValidDate(date))
            this.SetDate(date);
        else
            this._Element.nativeElement.value = value;
    }

    private ComponentFromCurrentCaretPosition(): DateComponentEnum {
        const targetElement = this._Element.nativeElement as HTMLInputElement;
        const caret = targetElement.selectionStart;

        //  MM/dd/yyyy, hh:mm aa
        //  012345678901234567890
        if (caret <= 2)
            return DateComponentEnum.Month;
        if (caret <= 5)
            return DateComponentEnum.Day;
        if (caret <= 10)
            return DateComponentEnum.Year;
        if (caret <= 14)
            return DateComponentEnum.Hour;
        if (caret <= 17)
            return DateComponentEnum.Minute;
        return DateComponentEnum.AmPm;
    }

    private UpDownComponent(component: DateComponentEnum, up: boolean): void {
        const date = this.ParseDateFromInput(this._Element.nativeElement.value);
        if (!DateUtils.IsValidDate(date))
            return;

        let increment = up ? 1 : -1;
        if (component === DateComponentEnum.Minute) {
            //  If current number of minutes is not on a 15 minute boundary, just inc/dec by the amount
            //  needed to get to the next boundary
            const mod = date.getMinutes() % 15;
            increment = up ? (15 - mod) : (mod ? -mod : -15);
        } else if (component === DateComponentEnum.AmPm) {
            //  Up/Down on am/pm goes +/- 12 hours
            increment = up ? 12 : -12;
            component = 3;
        }

        switch (component) {
            case DateComponentEnum.Month:
                date.setMonth(date.getMonth() + increment);
                break;
            case DateComponentEnum.Day:
                date.setDate(date.getDate() + increment);
                break;
            case DateComponentEnum.Year:
                date.setFullYear(date.getFullYear() + increment);
                break;
            case DateComponentEnum.Hour:
                date.setHours(date.getHours() + increment);
                break;
            case DateComponentEnum.Minute:
                date.setMinutes(date.getMinutes() + increment);
                break;
        }

        this.SetDate(date);
    }

    private OnInputEvent(targetElem: HTMLInputElement) {
        const valueToTransform = targetElem.value as string;
        const prevComponent = this.ComponentFromCurrentCaretPosition();

        const result = this.OnDateElementChanged(targetElem, valueToTransform);

        const date = this.ParseDateFromInput(result.value);

        if (DateUtils.IsValidDate(date)) {
            //  Must force the date to be set (triggers always calling _FormControl.setValue - emits events) or typing out the exact
            //  same value and then tabbing is causing the date to then be set to null(which sets it to now).  Could not find what was
            //  causing that to happen, but forcing it to be set like this fixes that problem and the worst thing that will happen is an
            //  extra change event firing(but the user did type in to the field, so that seems reasonable).
            //  i.e. If the date is already set to "02/11/2022", typing "02" for the month (0 is important) and then tab.
            this.SetDate(date, true);     //  If have a valid date, set like this so that the FormControl is set correctly and only when we have a valid date entered
        } else
            targetElem.value = result.value;

        //  Must set the caret position or it will be set to the end of the input
        if ((result.caret >= 0) && targetElem.setSelectionRange) {
            //  Must use setTimeout because the autocomplete trigger ALWAYS sets the caret to the end!
            //  So this allows us to set it after it's done doing it's stuff.
            //setTimeout(() => targetElem.setSelectionRange(result.caret, result.caret));
            targetElem.setSelectionRange(result.caret, result.caret);
            const newComponent = this.ComponentFromCurrentCaretPosition();
            if (prevComponent !== newComponent)
                this.SelectDateTimeComponent(newComponent);
            return false;
        }

        return;
    }

    private OnFocus(targetElem: HTMLInputElement): void {
        const component = this.ComponentFromCurrentCaretPosition();

        //  If empty, fill it with the placeholder so that we can set the selection to the first component
        if (!targetElem.value || (targetElem.value === ''))
            targetElem.value = this._Config.Placeholder;

        this.SelectDateTimeComponent(component);
    }

    private OnBlur(targetElem: HTMLInputElement): void {
        //  If equals the placeholder, set it to empty
        if (targetElem.value === this._Config.Placeholder)
            targetElem.value = '';

        if (this._EmitChangeEventOnBlur) {
            //  This is set if the FormControl has updateOn set to 'blur' and we set the value
            this._FormControl.setValue(this._FormControl.value);
            this._EmitChangeEventOnBlur = false;
        }
    }

    private OnClick(): void {
        const component = this.ComponentFromCurrentCaretPosition();
        this.SelectDateTimeComponent(component);
    }

    //  component: 0=month, 1=day, 2=year, 3=hour, 4=minute, 5=am/pm
    private SelectDateTimeComponent(component: DateComponentEnum): void {
        if (!this._Element)
            return;
        const targetElement = this._Element.nativeElement as HTMLInputElement;
        if (!targetElement.setSelectionRange)
            return;     //  ???

        let start: number = 0;  //  default (0) = month
        let len: number = 2;

        switch (component) {
            case DateComponentEnum.Day:
                start = 3;
                break;
            case DateComponentEnum.Year:
                start = 6;
                len = 4;
                break;
            case DateComponentEnum.Hour:
                start = 12;
                break;
            case DateComponentEnum.Minute:
                start = 15;
                break;
            case DateComponentEnum.AmPm:
                start = 18;     //  am/pm
                len = 1;
                break;
        }

        targetElement.setSelectionRange(start, start + len);
    }

    //  Using the browser to do this conversion from String -> Date works in newer browsers.  But in older ones, the timezone
    //  handling is not the same.  Some browsers (like old Chrome and maybe some Safari/iOS) will convert our date/time string
    //  and then TRANSLATE the time into the browsers timezone.  So a time of 23:59 gets translated into 18:59 (for offset -5)!
    //  To avoid that, we can't rely on "new Date(str)" producing the correct result and must always construct the date
    //  using all of the components.  Then the result will be the exact date & time in the local timezone.
    //  https://medium.com/@toastui/handling-time-zone-in-javascript-547e67aa842d
    private ParseDateFromInput(dateAsString: string): Date {
        //  Expected date format is: "MM/dd/yyyy, hh:mm aa"
        //  Requires a string formatted as 01/19/2018, 07:00 AM

        //  If not correct format or the string contains placeholders, returns null.
        //  Otherwise, we may parse a valid date before the user has entered all of the digits which will then
        //  replace the placeholders with 0's and prevent typing in the additional digits.
        if (!dateAsString)
            return null;
        if (dateAsString.length !== this._Config.Mask.length)
            return null;
        if (dateAsString.indexOf("_") !== -1)
            return null;

        let month = parseInt(dateAsString.substr(0, 2)) - 1;      //  month is zero based!
        let date = parseInt(dateAsString.substr(3, 2));
        const year = parseInt(dateAsString.substr(6, 4));

        //  Do some checks on the month and (more importantly) the day.  Otherwise, if the date is pre-populated
        //  to "1/31" and the user tries to enter "2" for the month, "2/31" is invalid and using the built-in javascript
        //  Date() constructor will see that and turn it in to 3/3!!!
        if (month < 0)
            month = 0;
        else if (month > 11)
            month = 11;

        if (date < 1)
            date = 1;
        else {
            const m = moment().set({ 'year': year, 'month': month });
            if (date > m.daysInMonth())
                date = m.daysInMonth();
        }

        let hours: number = 0;
        let minutes: number = 0;
        if (this._Config.LastComponent > DateComponentEnum.Year) {
            hours = parseInt(dateAsString.substr(12, 2));
            minutes = parseInt(dateAsString.substr(15, 2));

            if (!this._Config.UseMilitaryTime) {
                const ampm = dateAsString.substr(18, 1);
                if ((ampm === "P") && (hours !== 12))
                    hours += 12;
                else if ((ampm === "A") && (hours === 12))
                    hours = 0;
            }
        }

        return new Date(year, month, date, hours, minutes, 0);
    }

    private SetDate(date: Date, forceSetValue: boolean = false): void {
        if (!DateUtils.IsValidDate(date))
            return;

        if (this._ForceTime) {
            //  If this is set, set the specific time into the date.  Allows us to use just the calendar in cases
            //  where we need a date other than 00:00 in order for the date to be valid.
            const hours = parseInt(this._ForceTime.substr(0, 2));
            const minutes = parseInt(this._ForceTime.substr(3, 2));
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
        }

        if (this._Min && (date < this._Min))
            date = this._Min;
        else if (this._Max && (date > this._Max))
            date = this._Max;

        //  Always store the date into the FormControl using this method so that it's formatted as the server expects
        const dateAsString = DateUtils.ConvertToString(date);

        if (this._FormControl && (forceSetValue || (this._FormControl.value !== dateAsString))) {
            //  Don't emit the change event here if updateOn = 'blur'.
            //  If we are using a datepicker, that may do that itself no matter what - which is fine.  We will also emit the event in blur
            //  so a change event should do a short debounce to prevent getting multiple events.
            const emitEvent = this._FormControl.updateOn === "blur" ? false : true;
            if (!emitEvent)
                this._EmitChangeEventOnBlur = true;
            this._FormControl.setValue(dateAsString, { emitEvent: emitEvent });
        }

        this.FormatDateIntoInput(date);
    }

    //  Formats the date into the input without setting the form control value.  Used when the date changes
    //  (such as in response to a shortcut key or changing the ticket type) to make sure that the new value is
    //  formatted correctly.
    public FormatDateString(dateAsString: string): void {
        const date = DateUtils.ConvertFromString(dateAsString);
        if (!DateUtils.IsValidDate(date))
            return;

        if (this.FormatDateIntoInput(date))
            setTimeout(() => this.SelectDateTimeComponent(0));
    }

    private FormatDateIntoInput(date: Date): boolean {
        const formattedDate = formatDate(date, this._Config.DateFormat, "en-US");
        this._PreviousConformedValue = formattedDate;   //  Always set this - if it's not initialized correctly, mask gets screwed up when first digit entered

        //  Format the input control as the editor expects
        const targetElement = this._Element.nativeElement as HTMLInputElement;
        if (targetElement.value === formattedDate)
            return false;       //  Same so do nothing

        targetElement.value = formattedDate;
        return true;
    }

    //  Demo page: https://text-mask.github.io/text-mask/
    //  text-mask functions: https://github.com/text-mask/text-mask/tree/master/core
    private OnDateElementChanged(targetElem: HTMLInputElement, value: string): any {
        if (!value)
            return { value: value, caret: 0 };

        const currentCaretPosition = targetElem.selectionStart;

        //  Source for this method: https://github.com/text-mask/text-mask/blob/master/core/src/conformToMask.js
        const conformOptions = {
            guide: true,
            previousConformedValue: this._PreviousConformedValue,
            placeholderChar: this._Config.PlaceholderChar,
            placeholder: this._Config.Placeholder,
            currentCaretPosition: currentCaretPosition,
            keepCharPositions: true
        }
        const result = conformToMask(value, this._Config.Mask, conformOptions);
        //console.warn("OnDateElementChanged:conformToMask", value, conformOptions, result);

        //  Source for this method: https://github.com/text-mask/text-mask/blob/master/addons/src/createAutoCorrectedDatePipe.js
        const autoCorrectedValue = createAutoCorrectedDatePipe(this._Config.AutoCorrectDatePipeFormat)(result.conformedValue);

        //  createAutoCorrectedDatePipe seems to return a value of "false" if it does nothing!
        const conformedValue = autoCorrectedValue ? autoCorrectedValue.value : result.conformedValue;

        const adjustOptions = {
            previousConformedValue: this._PreviousConformedValue,
            conformedValue: conformedValue,
            currentCaretPosition: currentCaretPosition,
            rawValue: value,
            placeholderChar: this._Config.PlaceholderChar,
            placeholder: this._Config.Placeholder,
            indexesOfPipedChars: autoCorrectedValue ? autoCorrectedValue.indexesOfPipedChars : [],
            //caretTrapIndexes: []
        };

        //  Source for this method: https://github.com/text-mask/text-mask/blob/master/core/src/adjustCaretPosition.js
        //  Description: https://github.com/text-mask/text-mask/tree/master/core#adjustcaretpositionargumentsobject
        let newCaretPosition = adjustCaretPosition(adjustOptions);
        //console.warn("OnDateElementChanged:adjustCaretPosition", adjustOptions, newCaretPosition);

        //  Not sure why, but when the AutoCorrectDatePipeFormat makes a change (which happens if you enter something like '9' into
        //  a field that it knows can't start with a 9 - it changes it to '09') the caret position comes back 1 less than it should.
        //  The demo page works correctly though.  No idea what is being done differently so just incrementing it to get what we need.
        if (conformedValue !== result.conformedValue)
            newCaretPosition++;

        this._PreviousConformedValue = conformedValue;
        return { value: conformedValue, caret: newCaretPosition };
    }
}

//  This is the text-mask addons default createAutoCorrectedDatePipe() but adapted to *NOT*
//  check for a valid number of days based on the entered month.  We can't do that because we are EDITING
//  a pre-populated date.  Which means that the user could change the month (which is prompted first) and then
//  be left with an invalid date because the day was pre-populated.  The default method would then treat that as invalid
//  and not do the conform as we need it to.  The best example of when this is an issue is when the date
//  is pre-populated with 1/31 and we enter "2" as the month.  2/31 is obviously invalid which makes this function
//  not auto correct the "2" in to "02".
//  We handle and fix invalid dates like this in ParseDateFromInput() so the example above will automatically
//  correct itself to "2/28".
//  Original is here: https://github.com/text-mask/text-mask/blob/master/addons/src/createAutoCorrectedDatePipe.js
//  and in Web\node_modules\text-mask-addons\src\createAutoCorrectedDatePipe.js
function createAutoCorrectedDatePipe(dateFormat = 'mm dd yyyy', { minYear = 2000, maxYear = 2200 } = {}) {
    const formatOrder = ['yyyy', 'yy', 'mm', 'dd', 'HH', 'MM', 'SS'];
    const dateFormatArray = dateFormat
        .split(/[^dmyHMS]+/)
        .sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b));

    return function (conformedValue) {
        const indexesOfPipedChars = [];
        const maxValue = { 'dd': 31, 'mm': 12, 'yy': 99, 'yyyy': maxYear, 'HH': 23, 'MM': 59, 'SS': 59 };
        const minValue = { 'dd': 1, 'mm': 1, 'yy': 0, 'yyyy': minYear, 'HH': 0, 'MM': 0, 'SS': 0 };
        const conformedValueArr = conformedValue.split('');

        // Check first digit
        dateFormatArray.forEach((format) => {
            const position = dateFormat.indexOf(format);
            const maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10);

            if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
                conformedValueArr[position + 1] = conformedValueArr[position];
                conformedValueArr[position] = 0;
                indexesOfPipedChars.push(position);
            }
        });

        // Check for invalid date
        const isInvalid = dateFormatArray.some((format) => {
            const position = dateFormat.indexOf(format);
            const length = format.length;
            const textValue = conformedValue.substr(position, length).replace(/\D/g, '');
            const value = parseInt(textValue, 10);

            const maxValueForFormat = maxValue[format];
            if (format === 'yyyy' && (minYear !== 1 || maxYear !== 9999)) {
                const scopedMaxValue = parseInt(maxValue[format].toString().substring(0, textValue.length), 10);
                const scopedMinValue = parseInt(minValue[format].toString().substring(0, textValue.length), 10);
                return value < scopedMinValue || value > scopedMaxValue;
            }
            return value > maxValueForFormat || (textValue.length === length && value < minValue[format]);
        });

        if (isInvalid)
            return false;

        return {
            value: conformedValueArr.join(''),
            indexesOfPipedChars
        }
    }
}
