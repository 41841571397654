import { Injectable } from '@angular/core';
import { EntityEnum } from 'Enums/EntityType.enum';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { ResponseConfig } from 'Models/Configuration/Response.model';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { SearchColumnResponse } from 'Models/Searching/SearchColumnResponse.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketDiscussion } from 'Models/Tickets/TicketDiscussion.model';
import { TicketDiscussionChildResponse } from 'Models/Tickets/TicketDiscussionChildResponse.model';
import { TicketResponseCreateRequest } from "Models/Tickets/TicketResponseCreateRequest.model";
import { TicketResponseHistoryItem } from 'Models/Tickets/TicketResponseHistoryItem.model';
import { TicketResponseHistoryRequest } from 'Models/Tickets/TicketResponseHistoryRequest.model';
import { TicketResponseItem } from 'Models/Tickets/TicketResponseItem.model';
import { TicketResponseViewInfo } from 'Models/Tickets/TicketResponseViewInfo.model';
import { TicketServiceArea } from 'Models/Tickets/TicketServiceArea.model';
import { ExcavatorCompanyService } from 'Pages/Excavators/ExcavatorCompanies/Services/ExcavatorCompany.service';
import { ServiceAreaService } from 'Pages/ServiceAreas/Services/ServiceArea.service';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';

@Injectable({
    providedIn: 'root'
})
export class TicketResponseService extends CRUDBaseService<ResponseConfig> {
    protected apiPath: string = "Ticket/Response";

    ViewPermission: PermissionsEnum = PermissionsEnum.TicketResponse_View;
    EditPermission: PermissionsEnum = PermissionsEnum.TicketResponse_Create;
    CreatePermission: PermissionsEnum = PermissionsEnum.TicketResponse_Create;
    DeletePermission: PermissionsEnum = PermissionsEnum.TicketResponse_Create;
    CopyPermission: PermissionsEnum = null;

    constructor(services: CRUDServices, private _ServiceAreaService: ServiceAreaService, private _ExcavatorCompanyService: ExcavatorCompanyService) {
        super(services);
    }


    public GetHistoryStatus(r: TicketResponseHistoryItem) {
        let Status = "";

        if (r.Response) {
            if (!r.Response.ResponseID) {
                //  "No Response" does not also imply "Current".  If the response was not entered when the late
                //  ticket was created, the record is kept with no response, current = false, and OnLateTicket flag
                //  is set to true.  This allows us to "lock" the response state at the time the late ticket was sent.
                if (r.Response.OnLateTicket)
                    Status = "No Response by Due Date, Late Ticket Sent";
                else if (!r.Response.ResponseRequired)
                    Status = "Not Required";
                else
                    Status = "No Response";
            }
            if (r.Response.Current && r.Response.ResponseRequired) {
                if (Status !== "")
                    Status += ", ";
                Status += "Current";
            }
        } else {
            Status = "Event";
        }

        return Status;
    }


    public GetTicketResponse(ticketNumber: string): Observable<Ticket> {
        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            return new Observable<Ticket>(observer => {

                if (!allowed)//If they don't have permission then return an empty list, else do the search
                {
                    observer.next();
                    observer.complete();
                }

                return this.services.http.get<Ticket>(this.services.settingsService.ApiBaseUrl + "/Ticket/Response/GetTicketResponses/" + ticketNumber)
                    .subscribe(val => {
                        observer.next(val);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                        return of();
                    });
            });
        }));
    }

    public CreateResponse(request: TicketResponseCreateRequest): Observable<TicketResponseItem> {
        return this.services.http.post<TicketResponseItem>(this.services.settingsService.ApiBaseUrl + "/Ticket/Response/CreateUserEnteredResponse", request);
    }

    public CreateExcavatorComment(insert: TicketDiscussion[]): Observable<TicketDiscussionChildResponse[]> {
        return this.services.http.post<TicketDiscussionChildResponse[]>(this.services.settingsService.ApiBaseUrl + "/Ticket/Response/CreateExcavatorComment", insert);
    }

    public GetTicketResponseHistory(ticketNumber: string, serviceAreaID?: string): Observable<TicketResponseHistoryItem[]> {
        const request = new TicketResponseHistoryRequest(ticketNumber, serviceAreaID);
        return this.services.http.post<TicketResponseHistoryItem[]>(this.services.settingsService.ApiBaseUrl + "/Ticket/Response/History", request);
    }

    //  TODO: This api call is extremely wasteful.  It's returning all kinds of stuff that we don't need here.
    //  Should change what it returns to not send so  much extra stuff!  And to make it more efficient on the server
    //  to only query what we actually need.
    public GetServiceAreasForAdd(ticketNumber: string): Observable<TicketServiceArea[]> {
        const url = this.services.settingsService.ApiBaseUrl + "/Ticket/Response/GetServiceAreasForAdd/" + ticketNumber;
        return this.services.http.get<TicketServiceArea[]>(url);
    }

    //  TODO: This api call is extremely wasteful.  It's returning all kinds of stuff that we don't need here.
    //  Should change what it returns to not send so  much extra stuff!  And to make it more efficient on the server
    //  to only query what we actually need.
    public GetServiceAreasForView(ticketNumber: string, forAddResponse: boolean = true): Observable<TicketResponseViewInfo> {
        const url = this.services.settingsService.ApiBaseUrl + "/Ticket/Response/GetServiceAreasForView/" + ticketNumber;
        return this.services.http.get<TicketResponseViewInfo>(url);
    }

    protected HasSearchColumnsEndpoint: boolean = true;

    protected AddUIFunctionsToSearchColumn(response: SearchColumnResponse): SearchColumn {
        const col = super.AddUIFunctionsToSearchColumn(response);

        //  *** BEFORE HARDCODING PROPERTY NAMES IN HERE...
        //      Consider adding something into SearchColumnResponse to tell the UI how to handle the column!
        //      The server sends the available columns because it knows what they are.  So there's no reason it can't
        //      also send some information to tell the UI what type of column it is so that the UI knows how to handle it.
        switch (col.column) {
            case 'TicketNumber':
                col.minNumberOfCharsBeforeValid = this.services.settingsService.TicketNumberSearchRequiredChars;
                col.filterOperator = SearchFilterOperatorEnum.StartsWith;
                break;
            case 'PlaceName':
                this.ConfigurePlaceSearchColumn(col);
                break;
            case "ServiceArea.Name":
                col.autoComplete = true;
                col.autocompleteResultDisplayValue = "Code";
                col.autoCompleteSearchFunction = (filter: SearchRequest) => {
                    filter.EntityType = EntityEnum.ServiceArea;
                    filter.Filters[0].PropertyName = "Name, Code";
                    return this._ServiceAreaService.SearchForAutocomplete(filter);
                };
                break;
            case "ExcavatorCompany.Name":
                //  This is built (by ReadOnlyEntityBizBase.BuildSearchColumnsForType() and because of the SearchColumn attribute
                //  on the ExcavatorCompanyID property of TicketResponseSearch) to filter against the ExcavatorCompany.ID.
                //  Can't enable autocomplete (which would make that work) because a service area user doesn't necessarily
                //  have view permission on excavators.  So nothing will be returned from the api call even if we make
                //  CRUDBaseService.GetList skip it's security check to make the api call).  Until we have a way to tell the search
                //  api to skip the security check, we will just have to do a plain text search on the Name.
                col.filterColumn = "ExcavatorCompany.Name";
                break;
        }

        return col;
    }
}
