import { PluggableMap } from 'ol';
import ol_control_Button from 'ol-ext/control/Button';

//  Extends an ol-ext Button
export class HidableButton extends ol_control_Button {

    private _Hidden: boolean = false;

    constructor(options: any, hidden: boolean = true) {
        super(options)

        this.SetHidden(hidden);
    }

    //  Just needed because UndoEditButton derives from this and has a setMap() method.
    //  Need this method because the type mappings for ol_control_Button don't actually define this.
    public setMap(map?: PluggableMap): void {
        super.setMap(map);
    }

    public SetHidden(hidden: boolean): void {
        if (this._Hidden === hidden)
            return;

        //  ol-ext seems to have added this - may not need this HidableButton class at all any more.
        //  The Angular 9 build is not seeing it though so need to cast it to any to avoid that build error...
        //this.setVisible(!hidden);
        (this as any).setVisible(!hidden);

        this._Hidden = hidden;
    }
}
